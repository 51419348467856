    
    // Base Layout


    body {

        transition: top 0.25s;
        top: 0;

        &:after {
            content:"";
            display:block;
            background: $labc-grey url('../images/transparent-loader.gif') no-repeat center 52%;
            width:100%;
            height:100%;
            color:$labc-black;
            font-size:32px;
            position:absolute;
            top:0;
            left:0;
            z-index:999;
            text-align:center;
            padding-top:37%;
            z-index:-1;

            opacity: 0;
            transition: all 0.25s easeOut;
            
        }

        &.loading {

            overflow:hidden;

            &:after {

              z-index:999;
              opacity: 0.7;

            }
        }
    }

    // Content Wrappers
    // -------------------------------------------------------------------------/
    
  
 
        // Site Wrapper
        // ----------------------------------
     

        .site-wrapper { 
 
            //background : $site-background-colour;
            background: $site-background-colour url(../images/middleblock.jpg) repeat;
            position : relative;

            @if $page-width != 100%  {
                @media all and  (min-width: $desk-start) {
                    // http://css-tricks.com/perfect-full-page-background-image/
                    background: url(../images/bgs/bg.jpg) no-repeat center center fixed; 
                    background-size: cover;
                    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg.jpg', sizingMethod='scale');
                    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg.jpg', sizingMethod='scale')";            


                    .page.container {
                        max-width: $page-width;  
                        margin: 0 auto;

                        &.header-container {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }

                }
            }


            #page-container {
                margin-bottom: 20px;
                padding-top: 0;
                @media all and  (min-width: $desk-start) {
                    padding-top: 20px;

                    &.fullbox {
                        background: $iedesign-white;
                        margin-top: 20px;
                        padding-top: 5px;
                    }
                }

                #main-content {
                    background: $iedesign-white;
                    padding: 15px;
                }
            }

        }

        body.home .site-wrapper #page-container {
            margin-bottom: 0;
            padding-bottom: 20px;
            /*padding-top: 10px;*/
            padding-top: 0;

            #main-content {
                background: none;
                padding: 0;
            }
        }
     
        @media all and  (min-width: $desk-start) {
            body {
                &.bg1 .site-wrapper {

                }
                &.bg2 .site-wrapper {
                    background-image: url(../images/bgs/bg2.jpg);
                    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg2.jpg', sizingMethod='scale');
                    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg2.jpg', sizingMethod='scale')"; 
                }
                &.bg3 .site-wrapper {
                    background-image: url(../images/bgs/bg3.jpg);  
                    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg3.jpg', sizingMethod='scale');
                    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg3.jpg', sizingMethod='scale')";  
                }
                &.bg4 .site-wrapper {
                    background-image: url(../images/bgs/bg4.jpg);
                    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg4.jpg', sizingMethod='scale');
                    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../images/bgs/bg4.jpg', sizingMethod='scale')"; 
                }

            }
        }

        @media all and (max-width: $desk-start - 1) {
            
            .site-wrapper {
                transition: left 0.25s, transform 0.6s;
                padding-top :  $mobile-sitewrapper-padding;
                left: 0;
            }
        


            // Mobile Active Menu state

            .active-menu {
           
                overflow: hidden;
          
                .site-wrapper {
                    transform: translateX(240px);
                    transition: transform 0.6s;
                }

            }
     

        }
        

        

        // When Site Search drop down is active
        @media all and  (min-width: $desk-start) {

            .active-search {

                top: 90px;
            }
        

            .lte-ie9  .active-search   {
                    
         //      margin-top : 90px;
            }
        }
        
                
        
        // Section Wrapper
        // ----------------------------------

        .container {

            @media all and  (min-width: $desk-start) {

                padding: 0; 

            }

        }



        // Header
        // ----------------------------------

            .site-header { 

                position: relative;
                background: $iedesign-primary url(../images/header.png) repeat-x;
                border-bottom: 3px solid $iedesign-primary3; // Fallback
                border-bottom: 3px solid rgba($iedesign-primary3,  0.3);


            }


            // Wrapper For Logo and strapline
 
            .header-content {

                padding : 12px;

                @extend .cf;

                @media all and  (min-width: $desk-start) {

                    padding-top: 28px;
                    padding-bottom: 28px;
                }

                @media all and (min-width: $desk-start) and (max-width:  $page-width) {
                
                    padding-right: 12px;
                    padding-left: 12px;
                
                }

               @media all and  (min-width: $page-width) {
                
                     padding-right: 12px;
                    padding-left: 12px;
               }



                .slogan,
                .logo-container  {

                  //  float: left;
                }


                .logo-container  {

                    position: absolute;
                    top: -42px;
                    right: 14px;   
                    z-index: 100;

                    @media all and  (min-width: $desk-start) {
                        position: relative;
                        top: 0; 
                        right: auto;
                        float:left;
                    }
     

                }
                
                .slogan {  

                    display: none;
                    visibility: hidden;

                    @media all and  (min-width: $lap-start) {
                        display: block;
                        visibility: visible;
                        padding: 0 0 14px;
                        max-width: 700px;
                        text-align: center;
                        line-height: 140%;
                        width: 75%;
                        margin: 0 auto;
                    }

                    @media all and  (min-width: $desk-start) {
                      
                        padding: 0;
                        padding-left: 55px;

                    }

                }

                .sitename {

                    text-align: center;
                    color: $iedesign-white;
                    font-weight: 300;
                    font-size: 20px;
                    margin: 0;
                    
                    @media all and  (min-width: $lap-start) {

                        margin: 0 0 8px;
                        font-size: 24px;
        
                    }

                    @media all and  (min-width: $desk-start) {

                            margin: 0;
                            float: left;
                            width: 100%;
                         
                            font-size: 28px;
                      
                    }                    
                }
            }

 
        
        
        // Content
        // ----------------------------------

            // Page Wrapper
            // Page wraps main content and Sidebars

            .page  {

                @extend .cf;

                &.container {
                    padding:  20px 0;

                    &.no-padding {
                        padding: 0;
                        width: auto;
                    }
                }

                .image-container {
                    margin-bottom: 15px;
                }

                img {

                    max-width: 100%;
                    height:auto;

                    &.alignright {
                        float: right;
                        margin: 0 0 0 15px;
                    }

                    &.alignleft {
                        float: left;
                        margin: 0 15px 0 0;
                    }

                }
           
                .main-content {

                    padding: 16px;

                    @media all and (min-width:  $desk-start) {

                        padding: 0 0 0 8px  ;
                        min-height: 400px;
                    }   


                    &.loading  {

                        position: relative;

                        .content {

                            opacity: 0.8;
                        }
                       
                        &:before {

                            content: "";
                            display: block;
                            position:absolute;
                            top: 24px;
                            right: 12px;
                            @extend .flag-throbber;
                        }
                    }

                    .content {

                        @include clearfix;
                        //@include opaqueBackground;

                     }

                }
                
            // Sidebar  Stlyes

                .sidebar-first ,
                .sidebar-second {

                    @extend .desk-one-quarter;
                    padding: 0 16px 0;

                    @media all and (min-width:  $desk-start) {

                        float:left;
                        clear: left;

                        background: $iedesign-white;
                        padding: 15px;
                        padding-right: 0;
                    }

                    .block {

                        background: $iedesign-secondary;
                        color: $iedesign-white;
                        padding: 15px;
                        position: relative;
                        margin-bottom: 10px;

                        .title {
                            color: $iedesign-white;
                        }

                        .content {

                            color: $iedesign-white;   
                        }


                        &.call-to-actions-block  {

                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                display: block;
                                right: -7px;
                                @include block-arrow(right, $iedesign-secondary);
                                top: 20%;
                            }
                        }


                        .action-button {
                            position: absolute;
                            right: 0px;
                            bottom: -23px;
                             
                        }
                    }
                }

               .sidebar-first {

                   //padding: 0;

                   @media all and (min-width:  $desk-start ) {
                    
                      //padding: 0 8px 0 0;

                    }

                }


                .sidebar-second  .block {

                    margin-bottom: 30px;
                }
 

            }

  

             // Page with Sidebars

            .one-sidebar, 
            .two-sidebars {


                // Main Content Wrapper width  with sidebars

                .main-content {
                                   
                    @extend .desk-three-quarters;
    
                    // On Desktop up ++ float
                    
                    @media all and  (min-width: $desk-start) {

                        float:right;
                    }

                }
 

            }

           
        // Node / Page Specific Overiddes to the standard layout


        // Homepage & Awards Landing page Content
        //------------------------------------------------------------------------

        .home {
 
            @media all and  (min-width: $desk-start) {

                .main-content {

                    float: left;
                    padding: 0 8px 0 0;

                    .node {

                      text-align: center;   
                    }
                }

                .sidebar-second {

                    padding:  0;
                    float: right;

                }

            
            }
 

        }

 

        // News Page
        //------------------------------------------------------------------------

        .page-news {

            .content {

                padding: 0;
            }
        }


        // Node Content
        //------------------------------------------------------------------------

        .content-wrapper {

            @extend .cf;

            ul {
                li {
                    list-style-type: disc;
                    list-style-position: initial;
                }
            }

        }

     
        .content {
        
            padding: 0 15px ;

            @media all and  (min-width: $desk-start) {
            
                padding: 0px;

            }

        }

 

        .node-type-article {
            
            .content {

                padding-top: 25px;
            }

            @media all and  (min-width: $lap-start) {
                .field-type-image {
                    width: 50%;
                    float: left;
                    margin-right: 15px;
                    margin-bottom: 15px;
                }
            }

            article.node-article {
                border-bottom: 1px dashed $iedesign-primary2;
                margin-bottom: 15px;
            }

        }

 
        .content-bottom {
            margin-top: 15px;

          clear: both;

        }


        // Footer
        // ----------------------------------
        
        .site-footer {  
 

            background : $footer-background-colour;
            color : $footer-colour;
            
            .edit-button {

                position:absolute;
                right: 54px;
                top: -31px;
            }
    
        } 


        .footer-content {

            padding: 30px 16px;

            position: relative;

            @extend .cf;

            @media all and  (min-width: $desk-start) {

              padding: 10px;

            }

        }


        .footer-block {

            //border-top: 1px solid rgb(206,206,205);

            padding-bottom: 15px;

            padding-left: 5px;

            margin-top : 15px;

            font-size:   $footer-content-size;

            @media all and (min-width: $desk-start) {

                //float:left;

                //margin-left: 4%;
            }
        }


//special pages with added transparency between elements

.page-opportunities,
.page-news {

    .page .main-content .content {

        background: transparent;
        padding: 0;

        
    }
}

.page-opportunities {

    .page-title {
        display: none;
        padding: 0 15px 15px;
        @include opaqueBackground;
    }

}



.posted_on {
    color: $iedesign-primary3;
    font-weight: normal;
    margin: 0;
    margin-top: -15px;
}

.fullmode_summary p {
    /*font-size: 21px;*/
    font-size: 17px;
    color: #555555;
    font-weight: 300;
    line-height: 28px;

    a {
        font-weight: 400;
    }
}

img {
    &.right {
        float: right;
        margin-left: 15px;
        margin-bottom: 15px;
        margin-top: 5px;
    }
    &.left {
        float: left;
        margin-right: 15px;
        margin-bottom: 15px;
        margin-top: 5px;
    }
}

a.more {
    &:after {
        content: " \00BB";
    }
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.page {
    .main-content-inner {
        @include tablet-landscape {
            width: 100%;
        }
    }
}

@media all and (min-width: $desk-start) {

    .matching_layout--main{
        width:70%;
        padding-left:20px;
        float:left;
    }
    .matching_layout--sidebar{
        width:30%;
        float:left;
    }

}

div.resumes ul.resumes,
div.job_listings ul.job_listings{  
    list-style:none;
}

body .job_filters .search_jobs{
    padding:0;
}

body .resume_filters    .search_resumes,
body .resume_filters    .search_resumes div.resume-filter.filter_last, 
body .resume_filters    .search_resumes div.resume-filter.search_location,
body .resume_filters    .search_resumes div.resume-filter.filter_first, 
body .resume_filters    .search_resumes div.resume-filter.search_keywords,
body .job_filters       .search_jobs    div.search_keywords,
body .job_filters    .search_jobs div.job-filter.filter_last, 
body .job_filters    .search_jobs div.job-filter.search_location,
body .job_filters    .search_jobs div.job-filter.filter_first, 
body .job_filters    .search_jobs div.job-filter.search_keywords,{
    float:none;
    padding-left:0;
    padding-right:0;
    padding-top:0;
    padding-bottom:0;
}

body .job_filters .search_jobs div.job-filter,
body .resume_filters .search_resumes div.resume-filter{
    width:100%;
}

.job-filter-input,
.resume-filter-input,
body .resume_filters .search_resumes div.grm-filters-wrapper,
body .job_filters .search_jobs div.gjm-filters-wrapper{
    margin: 20px auto 20px auto;
    width: 90%;
    overflow:auto;
}

body .job_filters .search_jobs div.job-filter label,
body .resume_filters .search_resumes div.resume-filter label{
    display:block;
}

body .job_filters .search_jobs i.gjm-locator-loader,
body .job_filters .search_jobs i.gjm-locator-btn,
body .resume_filters .search_resumes i.grm-locator-loader,
body .resume_filters .search_resumes i.grm-locator-btn {
    top: 98px;
    right: 20px;
}

body .wp-job-manager-checkbox{
    padding:20px;
    border-bottom:1px solid #e6e6e6;
}

body .job_filters .search_jobs div.job-filter .wp-job-manager-checkbox input,
body .resume_filters .search_resumes div.resume-filter .wp-job-manager-checkbox input{
    float:right;
    width:auto;
}