
// Emedbed media

.media_embed {
    
    margin: 0 0 20px;        
    max-width: 100%;


    @media all and  (min-width: $desk-start) {   

        max-width: 75%;
    }

}