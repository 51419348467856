/*------------------------------------*\
    VARS.SCSS
\*------------------------------------*/
/**
 * Any variables you find set in inuit.css’ `_vars.scss` that you do not wish to
 * keep, simply redefine here. This means that if inuit.css, for example, sets
 * your `$base-font-size` at 16px and you wish it to be 14px, simply redeclare
 * that variable in this file. inuit.css ignores its own variables in favour of
 * using your own, so you can completely modify how inuit.css works without ever
 * having to alter the framework itself.
 */





/*------------------------------------*\
    $OBJECTS-AND-ABSTRACTIONS
\*------------------------------------*/
/**
 * All of inuit.css’ objects and abstractions are initially turned off by
 * default. This means that you start any project with as little as possible,
 * and introducing objects and abstractions is as simple as switching the
 * following variables to `true`.
 */
$use-grids:         true; 
$use-flexbox:       false;
$use-columns:       false;
$use-nav:           true;
$use-options:       false;
$use-pagination:    true; 
$use-breadcrumb:    true;
$use-media:         false;
$use-marginalia:    false;
$use-island:        false;
$use-block-list:    false;
$use-matrix:        false;
$use-split:         false;
$use-this-or-this:  false;
$use-link-complex:  false;
$use-flyout:        false;
$use-arrows:        false;
$use-sprite:        false;
$use-icon-text:     false;
$use-beautons:      false;
$use-lozenges:      false;
$use-rules:         false;
$use-stats:         false;
$use-greybox:       true;


/*------------------------------------*\
    $COMPASS
 \*------------------------------------*/
/**
 * Globals required by the compass mixins copied to port ceertain functaionlity from previous theme versions
 */


$hide-text-direction: left;
$critical-usage-threshold: 0.01;
$supported-browsers: all;
$debug-browser-support: false;
$graceful-usage-threshold: 0.1;
$box-sizing-support-threshold: $graceful-usage-threshold;
$default-capability-options: ();
$current-prefix: null;
$prefix-context: null;
$current-browser-versions: ();
$default-border-radius: 5px;


/*------------------------------------*\
    $OVERRIDES
 \*------------------------------------*/
/**
 * Place any variables that should override inuit.css’ defaults here.
 */


 // Just a note of the widths.
//$lap-start:         481px!default;
//$desk-start:        1024px!default;
//$desk-wide-start:   1200px!default;

    $h1-size: 26px;
    $h2-size: 14px;
    $h3-size : 14px;
    $h4-size : 14px;

/*------------------------------------*\
    $CUSTOM
\*------------------------------------*/
 
 

// LABCs Variables 
// (note, tried making vars for a lot of components, not sure it works brilliantly, clearer naming convention required BW )
// -----------------------------------


// 1 . Colours

    // Branding

    //******** COLORS HAVE BEEN GREYFIED UNTIL WE HAVE STORYBOARDS

    $labc-orange : #00c2c2; //main color
    $iedesign-primary: $labc-orange;
    $iedesign-primary2: $iedesign-primary - 50;
    $iedesign-primary3: $iedesign-primary - 100;
    $iedesign-dark-green: #174c4c;
    $iedesign-pink : #e54a91;


    $labc-green : #e54a91; //secondary color
    $iedesign-secondary: $labc-green;

    $labc-yellow : #999; //#fff14d;
    $iedesign-tertiary: $labc-yellow;    

    $labc-black : rgb(29,29,27);
    $iedesign-black : $labc-black;

    $labc-darkgrey : #323232;
    $iedesign-darkgrey : #464646;

    $labc-lightgrey:  rgb(240, 240, 240);
    $iedesign-lightgrey : $labc-lightgrey;

    $labc-grey:  rgb(150, 150, 150);
    $iedesign-grey : $labc-grey;

    $iedesign-white: #fff;

    // 1.1 Wrapper Backgrounds

        $body-background-colour:  $iedesign-darkgrey;

        $body-background-colour-desk: $iedesign-lightgrey;

        $site-background-colour: rgb(253, 253, 253);

        $footer-background-colour: $iedesign-darkgrey;
    
    // 1.2 Wrapper Text Colours

        $footer-colour: $iedesign-white;


    // 1.2 Content

        // 1.2.1 Generic
        
            $text-color: rgb(85,85,85);

            $heading-color: $iedesign-primary2;

            $slogan-colour: $labc-black;

            // Tables

            $table-border-color : $labc-grey - 30;

            $table-cell-border-color: rgb(230, 230, 230);

            $table-header-border-color :  $labc-grey - 30;

            $table-header-background-color :  $labc-grey ;


            //Forms

            $form-text-colour :  $text-color;

            $submit-button-colour : $iedesign-darkgrey;

            $submit-background-colour : $iedesign-lightgrey;
            
            // Social Share
            $social-tilte-color : rgb(125, 125, 125);


        // 1.2.2 Navigation

            // Lang Select 

            $lang-select-color : rgb(255,255,255);
            $lang-select-color-desk : rgb(130,130,130);

            // Buttons: 

            $button-border : $iedesign-white;

            $button-colour: $iedesign-white;
            $button-colour-hover: $iedesign-white - 50;
            $button-background-colour: $iedesign-secondary;
            $button-background-colour-hover: $iedesign-secondary - 50;

                // Header Button

                $header-button : $iedesign-white;
                $header-button-hover : $iedesign-white - 30;

                // Footer

                $footer-button : $labc-green;
                $footer-button-hover : $labc-green - 30;


            // Header Nav


                $header-nav-color-desk : $iedesign-darkgrey;
                $header-nav-background-desk : rgb(229,232,232);
                $header-nav-border-left: rgb(196,198,198);
                $header-nav-border-right: rgb(254, 253,253);

                // Search 

                     $site-search-background : $labc-green;
                     $site-search-background-desk : $labc-black;


            // Main Nav
                $main-nav-colour: #5f5e5f;
                $main-nav-background : $labc-lightgrey;

                $active-nav-colour-desk : $labc-grey;

                $main-nav-border-left : $labc-grey;
                $main-nav-border-right : $labc-darkgrey;

            //Secondary Nac

                $desk-secondary-nav-background : $iedesign-white;
                $desk-secondary-nav-background-color : $iedesign-darkgrey;


            // Footer Nav

               $footer-link-color : rgb(125, 125, 125);

               $social-nav-border : white;
               $social-nav-border : white;

               $social-nav-background : $labc-grey;
               $social-nav-background-hover : #00c2c2;
               $social-nav-border-hover: #00c2c2;

               $social-nav-color :  white;
               $social-nav-color-hover :#00c2c2; 
        
            // System Messages

                $info-message :  $iedesign-primary3;
                $error-message : #e7b1a9;
                $success-message :  $iedesign-primary;

            //Tab Color
                $tab-color :rgb(100, 99, 99);


// 2 Paths
 
    $font_path :  '../../fonts';
        
    $layout_image_path:  '../images';

// 3  Font Sizes

    $form-font-size : 14px;

    $form-label-size :  14px;

    $footer-content-size: 12px;


    // Nav 
    
     $nav-size: 14px;
     $nav-size-desk: 13px;
     $header-nav-size-desk: 14px;

     $footer-nav-size: 12px;


// 4 Generic

 
        // 4.1 Page Max Width
        // If needed set a maximum page width for the layout
        // Set to 100% to override
    
            $page-width : 1200px;


        // Mobile Site Wrapper Padding
        // Padding applied to top of mobile site 

            $mobile-sitewrapper-padding : 50px;

            