

// Generic content blocks.


.content-blocks {
 


}

.content-block {


    padding: 12px 0;


    .media_embed  {
        
        max-width: 100%;
    }
   
}


@media all and  (min-width: $lap-start) {

    .content-block {

         &.inline {

            float:left;

            padding: 12px;

        }

        &.first {

            padding-left: 0;
        }
    }

}

