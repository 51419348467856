    

    .page-not-found {
        
        
        //text-align:center;

        .page-title {
            font-size:44px;
            text-align:center;
        }

        .content-wrapper  {
            padding: 30px 0;
            //background : $labc-grey;
                
            @extend .cf;
            
            margin: 0  auto;
        
            @extend .three-quarters;

            @extend .desk-one-half;
            

         }

         

        .content-search-block{
                
            @extend .cf;
        
            @extend .three-quarters;

            @extend .desk-one-half;
            

            
            margin: 30px  auto;


            .views-widget-filter-search_api_views_fulltext {
                
                max-width: 450px;
                margin: auto;
            }

            .views-submit-button  .form-submit{

                  
                margin: auto;

            }


        }

    }



    .helpful-links-navigation{

        padding: 20px ;
        
        @media all and  (min-width: $desk-start) {

            margin: 0 20%;
        }

        .menu {
                
            margin: 0 10%;

            li {
                margin: 0;
                width:50%;
            }

            a {
        
                text-align:center;
                border: 1px solid $labc-grey;

                padding: 10px 5px;
                display: block;

            }
        }
        
    }
