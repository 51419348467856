.recommend-form {
    background: $iedesign-secondary;
    color: $iedesign-white;
    padding: 15px;
    margin-bottom: 30px !important;

    .title {
        color: $iedesign-white;        
        font-size: 18px;
    }    

    .form-item.webform-component label {
        display: none;
    }

    .form-actions {
        position: relative;
        display: block;
        float: none;
    }

    .form-submit {
        position: absolute;
        right: -9px;
        bottom: -41px;
    }
}

.createopportunityblock {
    margin-top: 15px;
    position: relative;
    margin-bottom: 35px;
    padding: 15px;
    background: $iedesign-primary;
    color: $iedesign-white;
    width: auto;
    padding-bottom: 40px; 
    //float: right;
    
    & > .title {
        @include replace-text-with-dimensions('teachers.png');
    }

    a {
        color: $iedesign-primary3;
    }

    a.create {
        position: absolute;
        left: 15px;
        bottom: -20px;

        color: $iedesign-white;
        padding: 10px 45px 10px 25px; 
        border: 4px solid white;
        font-size: 16px;
        font-weight: 400;
        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
        background: $iedesign-secondary;

        &:after {
            background:  url('#{$layout_image_path}/icons/arrow-right.png') no-repeat 0 0;
            width: 29px;
            height: 18px;
        }

        &:hover {
            text-decoration: none;
            background: $iedesign-secondary - 30;
        }

    }

    @media all and (min-width: $desk-start ) {

    }

}

.findopportunitiestopform {
    margin-top: 15px;
    position: relative;
    margin-bottom: 35px;
    padding: 15px;
    background: $iedesign-primary3;
    color: $iedesign-white;
    width: auto;
    padding-bottom: 40px;

    & > .title {
        @include replace-text-with-dimensions('lp.png');
    }

    input {
        height: 40px;
        padding: 10px;
        margin: 0 10px;
        border: 1px solid white;

        &#miles_number {
            width: 70px;
        }

        &#postcodesearch {
            margin-right: 0;
            width: 190px;
            margin-top: 5px;
        }
    }

    .searchicon {
        
        background: $iedesign-primary2 url("../images/icons/search.png") no-repeat 5px 7px;
        background-size: 25px 25px;
        padding: 9px 0 9px 36px;
        margin-left: -7px;
        border: 1px solid $iedesign-white;
        position: absolute;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin-top: 5px;
    }

    a.findopportunities {
        position: absolute;
        left: 15px;
        bottom: -20px;

        color: $iedesign-white;
        padding: 10px 45px 10px 25px; 
        border: 4px solid white;
        font-size: 16px;
        font-weight: 400;
        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
        background: $iedesign-secondary;

        &:after {
            background:  url('#{$layout_image_path}/icons/arrow-right.png') no-repeat 0 0;
            width: 29px;
            height: 18px;
        }

        &:hover {
            text-decoration: none;
            background: $iedesign-secondary - 30;
        }
    }   

    @media all and (min-width: $desk-start ) {

    }

}

@media all and (min-width: $desk-start ) {

    .findopportunitiestopform {
        float: left;
        width: 65%;
        min-height: 200px;
    }   

    .createopportunityblock {
        float: right;
        width: 34%;
        min-height: 200px;
    }

    .createopportunityblock + .clearfix {
        width: 100%;
        float: none;
        overflow: hidden;
        height: 0;
    }
}


#block-block-10.help-teachers {
    background: $iedesign-primary3;
    background: rgba($iedesign-primary3, 0.8);  
    color: $iedesign-white;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 15px;

    h2 {
        color: $iedesign-white;
        font-size: 18px;
    }
}

#block-views-promote-opportunity-block-2 {
    padding: 15px;
    background: rgba($iedesign-primary3, 0.8);
    color: $iedesign-white;
    margin-bottom: 15px;

    .title {
        color: $iedesign-white;
        font-size: 18px;
    }

    .view-promote-opportunity {
        & > div {
            padding-left: 15px;
        }
    }

    .views-field-nothing-1 {
        border: 1px solid $iedesign-lightgrey;
        padding: 5px;
        margin-bottom: 5px;
        @include clearfix;

        &:hover {
            background: $iedesign-primary2;
        }

        a {
            color: $iedesign-white;
            font-weight: normal;
        }

        .notified_picture {
            float: left;
            width: 60px;
        }
        .notified_name {
            padding-left: 75px;
        }
        .notified_jobtitle {
            padding-left: 75px;
        }
    }

    @media all and (min-width: $desk-start ) {
        float: right;
        width: 34%;
    }
}

.path-opportunities-dashboard {

    position: relative;
    
    .dash-message-box,
    .activeteachersdash,
    .activelpdash {
        float:left;
    }


    .call-to-actions {

        .call-to-action {
            background: $iedesign-primary;            
            color: $iedesign-white;
            position: relative;
            margin-bottom: 40px;
            .title {
                color: $iedesign-white;
                font-size: 18px;
            }

            &.even {
                background: $iedesign-secondary;
            }

           
            .action-button { 

                position: absolute;
                bottom: -24px;
                right: 10px;
                padding-left: 10px;

            }
        }
    }


    .message-box {

        margin: 0;

        @media all and (min-width: $lap-start ) {
            padding-left: 4%;
        }

    }

    .landing-page-header {
        display: none;
    }

}

.content-bottom .call-to-action {
    background: rgba($iedesign-lightgrey, 0.7); 
}

.ui-widget-overlay {
    opacity: 0.5;
    background: #000; 
}

.ui-dialog {
    padding: 5px;
    border: 1px solid $iedesign-grey;

    .ui-dialog-titlebar {
        background: rgba($iedesign-primary3, 0.7);
        border-radius: 0;
        //display: none;
    }

    #dialog-confirmation ~ .ui-dialog-titlebar {
        //display: block; 
    }

    .ui-dialog-content {
        padding: 15px;
        font-size: 14px;
        color: $iedesign-primary3;
        height: auto !important;
        border: none;
    }

    .ui-dialog-titlebar-close {
        padding: 6px !important;
        float: right;
        background: transparent !important;
        border: none;

        .ui-button-text {
            display: none;
        }

        .ui-icon-closethick {
            background-color: $iedesign-white;
        }
    }

    .ui-button {
        background: rgba($iedesign-primary3, 0.7);
        border-radius: 0;
        color: $iedesign-white;
        padding: 5px 15px;
    }

    .ui-dialog-buttonpane {
        border: none;

        .ui-dialog-buttonset {
            text-align: center;
        }
    }
}

.call-to-actions {
    .call-to-action {
        padding: 5px;
    }
}


#block-block-14 {
    visibility: hidden;
}

#block-views-resource-download-link-block {
    float: right;
    max-width: 300px;

    padding: 15px;
    background: rgba($iedesign-primary3, 1);
    color: $iedesign-white;
    margin-left: 20px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    font-weight: normal;
    font-size: 14px;
    @include clearfix;

    .title {
        font-weight: normal;
        font-size: 17px;
        color: $iedesign-white;
    }

    .icon{
        width: 50px;
        height: 50px;
        float: left;
        margin-top: 24px;
        margin-left: 10px;
        background-size: 50px 50px;
    }

    .icon-locked .icon{
        background-image: url(../images/icons/icon-locked.png);
    }

    .icon-unlocked .icon{
        background-image: url(../images/icons/icon-unlocked.png);
    }

    .icon-everyone .icon{
        background-image: url(../images/icons/icon-everyone.png);
    }

    a {
        float: right;
        display: block;
        margin-top: 24px;
    }
}

#block-views-similarterms-block {
    padding: 15px;
    background: $site-background-colour url(../images/middleblock.jpg) repeat;
    border: 3px solid $iedesign-white;
    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);

    color: $iedesign-darkgrey;
    margin: 50px 0 20px;

    .title {
        font-size: 18px;
        color: $iedesign-primary3;
    }

    a {
        display: block;
        margin-top: 5px;
        padding: 5px;
        color: $iedesign-darkgrey;
        font-weight: normal;

        &:before {
            content: '- ';
        }
    }   

    @media all and (min-width: $lap-start ) {
        float: right;
        max-width: 300px;
    }
}

.gobackblock {
    padding: 0 !important;  //override the global block one

    p {
        margin: 0;
    }

    .goback {
        display: block;
        padding: 5px;
        padding-left: 45px;
        color: $iedesign-darkgrey;
        background: $iedesign-lightgrey url('../images/icons/arrow-left-dark.png') no-repeat 10px 10px;  
        border: 2px solid $iedesign-primary3;

    }
}

.help-teachers {
    display: none;
}


#sliding-popup {

    #popup-buttons button {
        border-radius: 0 !important;
        border: 2px solid white !important;
        color: $iedesign-primary3 !important;
        background: url('../images/middleblock.jpg') repeat scroll 0% 0% #F0F0F0 !important;
    }
}

//captcha
.block .captcha.form-wrapper {
    legend,
    .description {
        display: none;
    }

    .field-prefix {
        color: $iedesign-white;
        float: left;
        width: 50%;
        display: block;
        text-align: center;
        padding-top: 10px;
    }

    input[name="captcha_response"] {
        display: block;
        float: right;
        width: 50%;
    }

    margin-top: 0;
    padding-top: 0;
}

.similarnews {
    margin-bottom: 30px;

    & > .title {
        font-size: 17px;
        color: $iedesign-primary3;
    }    

    .view-similarterms > div {
        //margin-bottom: 20px;
        position: relative;
        float: left;
        width: 100%;
        //background: rgba($iedesign-primary3, 0.7);
        background: $site-background-colour url(../images/middleblock.jpg) repeat;
        border: 3px solid $iedesign-white;
        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
        margin-bottom: 30px;
        

        @media all and (min-width: $lap-start ) {
            width: 49%;
            margin-right: 1%;
        }

        @media all and (min-width: $desk-start ) {
            width: 32.6%;       //times 3 = 97.8 plus the extra 2% of margins
        }

        &.last {
            margin-right: 0;
        }

        .views-field-field-image img {
            width: 100%;
        }

        .views-field-title {
            
            padding: 15px;
            min-height: 80px;
            
            a {
                color: $iedesign-darkgrey;
                font-weight: normal;
            }
        }

        .more {
            display: none;

            background: url("../images/icons/arrow-right-dark.png") no-repeat 93% 15px,
                        url(../images/middleblock.jpg) repeat;
            border: 3px solid $iedesign-white;
            box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
            color: $iedesign-primary3;
            padding: 10px 35px 10px 15px;

            position: absolute;
            bottom: -25px;
            right: 15px;

            &:hover {
                border-color: $iedesign-primary3;
            }

            @media all and (min-width: $desk-start ) {
                display: block;
            }
        }
    }
}

#views-exposed-form-promote-opportunity-block-1 {
    @media all and (min-width: $lap-start ) {
        .views-exposed-widget {
            @include clearfix;

            label {
                width: 40%;
                float: left;
                font-weight: bold;
            }

            .views-widget {
                width: 60%;
                float: left;

                .form-checkboxes,
                .form-radios {
                    margin: 0;

                    label {
                        font-weight: normal;
                        float: none;
                        width: 100%;
                    }
                }
            }

            .form-type-radio {
                padding: 0;
                float: none;
                margin-bottom: 5px;
            }

            &#edit-field-experience-tid-wrapper {
                @include clearfix;

                label {
                    width: 100%;
                    float: none;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .views-widget {
                    @include clearfix;
                    width: 100%;
                    float: none;
                }    

                .form-type-bef-checkbox {
                    width: 50%;
                    float: left;

                    label {
                        font-weight: 300;
                    }
                }            
            }

            &#edit-field-valid-crb-value-wrapper {
                .form-type-select .description {
                    padding-left: 0;
                }
            }
        }
    }
}

#webform-client-form-318 {
    .form-type-checkbox {
        float: none;
        @media all and (min-width: $desk-start ) {
            float: left;
            width: 50%;
        }
    }

    #edit-submitted-resource-file-ajax-wrapper > .form-item > label,
    #edit-submitted-resource-image-ajax-wrapper > .form-item > label,
    & > .form-item > label {
        font-weight: 600; 
    }

    #edit-submitted-description-value {
        margin-bottom: 20px;
    }

    .grippie {
        display: none;
    }

    .text-format-wrapper label {
        visibility: visible;
        display: block;
        font-weight: 600;
    }

    .form-item {
        max-width: 595px;
    }

    .webform-container-inline .form-text {
        width: 421px;
        max-width: 100%;
    }
}

.footer .view-news a:hover {
    color: $iedesign-primary;
}

.resource_classification {
    color: $iedesign-primary3;
    font-size: 23px;
    font-weight: 300;
    margin-bottom: 10px;    
}

#block-views-exp-resource-library-page {
    //margin-top: -45px;
}

.unsureblock {
    position: relative;

    &:after {
        display: none;
        @media all and (min-width: $desk-start ) {
            display: block;
            content: " "; 
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9.5px 0 9.5px 8px;
            border-color: transparent transparent transparent $iedesign-secondary;

            position: absolute;
            right: -8px;
            top: 20px;
        }
    }            
    
}

.copyright_footer {
    padding: 5px;

    p {
        color: #1d1d1b;
        font-size: 12px;
        font-weight: normal;
        margin: 0;
    }

    @media all and (min-width: $desk-start ) {
        text-align: right;
        max-width: 1200px;
        margin: 0 auto;
        margin-top: -40px;
        padding-bottom: 13px;
        pointer-events: none;

        p {
            max-width: 518px;
            float: right;
        }
    }

    @media all and (min-width: $desk-start + 100 ) {
        p {
            max-width: 602px;
        }
    }

    @media all and (min-width: $desk-wide-start ) {
        p {
            max-width: 700px;
        }
    }
}

.lte-ie9 .copyright_footer {
    //undo some of the changes because POINTER EVENTS not supported
    @media all and (min-width: $desk-start ) {
        text-align: left;
        margin-top: 0;

        p {
            float: none;
        }
    }
}

.donateblock {
    margin-top: 45px;
    padding-bottom: 30px !important;

    .button {
        right: 12px !important;
    }
}

.view-id-promote_opportunity .view-header {
    display: none;

    p {
        font-size: 18px;
        color: $iedesign-primary3;
        font-weight: 400;        
    }
}

.mailchimp_signup {
    position: relative;
    margin-bottom: 30px;
    margin-top: 45px;

    .title {
        font-size: 18px;
    }

    .form-type-checkbox {
        float: none;
    }

    label {
        color: $iedesign-white;
    }

    .form-submit {
        position: absolute;
        right: 15px;
        bottom: -25px;
    }

    .mailchimp-newsletter-mergefields {
        margin-top: 10px;
    }
}

//autocompletes styling
#autocomplete {
    width: auto !important;    
    
    li {
        border-bottom: 1px solid $iedesign-lightgrey;
    }

    .reference-autocomplete {

        font-weight: 300;

        .views-field-title {
            font-weight: 400;
        }

        .views-field-field-opp-location {
            display: inline;

            div {
                display: inline !important;
            }

            .map-link,
            .additional,
            .street-address {
                display: none !important;
            }

            //only display postcode
            .postal-code {
                display: inline;
            }
        }

        div {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}

.block-views table th {
    //position: relative;
    padding: 0;
    min-width: 73px;

    &:last-child {
        min-width: 52px;
    }
}

.help_icon {
    margin-left: 3px;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        margin-bottom: -2px;
    }
}

.helpbox {
    border-radius: $default-border-radius;
    padding: 15px;
    background: $iedesign-primary3;
    color: $iedesign-white;

    z-index: 999;
    border: 1px solid $iedesign-white;
    position: absolute;
    left: 10%;
    right: 10%;
    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);

    @media all and (min-width: $desk-start ) {
        left: 25%;
        right: 25%;        
    }

    .closebox {
        color: $iedesign-primary3;
        margin-top: -7px;
        font-family: arial;
        background: $iedesign-white;
        padding: 0px 6px 0 6px;
        text-align: center;
        border-radius: 12px;
        cursor: pointer;
        float: right;
    }

    h3, ul, li {
        color: $iedesign-white;
        margin-bottom: 5px;
    }

    ul {
        margin-left: 0px;
        list-style: disc;
    }

}

.help_interest_status,
.help_opp_status {

}

.help_interest_status {
    background: $iedesign-primary2;
}


.location-gmap-find-address-button {
    border: 4px solid $iedesign-white;
    background: url(/sites/all/themes/iedesign/images/middleblock.jpg) repeat;
    color: $iedesign-primary3;
    padding: 10px 45px 10px 25px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
    position: relative;

    &:hover {
        border-color: $iedesign-primary3;
    }
}

.location .geo {
    display: none !important;
}


// Checkout

.cart_contents.form-wrapper{
    legend{
        display: none;
    }
    border: none 0;
    padding: 0;
    margin: 0;
}

.view-commerce-cart-summary{

    table.views-table{

        border-bottom:2px solid #efefef;
        margin-bottom: 0;

        tr th.views-field{
            background: #e54a91;
            border-bottom:2px solid #fff;
            color: #fff;
            padding:5px 12px;
            @media all and (max-width: $lap-start) {
               padding:6px;
               font-size: 13px;
            }
        }

        tr:nth-child(odd){
            background: #efefef;
        }

        tr td span a{
            width: 100%;
            padding: 12px;
            display: block;

            &:hover{
                text-decoration: none;
                color: darken(#00c2c2, 20%);
            }
        }

        td.views-field-edit-quantity,
        th.views-field-edit-quantity{
            text-align: center;
        }

        td.views-field-commerce-total,
        th.views-field-commerce-total{
            text-align: right;
        }

        td.views-field{
            vertical-align: middle;
            font-size: 19px;
            @media all and  (max-width: $desk-start) {
                font-size: 13px;
                vertical-align: top;
            }
        }

    }

    .view-footer{
        .commerce-price-formatted-components{
            border-top:2px solid #efefef;
            border-bottom:2px solid #efefef;
            width: 100%;

            .component-type-commerce-price-formatted-amount{
                font-size: 19px;
                font-weight: 300;

                .component-title{
                    font-weight: bold;
                }

                .component-total{
                    font-weight: bold;
                }
            }
        }
    }
}

.checkout-buttons{
    padding: 0;
    margin: 0;
    border: none 0;
    width: 100%;

    .checkout-cancel.form-submit,.checkout-back.form-submit{
        background: url("../images/icons/arrow-right-dark.png") no-repeat 93% 15px,url(../images/middleblock.jpg) repeat;
        border: 3px solid #fff;
        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
        position: relative;
        z-index: 20;
        display: block;
        border-radius: 0;
        padding: 10px 45px 10px 15px;
        width: auto;
        font-size: 16px;
        text-transform: none;
        font-weight: normal;
        font-family: 'Roboto Slab';
        color: #174c4c;
        float: left;

        &:hover{
            text-decoration: none;
            border: 3px solid #005e5e;
        }
    }

    .button-operator{
        float: left;
        padding: 25px 12px 0 12px;
    }

    #edit-continue{
        float: left;
    }
}

// Checkout Review

#edit-checkout-review{

    .pane-title{
        border: 0 none;
        td{
            float: left;
            text-align: left;
            padding: 20px 0px;
        }
    }

    .commerce-price-formatted-components{
        margin-bottom: 0;
    }

    .pane-data{
        border-bottom: 0 none;
    }

    .pane-data-full{
        padding: 0;
        tr{
            border: 0 none;
        }
    }

    .field-type-addressfield{
        text-align: left;
        background: #efefef;
        padding: 20px;
    }

    .checkout-review{
        margin-bottom: 0;
    }

}

#edit-commerce-payment{
    
    legend{
        padding: 20px 0px;
        font-weight: bold;
    }

    #edit-commerce-payment-payment-method{
        margin:0 0 20px 0;
        width: 100%;
    }

    label{
        padding: 0;
        margin: 0;
    }

    input{
        float: left;
    }

    .form-item{
        width: 100%;
        max-width: 100%;
    }

    .commerce-paypal-icons{
        padding: 0;
        margin: 0;

        span{
            padding: 0;
            margin: 5px 0;
        }
    }

}

//Payment redirect form

.payment-redirect-form{
    #edit-submit{
        margin-top: 20px;
    }
}