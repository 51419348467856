@charset "UTF-8";
@import '../../fonts/ss-standard/ss-standard';
@import  '../../fonts/ss-social/ss-social';

 

@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,300,700,100);

 body{

    font-family: 'Roboto Slab', serif;
    font-weight: 300;
    font-size: 15px;
    
 }

// Titles  and page headings
// If not specified, inuit font sizes will be used
// These  font size can also be overridden  in _vars.scss
 

// Main Page Title 
// Typically a h1 tag, largest on page
// ---------------------------------------------------

.page-title {

    color: $heading-color;
    font-weight: 400;
    margin: 10px 0 8px; 
}


.home .page-title {

    text-align:center;
 
}


// Titles
// Typically title in a listing or call to action boxes 
// and within content
// ---------------------------------------------------

.title {

     //text-transform:uppercase;    
    color : $heading-color;
    margin-bottom: 7px;
    font-weight: 300;
}



// Headings 
// Typical h3 within content
// ---------------------------------------------------

.heading {

     color:  $heading-color ;
    font-weight: normal;
    margin-bottom: 7px;

}


// Sub Headings 
// Typical h4 within content
// ---------------------------------------------------

.sub-heading {

    color:  $heading-color ;
    margin-bottom: 6px;
}


// Award title
// ---------------------------------------------------

.award-title {
    font-size: 18px;
    text-transform: none;
    margin-top: 24px;
}



// Remove top margin value from paragraphs directly after a heading

.page-title + p,
.heading + p,
.sub-heading + p,
.title + p {
    margin-top: 0;

}


// Helper class for Fancy title with yellow border,
// Will require additional styles when insitu to achieve storyboard
// ---------------------------------------------------


%feature-title {
    //text-transform: uppercase;
    font-size: 20px;
    border-bottom: 6px solid $labc-yellow; 
    margin: 0;
    float: left;
    clear: both;
    z-index: 10;
    position: relative;
}




p {
    
    font-size: 15px;
    margin-bottom: 1rem;
    // Introductory Paragraphs
    line-height: 24px;
    font-weight: 300;
    // 
    &.introduction,
    &.lead {
         font-size: 1.15em;
    }

}


b {
    font-family: 'Roboto Slab';
    font-weight: normal;
}

// Strap Line, in header header
// ---------------------------------------------------

.slogan {

     font-weight: normal;
    
    font-size: 13px; 


    color  : $slogan-colour;
    line-height: 120%;
    margin: 0;

    @media all and  (min-width: $lap-start ) {
        line-height: 120%;
        font-size: 15px;
    }
}



// lists

    .content  {

            ul, ol  {

                li {
                    list-style: inherit;
                    font-size: 15px;
                    line-height: 140%;
                    margin-bottom: 1em;
                }
            }

    }


%textwrapping{ 

  
    -ms-word-break: break-all;
     word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.main-content .content {
    h2 {
        color: $iedesign-primary3;
        //margin-bottom: 10px;
        font-weight: 600;
        font-size: 19px;
    }

    h3 {
        color: $iedesign-primary2;
        //margin-bottom: 10px;
        font-weight: 500;
        font-size: 17px;
    }

    h2 + h3 {
        //margin-top: -10px;
    }
}

.trainingpage {
     h2 {
        margin-bottom: 10px;
    }

    h3 {
        margin-bottom: 10px;
    }

    h2 + h3 {
        margin-top: -10px;
    }   
}