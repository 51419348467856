$grids :('small' 0px), ('medium' 640px), ('large' '960px') , ('desktopmenu' '1140px');

@mixin respond-to($size) {
	
 	@each $grid in $grids{
 		@if nth($grid, 1) == $size {
			@media screen and ( min-width:nth($grid, 2) ){
				@content; 
			}
		}
	}
}