.single-product-sidebar {
	a.btn-law {
		margin-top: 8px;
	}
}

.woocommerce-page {

	.resources a h2.resource-title {
		margin-top: 0;

		a:hover,
		a:focus,
		a:active {
			text-decoration: none;
		}
	}

	.single-product-details {

		.page-title {
			margin-bottom: 20px;
		}

		img {
			float: left;
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
}

label.product-attribute {
	display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;

	i {
		margin-right: 5px;
	}
}

.resource-detail .btn{
	text-align: left;
}

.single-product {
	table.related-resources {

		tr {
			border-bottom: 1px dotted #777;
		
			td {
				padding-top: 15px;
				padding-bottom: 15px;

				img {
					margin-bottom: 0;
				}
			}
		}

	}

	table.related-resources tr 
	table.related-resources tr td:nth-child(1) {
		width: 20%;
	}
	table.related-resources tr td:nth-child(2) {
		padding-left: 2%;
		font-size: 87.5%;
		width: 70%;
	}

}
