

.navigation-container {

    background-color: rgba(240,240,240,0.9);

    @media (min-width: 1024px) {
        nav {
            
            float: left;

            & + nav {
                float: right;

                &.submenu {

                    height: 43px;
                    margin-bottom: 0;

                    li {
                        padding: 10px 15px;

                        &:last-child {
                            padding-right: 0;
                        }

                        &.cart-quantity {
                            font-size: 9px;
                            height: 35px;
                            width: 35px;
                        }

                        &.top-menu-basket {
                            display: none;
                            padding: 0;
                        }
                        a {
                            &:hover {
                                text-decoration: none;
                            }
                        }

                    }
                }
            }


            ul {
                li {
                    display: inline-block;

                    &.current_page_item,
                    &.current-menu-item {
                        a {
                            color: $iedesign-secondary;
                            svg path {
                                fill: #e54a91;
                            }
                        }
                    }

                    a {
                        color: #5f5e5f;
                        font-size: 16px;
                        font-weight: 300;
                        text-transform: none;
                        border-left-color: #969696;
                        border-right-color: #323232;

                        &:hover {
                            color: #e54a91;

                            svg path {
                                fill: #e54a91;
                            }
                        }

                        /*@include tablet-portrait {
                            font-size: 16px;
                        }*/

                        svg {
                            width: 30px;
                            height: 27px;
                            transform: translateY(5px);

                            path {
                                fill: #5f5e5f;
                            }
                        }
                    }
                }
            }
        }

        .site-navigation .nav-main-link {

            padding-left: 10px;
            padding-right: 10px;
            text-transform: capitalize;           
        }
    }

    @media (min-width: 1140px) {
        ul li a.nav-main-link {
            font-size: 16px;
        }
    }

    .site-navigation .nav-main-link {
        @include tablet-portrait {
            padding-left: 12px;
            padding-right: 12px;
        }

        @media (min-width: 1140px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @include tablet-landscape {
        nav {
            ul {
                li {
                    a {
                        font-size: 15px;
                        padding: 7px 10px;

                        svg {
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1023px) {
        clear: both;
        position: absolute;
        display: block;
        transform: translateX(-240px);
        width: 240px;
        transition: transform 0.6s;

        li {
            border-bottom: 1px dotted #f0f0f0;
        }

        .submenu,
        .submenu ul {
            margin: 0;
            background-color: #464646;

            li {
                padding: 10px 15px;
                a {
                    color: #fff;
                    text-decoration: none;
                }

                &.top-menu-basket {
                    padding-left: 0;
                }
            }

            .cart-quantity {
                margin-left: 10px;
            }
        }
    }
}


@media (min-width: 1024px) {
    .menu-toggle {
        display: none;
    }

    .navigation-container .account-management {
        opacity: 1;
        padding: 0;
        max-width: 1200px;
        margin: 0 auto;
        padding-top:15px;

        &.not-logged-in{
            padding-top:26px;
        }
    }

    .navigation-container .header-navigation {
        background: none;
        padding: 0;
        max-width: 1200px;
        margin: 0 auto 0;
        position: relative;
        z-index: 1;
        overflow: visible;
        float: none;
    }

    .top-menu-basket-desktop {
        display: block;
        position: absolute;
        top: -75px;
        right: 0;
    }
}

.navigation-container {
    top: 0;
}

@media (max-width: 1023px) {

    .top-menu-basket-desktop {
        display: none;
    }

    #account-management {
        background: #009090;
    }

    #block-menu-block-3 .nav li a {
        color: #fff;
        text-transform: none;
    }

    .nav-main-link {
        svg {
            display: none;
        }
    }

    .active-menu {

        .row.full-width {
            margin-left: 0;
        }

        .site-navigation {
            z-index: 100;
            display: block;
        }
    }

    #account-management .nav li a {
        color: #fff;
        text-transform: none;

        &:before {
            display: block;
            content: " ";
            background: transparent url(../images/icons/arrow-right.png) 0 0 no-repeat;
            width: 30px;
            height: 20px;
            font-size: 0.8em;
            font-family: "SSStandard";
            position: absolute;
            right: 14px;
            top: 14px;
            display: block;
        }
    }

    .site-navigation {
        ul {
            background-color: #464646;

            li a {
                color: #fff;
            }
        }
    }
}

@media (min-width: 1024px) {
    .navigation-container .header-navigation .account-management {
        margin: 0;
        padding: 0;
        position: absolute;
        
        right: 50px;
        z-index: 999;
        top: -87px;
        width: 240px;

        &.not-logged-in {
            right: 0;
        }

        li {
            width: 176px;
            border: none;
            float: none;
            text-align: right;
            float: left;
            clear: left;
            width: 130px;

            a {
                width: 100%;
                text-align: left;
                display: block;
                text-transform: none;
                font-size: 12px;
                color: #464646;
                font-weight: 400;
                border-bottom: 1px dotted #8dcfcf;
                padding: 5px 0;
            }
        
           // &:first-child,
            &:last-child {
                a {
                    border-bottom: 0;
                }
            }
        }
    }
}

.menu-toggle {
    font-size: 20px;
    line-height: 0px;
    padding-top: 8px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-decoration: none;
    color: white;
    background: #464646;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: block;
    cursor: pointer;
    padding: 0 15px 16px 14px;
    height: 50px;
    z-index: 40;
    font-size: 12px;
    transition: background 0.3s;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: #fff;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}


.social-icon {
    display: block;
}

.search.site-search.block-views-exp-site-page {
    display: none;
}

.footer-partners {
    li {
        float: left;
        margin-right: 5px;
    }
}
