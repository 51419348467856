#top-menu-basket {

	width: 43px;
    height: 43px;
	text-align: center;

	& > a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	span{
		font-size: 12px;
	}
}

#top-menu-basket {
	padding-left: 7px;
	@media screen and (min-width: 992px) {
		padding-left: 0;
	}
}

.cart-quantity{
	background: url('../images/basket.png') no-repeat;
	background-size: cover;
	width: 43px;
    height: 43px;
    padding: 16px 11px;
	font-size: 15px;
	font-weight: bold;
	text-align: center;
	color: #FFF;
	display: inline-block;

	&:hover {
		color: #e54a91;
	}
}

.product-cat-tags {
	margin-top: 10px;
}

.facetwp-type-search {
	margin-top: 20px;
}

.resources-count {
	font-weight: bold;
}

.woocommerce {
	.resources {
		.woof_products_top_panel {
			display: none !important;
		}

		.woocommerce-ordering {
			margin-right: 15px;

			@media screen and (max-width: 320px) {
				margin-top: 20px;
				width: 90%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.woocommerce,
	.woocommerce-page {
		img {
			margin-bottom: 20px;
		}

		.read-more {
			display: none;
		}
	}
}

.facetwp-pager {
	
   	color: #969696;
    font-family: 'Roboto Slab', sans-serif;
    font-size: 16px;
    text-align: center;
    border: 0;


	.facetwp-page {
		display: inline-block;
		background-color: #fff;
		border: 0;
		color: #969696;
	    padding: 0 .5em;
	    margin: 0;
	    overflow: hidden;

	    &.next {
			background-image: url('../images/arrow-right-dark.png');
			background-repeat: no-repeat;
			background-position: 93% 5px;
			padding-right: 45px;
			color: #969696;
    	}

    	&.prev {
			background-image: url('../images/arrow-left-dark.png');
			background-repeat: no-repeat;
			background-position: 3% 5px;
			padding-left: 45px;
    		color: #969696;
    	}

    	&.current-page {
	    	color: #009090;
    		font-weight: bold;
    		text-decoration: none;
    	}
	}
}


.woocommerce.woocommerce-page .main-content-inner nav.woo-pagination,
.tool-pagination {
	
	display: block;
	text-align: center;

	ul {
		display: inline-block;
	   	color: #969696;
	    font-family: 'Roboto Slab', sans-serif;
	    font-size: 16px;
	    position: relative;
	    clear: both;
	    overflow: hidden;
	    text-align: center;
	    border: 0;

	    li {

			background-color: #fff;
			border: 0;
			color: #969696;
		    padding: 0 .5em;
		    margin: 0;
		    float: left;
		    overflow: hidden;

			span {
				background-color: #fff;
				border: 0;				
			}

	    	&.current
	    	a.current,
	    	span.current {
		    	color: #009090;
	    		font-weight: bold;
	    		text-decoration: none;
	    	}

	    	a {

				color: #969696;

				&:hover,
				&:focus,
				&:active {
					background-color: #fff;
					text-decoration: none;
				}

	    		&.next {
	    			background-image: url('../images/arrow-right-dark.png');
					background-repeat: no-repeat;
					background-position: 93% 5px;
					padding-right: 45px;
					color: #969696;
		    	}

		    	&.prev {
	    			background-image: url('../images/arrow-left-dark.png');
					background-repeat: no-repeat;
					background-position: 3% 5px;
					padding-left: 45px;
		    		color: #969696;
		    	}
		    }
	    }
    }
}

#filters {

	background-color: rgba(240, 240, 240, 0.8);

	.facetwp-btn {
		right: 15px;
		top: 50%;
    	transform: translateY(-50%);
	}

	.facetwp-slider-wrap {
		margin-top: 20px;
	}

	.facetwp-slider-label {
		display: block;
		text-align: center;
	}

	.facetwp-facet {
		margin:  20px auto 0 auto;
		width: 90%;
	}

	.facetwp-type-checkboxes {
		margin: 0;
		width: 100%;

		.facetwp-checkbox {
			border-bottom: 1px solid #e6e6e6;
			margin-bottom: 0;

			&:last-child {

				margin-bottom: -20px;

				@media (min-width: 1025px) {
					margin-bottom: -24px;
				}
			}
		}
	}
}