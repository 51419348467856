// Mobile first styles
/*-----------------------------------------------------------------------------*/

/*
/*  Styles
/*
/*-----------------------------------------------------------------------------*/

 .view-dashboards {
    margin-top: 12px;
    .breadcrumb {
        //margin-bottom: 18px;
    }
    .view-header {
        .logout.button {
            margin-bottom: 4px;
        }
    }
}

.dashboard-region-cta {
    
    float: left;
    .call-to-actions {
        margin: 0;
    }
}
.dashboard-panel,
.dashboard-block {

    float:left;
    border-right: 10px solid $site-background-colour;
    border-bottom: 12px solid $site-background-colour;

    a {

      color : rgb(85,85,85);
      font-weight:normal;



      &:before{ 
          padding: 0 8px 0 0;
          content: "\00BB";
      }
        
    }



    .view-footer {

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        p {
            margin :0;
        }

        a {

            display: block;
            padding: 0  24px 14px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;

            &:hover {
                color : $labc-green;
                text-decoration: none;
            }

            &:before {
                display: none;
                content:"";
            }

            @extend .ss-navigateright.right;

            &:after {
                font-size: 9px;
                font-family:"SSStandard";
                padding-left: 6px;
            }
        }
    }
}


/*-----------------------------------------------------------------------------*/

/*
/*  Media queries
/*
/*-----------------------------------------------------------------------------*/


/*-----------------------------------------------------------------------------*/

/*
/*  IE overrides
/*
/*-----------------------------------------------------------------------------*/
 // HTML5 Boilerplate classes 
 .oldie {
}
.ie8 {
}
.ie7 {
}
.ie6 {
}
