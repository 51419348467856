// Language Select 
// ------------------------------------
/*
<div class="block select language-select clearfix">  

    <ul class="language-switcher-locale-session">

        <li class="en first active"><a href="/contact-us" class="language-link  session-active active" lang="en">English</a></li>
        <li class="cy last><a href="/contact-us?language=cy" class="language-link active" lang="cy">Cymraeg</a></li>

    </ul>

</div>

*/

.language-select {
    
    color:white; 
    width: 210px;
    cursor: pointer;
    padding-top: 14px;
    height: 30px;
    position: absolute;
    
    z-index: 99;

    top: -45px;
    left: -230px;

    @media all and  (min-width: $desk-start) {   

        top: 62px;
        right: 250px;
        left: auto;
        color: $text-color;
        width: 70px;
    }

    // SSIcon - Globe
    @extend .ss-globe;

    &:before {

        z-index: 10;
        font-family: "SSStandard";
        position: absolute;
        left: 0;
        top: 10px;
        width: 17px;

        // Animation
        transition: color 1s;

        @media all and  (min-width: $desk-start) {   
            top: 8px;
        }

    }

    &:hover{

        &:before {

            color: $labc-green;
        }

    }


    // List of Langs        
    .language-switcher-locale-session {

        list-style: none;
        margin: 0;
        padding: 0 20px 0 30px;
        position: absolute;
        top:  7px;
        left: 0;
        width: 100%;
        background: rgba($labc-black, 0.8);

        @media all and  (min-width: $desk-start) {      
            padding-left: 20px;
            background: none;
        }


        //  SS Icon Down Arrow
        @extend .ss-navigatedown;
        
        &:before {

            font-family: "SSStandard";
            position: absolute;
            right: 0;
            top: 2px;
            font-size: 0.9em;


        }
    }

    // Lang
    .language-link  {

        text-decoration:none;
        color: $lang-select-color;
        font-size: 15px;
        font-weight: normal;
        display: none;
        position: relative;
        font-family: 'HelveticaNeueLTPro-Bd', sans-serif;
        text-transform: uppercase;
        opacity: 0;
        padding: 2px 6px;

    
        @media all and  (min-width: $desk-start ) {   
            color: $lang-select-color-desk;
            font-size: 12px;
        }

        &.session-active {
            display: block;
            opacity: 1;
        }

        // Animation



    }


    


    li {
       
        padding:0;
        margin: 0;
    }

    // Active Lang select Drop down

    &.active {

        .language-link  {
        
           opacity: 1;
           display: block;

           transition: opacity 0.5s ease;
                

            &.session-active {
                color : $labc-green;
            } 
        }


    }
}

