// Qutotes/ Cites /Credits


.credits {

    background: $labc-grey;
    padding: 0 20px;

    .title,
    .heading,
    .sub-heading {

        font-weight: normal;
        padding: 8px 0 ;
        font-size: 11px;
    }
}



.photo-credits {

    .photo-credit-reveal  {

        text-align: right;
        cursor: pointer;
        @extend .ss-plus;
        position: relative;
        padding-right: 12px;

        &:before {

            display: block;
            position: absolute;
            right: -6px;
            top : 10px;
            font-size: 8px;
            font-family: "SSStandard";
            transition: all 0.25s;
        }

        &.active:before{

            transform: rotate(45deg);

        }
    }


    .text {

        margin: 0;
        padding: 0 0 12px;

        a {
            display: block;
            font-size: 0.9em;
            padding: 6px 0 0 ;
        }
    }

}



.js {

   .photo-credits {
    
        .text{

            display: none;
        }
    }
}