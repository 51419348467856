
    // Filters  
    // ---------------------------------------------

    .filter {

        @extend .cf;

        .ajax-progress-throbber { display: none; } 

        .title {

            color: $iedesign-primary2;
            font-size: 16px;
            font-weight: bold;
        }

        .views-exposed-form {

            padding: 10px 0 0;
            position: relative;
            z-index: 5;
            float: left;
            width: 100%;
            //border-top: 1px solid $labc-grey - 40;
            margin-top: -3px;
        }

        .form-item {

            max-width: 100%;
        }

        @media all and (min-width: $desk-start )  {

            padding: 0 10px 0 0;
            margin:  24px 0;

           .views-exposed-form {

               padding: 5px 0; 
            }
    
        }

        .loading  {

            position: relative;

            .views-exposed-widgets  {

                opacity: 0.6;

                transition: opacity  0.24s cubic-bezier(0.550, 0.085, 0.680, 0.530);
            }
 

            .throbber {

                position: absolute;
                right: 0;
                top: -32px;
            }

        }

        .form-type-date-popup { 
            position: relative;

            &:before {
                width: 32px;
                height: 32px;
                display: block;
                background: transparent url(../images/icons/calendar.png) no-repeat;
                background-size: 32px 32px;
                position: absolute;
                right: 0;
                top: 5px;
                content: ' ';
            }

            input {
                width: 80%;
            }
        }

        .views-widget-filter-distance {
            #edit-distance-search-units {
                display: none;
            }
        }
    }



    // Filter in the first Sidebar

    .sidebar-first {

        .filter {

            padding: 5% 16px 0;
        }
    }


    /* Library filter 
    ------------------------------------------------*/

    .fullbox .library-filter {
        margin-bottom: 0 !important;
    }

    .library-filter {

        padding: 0px !important;
        margin-top: 0px;
        @media all and (max-width: $desk-start ) {
            margin-bottom: 20px;
        }

        &.block{        

            .page .sidebar-second & {

                @include opaqueBackground;
            }

        }

        .form-item {
            margin-bottom: 5px;
        }


        #edit-title-wrapper .views-widget {
            padding: 18px 15px 15px;
            background: $iedesign-lightgrey;

            .form-type-textfield {
                background: $iedesign-primary2 url("../images/icons/search.png") no-repeat 96% 9px;
                background-size: 25px 25px;

                input {
                    width: 85%;
                }
            }
        }
            

        .title {
            //color: $iedesign-primary2 !important;
            background: $iedesign-secondary;
            padding: 15px;
            font-weight: normal;
            margin: 0;
            position: relative;

            &:after {
                display: none;
                @media all and (min-width: $desk-start ) {
                    display: block;
                    content: " "; 
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9.5px 0 9.5px 8px;
                    border-color: transparent transparent transparent $iedesign-secondary;

                    position: absolute;
                    right: -8px;
                    top: 20px;
                }
            }            
        }

        //add id to have more specificity
        &#block-views-exp-opportunity-listing-page {
            .title {
                color: $iedesign-white !important;
                background: $iedesign-secondary;   

                margin: -15px;
                margin-bottom: 0;
                padding: 10px 15px;

                position: relative;
                font-size: 16px;

                &:after {
                    display: none;
                    @media all and (min-width: $desk-start ) {
                        display: block;
                        content: " "; 
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 9.5px 0 9.5px 8px;
                        border-color: transparent transparent transparent $iedesign-secondary;

                        position: absolute;
                        right: -8px;
                        top: 14px;
                    }
                }
            }
        }

        @media all and (min-width: $desk-start ) {

        }

        .description {
            font-style: italic;
            padding-left: 5px;
            color: $iedesign-grey;
            display: none;
        }

        label { 
            text-decoration: none;
            font-weight: normal;
            cursor: auto;
            //text-transform: uppercase;
            //color : white;
            width: auto;
            font-size: 15px;
            padding: 17px 30px 17px 15px;
            margin-bottom: 0;
            font-family: "Roboto Slab", sans-serif;
            font-weight: normal;
            //background:  $desk-secondary-nav-background ;
            font-weight: normal;

            padding-left: 15px;

            color: $iedesign-white;
            background: $iedesign-darkgrey;

            // Animation
            transition: background 0.3s; 
        }

        .morefilter {
            clear: both;

            .moreoptions {
                color: $iedesign-primary2;
            }
        }


        .bef-select-as-checkboxes {

            margin: 0;
        }

        #edit-sort-bef-combine-wrapper {
            display: none;
        }


        .form-type-bef-checkbox {

            position: relative;

            margin-bottom: 0;

            input {
                position: absolute;
                top: 13px; 
                right: 14px;

                width: 30px;
                height: 30px;
            }

            label { 

                background: $iedesign-lightgrey;
                color: $labc-black;
                border-bottom: 1px solid $iedesign-lightgrey - 40;
                cursor: pointer;
            
                &.active{

                    background: $iedesign-lightgrey - 30;
                }

                &:hover{ 

                    background: $iedesign-lightgrey - 20;

                }
            }

            &.highlight label {
                background: $iedesign-primary;// - 20;
                color: $iedesign-white;
            }
        }


        .show-more  {
            position: relative;
            cursor: pointer;
            width: 100%;
            font-size: 15px;
            padding: 10px 18px 10px 15px;
            display: block;
            font-family: "Roboto Slab", sans-serif;
            font-weight: normal;
            //text-transform: uppercase;
            background-color: $iedesign-lightgrey - 20;
            color: $iedesign-primary3;
            text-align: center;

            &:hover  {

                background-color: $iedesign-lightgrey - 40;
            }

            &:before {
                content: ' ';
            }

            &:after {
                content: ' \25BC';
            }

            &.ss-directup:after {
                content: ' \25B2';
            }
        }
    }

    .opp-filter {
        padding: 15px !important;
        background: $iedesign-lightgrey url(../images/middleblock.jpg) repeat !important;

        border: 1px solid $iedesign-grey;
        margin-top: 0px;
        @media all and (min-width: $desk-start ) {
            border: none;
        }

        .title {
            &:after {
                //display: none;
            }
        }
        label {
            font-size: 14px;
            background: none;
            color: $iedesign-darkgrey; 
            padding-left: 0;
        }

        .form-select {
            margin-bottom: 0;
        }
    }

    @media all and (min-width: $desk-start ) {
        .logged-in .opp-filter {
            margin-top: -18px;
        }
        .not-logged-in .opp-filter {
            margin-top: 34px;   
        }
    }

    #library-sort {

        @media all and  (min-width: $lap-start) {
        
            width:200px;
            float: right;
        }

        label {
            color: $iedesign-primary3;
            float: left;
            margin-right: 10px;
            padding-top: 13px;
        }

        select {
            color: $iedesign-primary3;
            padding: 11px 5px;
            option {
                color: $iedesign-primary3;
            }
        }
    }
  



    /* News filter 
    ------------------------------------------------*/

    .news-exposed-filter-block {
        //background: $iedesign-lightgrey url(../images/middleblock.jpg) repeat !important;

        .title {
            //color: $iedesign-darkgrey !important;
            font-weight: normal;
        }

        #edit-tid-wrapper {
            display: none;
        }

        .views-exposed-widget {

            label {
                //display: none;
                color: white;
                margin-bottom: 15px;
            }

            display: block;            
            //margin-left: 10px;
            margin-bottom: 10px;

            select {

                width: 100%;
            }
    
            .form-type-date-select {

                margin-bottom: 0;
            }


            @media all and  (min-width: $lap-start) and (max-width: $desk-start - 1)  {
                    
                float:left;
                width: 45%; 

                &.views-widget-filter-date_filter {

                    float:right;
                }

                &.views-reset-button {

                    //width: 100%;
                    text-align: center;
                }
            

                .date-year .select-wrapper {

                    margin-top: 45px;
                }

                .form-submit {
                    margin-top: 25px;
                }
            }

        }


        .views-reset-button {
            width: auto;

            .form-submit {
                float: left;
                margin-right: 10px;

                //padding: 12px 24px;

                //margin: 0 auto;

                @media all and (min-width: $desk-start )  {

                    margin: 0;
                }
            }
        }

        .form-submit {
            //background: $iedesign-lightgrey url("../images/icons/arrow-right-dark.png") no-repeat 91% 15px;
            background: url("../images/icons/arrow-right-dark.png") no-repeat 93% 15px,
                        url(../images/middleblock.jpg) repeat;
            border: 3px solid $iedesign-white;
            box-shadow: 1px 1px 3px rgba(10,10,10,0.3);

            position: absolute;
            bottom: -35px;
            right: -9px;

            &:hover {
                border-color: $iedesign-primary3;
                background-color: $iedesign-lightgrey;
            }
        }

        #edit-submit-news.form-submit {
            margin-right: 10px;
        }
    }


.filter #edit-field-start-date-value-wrapper,
.filter #edit-field-end-date-value-wrapper {
    @include clearfix;

    & > label {
        float: left;
        width: 30%;
    }

    & > .views-widget {
        float: left;
        width: 70%;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.textwidget {

    .facetwp-toggle {
        display: block;

        &.facetwp-hidden {
            display: none;
        }
    }

    .facetwp-checkbox {
        padding-right: 30px;
    }    

    .facetwp-checkbox,
    .facetwp-toggle {
        background-position: calc(100% - 18px);
        padding: 13px 18px
    }
}