.woocommerce-checkout {
	#payment #place_order {
		border: 3px solid #fff;
	    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
	    position: relative;
	    z-index: 20;
		border-radius: 0;
	    padding: 10px 15px;
	    width: auto;
	    font-size: 16px;
	    text-transform: none;
	    font-weight: normal;
	    font-family: 'Roboto Slab';
	    color: #fff !important;
	    text-transform: uppercase;
	    background-color: $iedesign-secondary;
	}

	table.shop_table th {
		background-color: lighten(#464646,40%);
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		color: #fff;
		padding: 1% 2%;
		font-size: 87.5%;
	}
}