
$header-breakpoint :  620px;

.content-header {

    position: relative; 

    background: $iedesign-pink ;
    color: $iedesign-white;
    margin-top: 12px;
 

    .page-title {

        margin: 0;

        color: $iedesign-white;
        padding :  20px;
        font-size: 26px;
        text-transform:none;
        line-height: 100%;
        font-weight: 300;

        @media all and  (min-width: $header-breakpoint) {

            background: none;
            width: 35%;
            float: left;
            padding: 20px 20px 10px;
        }
    
        @media all and  (min-width: $desk-start) {
            
            font-size: 30px;       
            padding-top: 18px;
            width: 30%;            

        }
  
    }


    // Second part of title
    // text highlight for a title

    .second-part {

        color : $iedesign-darkgrey;

        @media all and  (min-width: $desk-start) {

            display: block;
        }
    }


    
    .header-wrapper {

        height: 100%;

        .button {

            margin : 8px 0;
        }
    }

    .view-empty {
        
        @media all and  (min-width: $header-breakpoint) {
            
            padding: 20px;
        }
        
        p,
        .page-title {

            padding: 10px;
            float:none;
            width: auto;

        }

        .second-part {display:inline;}
        .lead { 
            padding-top: 8px;
        }
        
         @media all and  (min-width: $header-breakpoint) {

            .page-title, 
            p {
                padding:0;
            }

         }


    }

    .header-image {

       position: relative;
       overflow: hidden;
       @extend .five-eighths;
       float: right;

        &:before {

            content: ""; 
            @include block-arrow(up, $iedesign-pink ,  20px, 10px ); 
            position: absolute;
            display: block;
      

            left: 10%;
            bottom: 0;

            @media all and  (min-width: $header-breakpoint ) {
                border-bottom:none;
                @include block-arrow(right, $iedesign-pink , 10px, 20px ); 

                left:0;
                bottom: 10%;

            }

        }
       // Max with set as we need to extend a width class rather than using the namespaced classes
       @media all and  (max-width: $header-breakpoint - 1) {

            width: 100%;
       }
        img {

            display: block;
            width: 100%;
            height: auto;
    
        }

    }

    .introduction-wrapper {
                            
        @extend .three-eighths;

        float: left;

        padding:  0 20px;
           
        // Max with set as we need to extend the three eigths class

        @media all and  (max-width: $header-breakpoint - 1) {
            
            width: 100%;
            padding: 20px;

        }
      

    }

    .introduction {
        
        font-weight:300;
      
        line-height: 140%;
        
        a {
            font-weight:300;
            color: $iedesign-white;
            font-weight:normal;
        }




        @media all and  (min-width: $header-breakpoint ) {

            font-size: 16px;

            margin-bottom : 8px;        
        }

        @media all and  (min-width: $desk-start) {

            font-size: 19px;
        }



    }

    .calltoaction-button {

        margin: 12px 0 0;


        @media all and  (min-width: $header-breakpoint ) {

            position: absolute;
            bottom: 12px;
        }
    }


}


// Landing Page Specific 
// -----------------------------------------------------------

.landing-page-header {

    .introduction-wrapper {

        margin-bottom: -10px;

    } 
}

// News Page Specific 
// -----------------------------------------------------------

.page-news .main-content {
    .page-title {
        //background: rgba($iedesign-lightgrey, 0.8);
        padding: 0;
    }
}    

.news-page-header {

    .news-title-wrapper {
 
         @media all and  (min-width: $header-breakpoint) {
            float:left;
            width: 35%;
        }

    }


    .header-image {
         &:before {

            content: ""; 
            border:none;
            @include block-arrow(down, $iedesign-pink ,  20px, 10px ); 
            position: absolute;
            display: block;
      

            left: 10%;
            top: 0;

            @media all and  (min-width: $header-breakpoint ) {
                border-top:none;
                @include block-arrow(right, $iedesign-pink , 10px, 20px ); 
                top:auto;
                left:0;
                bottom: 10%;

            }
        }
    }

    .news-header-link{

        color: $iedesign-white;
        padding :  20px 20px 14px;
        font-size: 19px;
        line-height: 100%;
        display: block;

        text-decoration:none;
        letter-spacing: -1.4px;

         font-weight: 300;
        // text highlight for a title
        .second-part {

          color : $iedesign-darkgrey;
        }

        @media all and  (min-width: $header-breakpoint) {
            font-size: 26px;
            //color : white;
            padding:20px;
    
        }
   

        @media all and  (min-width: $desk-start) {

           position: absolute;
           top: 0;
        }
    }

    .news-title {

        font-size: 19px;
        width: 100%;
        line-height: 140%;

        @media all and  (min-width: $desk-start) {
            
            padding :0 0 0  20px;
            bottom: 14%;
            z-index: 100;
            width: 30%;
            position:absolute;

        }
  
    }
}