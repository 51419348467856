


// Sytem Messages with Main content



    .messages {

        position: relative;
        margin: 16px 0;
        background-color: $iedesign-white;
        border: 4px solid $info-message;
        //@include border-radius; 
        padding: 10px 10px 10px 40px;
        color: $text-color;

        a {
            color: $text-color;
            text-decoration: underline;
        }

        @extend .ss-info;
   
        // Error Message
        &.error {

            border-color : $error-message;
            color: $labc-black;
            @extend .ss-alert;

            ul{
                list-style: none;
            }

            &:before {

                color : darken( $error-message, 30%);
            }

        }


        // Success /Status Message
    
        &.status {
          
            border-color : $success-message;

            &:before {

                color :$labc-green;
            }
        }

        &:before {

            font-size: 1em;
            position:absolute;
            left: 12px;
            top: 12px;
            display: block;
            font-family: "SSStandard";
       
        }


        ul {
            margin: 0;
        }

        li {
            margin-bottom: 8px;

        }

        p {
            margin: 12px 0 0;
        }

        > p {
            margin: 0;
        }

    }

 

//full screen ajaxloader
#fullscreen-loader-container{
    display:none;
    position: absolute;
    top:0;
    left:0;    
    height: 100%;
    width:100%;
    z-index: 9999;
    background: none;
    opacity: 1;

    #pcle-loader{
        position: fixed;
        top:25%;
        left: 45%;
        margin-left: auto;
        margin-right:auto;
        width: 120px;
        height: 120px;
        background: $iedesign-white url('../images/ajax-loader-2.gif') no-repeat 10px 10px; 
        background-size: 100px 100px;
        border-radius: 60px;
        -webkit-box-shadow: 0px 0px 44px rgba(10, 10, 10, 0.9);
        -moz-box-shadow: 0px 0px 44px rgba(10, 10, 10, 0.9);
        box-shadow: 0px 0px 44px rgba(10, 10, 10, 0.9);        
    }
}

.wait #fullscreen-loader-container {
    display: block;
}