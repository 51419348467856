.event-info {
  background-color:$labc-grey;
  padding:20px;
  .views-label {
    float:left;
    font-weight:700;
    margin-right:5px;
  }
}

.page-event-book {
  h1.page-title {
    margin:0;
    padding: 9px 20px;
    background:$labc-black;
    color:white;
  }
}

#multipage-nav {
  .completed {
    background-color: $labc-yellow;
  }
  .active {
    background-color:$labc-orange;
    color:white;
  }
}

// Hide fields

#edit-field-status, #edit-field-current-step, #edit-field-event-entity {
  display:none;
}

.booking-steps {
  .title {
    span{
      font-family: 'HelveticaNeueLTPro-Roman', sans-serif;
    }
  }
}

/* Event details page */

.event-info-wrapper, .venue-info-wrapper{
  @extend .cf;
  background-color: $labc-grey;
  margin-bottom:15px;
}

.event-info-wrapper {

  ul {
    list-style:none;
    margin:0;
    li {
      span {
        font-family: 'HelveticaNeueLTPro-Bd', sans-serif;
      }
      .calltoaction-button.accent{
         margin-left:0;
         margin-top:10px;

      }
      &.small {
        font-size:14px;
        margin-top:10px;
      }
    }
  }

}
.event-info-left, .event-info-right{
  max-height:248px;
  float:left;
}

.event-info-left, .venue-info-right {
  width:50%;
  padding:20px;

}
.event-info-right {
  width:50%;
}

.venue-info-wrapper {

  .venue-info-right{
    float:right;
  }
  .gmap-control {
  float:left;
    width:50% !important;
    height:283px !important;
  }
}

.views-field-field-details, .views-field-field-trainer {

  width:50%;
  float:left;
  padding:20px 20px 20px 0;
  .calltoaction-button.accent{
    margin-left:0;

  }
}

.block-views-event-calender-block-1 {
  width:50%;
  float:right;
}