#for-lawyers-grid h2 { /* line 407 _style.scss */
	color: #005e5e;
    font-weight: 600;
    font-size: 19px;
}

.contact-container {
	.gform_wrapper .top_label select.medium,
	.gform_wrapper .top_label input.medium,
	.gform_wrapper textarea.medium {
		width: 100%;
	}

	.gform_wrapper .top_label .gfield_label {
		margin: 0;
		font-weight: normal;
	}

	.gfield {
		margin-bottom: 22px;
	}

	.gform_wrapper .gfield_required {
		color: #00c2c2;
	}

}

.main-content .entry-content .news ul li {
	list-style-type: none;
}

.btn.btn-default {
	color: #fff;
}

/* 404 */

@media (min-width: 1200px) {
    .main-content-inner.pull-right-lg.col-sm-12 {
        float: right;
    }
}

.post-inner-content,
.logged-in .post-inner-content{
    padding: 45px 50px;
}

.woocommerce-breadcrumb {
	display: none;
}

.child-sidebar-menu {
	.has_children {
		ul {
			display: none;
		}

		&.current_page_ancestor,
		&.current_page_item {
			ul {
				display: block;
			}
		}
	}
}

/* footer widget */

.footer {

	.widget {

		h3.widget-title {
			background-color: transparent;
			color: #fff;
		    border-bottom: 1px dotted white;
		    font-size: 20px;
		    padding-bottom: 6px;
		    margin-top: 20px;
		    margin-bottom: 12px;
		    margin-right: 12px;
		    padding-top: 0;
		    padding-left: 0;
		    padding-right: 0;
		    font-weight: 300;
		}

		.child-sidebar-menu {
			padding: 0;
		    margin: 0;
		    float: none;

		    li { 
				background-color: transparent;

		    	a {
		    		display: inline-block;
		    		font-size: 12px;
		    		color: #fff;
				    font-weight: normal;
				    text-decoration: none;
				    padding: 0 0 10px 0;
				    margin: 0;
				    border-bottom: 0 !important;
		    	
					&:hover {
						background-color: transparent;
						color: #00c2c2;
					}
		    	}
		    }
		}
	}
}
.woocommerce-page .woocommerce {

	.col2-set .col-2,
	form .form-row-last {
		float: left;
	}

	form .form-row-first, 
	form .form-row-last, 
	form .form-row-first, 
	form .form-row-last {

		width: 100%;

		@media (min-width: 992px) {
			width: 50%;
		}
	}
  
	.woocommerce-MyAccount-content {
		float: left;

		@media (min-width: 992px) {
		    width: 75%;
		}
	}
}

.ir-credits{
	position: absolute;
	overflow: hidden;
	width:1px;
	text-indent:100%;
	white-space:nowrap;
}





.woocommerce form .form-row .optional{
	display:none;
}