
.events  {

    margin : 30px 2% 0 0;
    clear:both;
    background: $labc-grey;
    padding:30px 20px;
    position:relative;
    .title {
     position:relative;
        font-size: 20px;
        z-index: 99;
        span{

            border-bottom: 6px solid $labc-yellow;
        }

        margin-bottom : -3px;
    }
    
    .button {
           
        width: 120px;
        right:20px;
        background: $labc-grey;
        border:2px solid #dedede;
        z-index: 999;
        text-align:left;
        text-decoration: none;
        margin: 0 20px;


       @media all and  (min-width: $desk-start) {

            position: absolute;
            top:20px;
            bottom:auto;
            right:20px;
            margin: 0;
       }

    }

    .item-list {
        
        padding-top : 20px;
        position: relative;
        z-index: 80;
        border-top : 1px solid #dedede;

    }

    .event {

        padding : 0 1% 10px;
    
 
    }

      img { 
            width:100%; 
            display:block;
        }
            
        .content {

  

        font-size: 12px;
        padding: 4px 0;

        h3 {

            text-transform: uppercase;
            font-size: 14px;
            color: $labc-orange;
            font-weight: normal;
            margin: 0;
        }
        
        .date{ 

            font-size: 11px;
            margin: 0;
        }


        p {
            margin: 12px 0;
            font-size:13px;
        }

    }

    a {
        color: $labc-green;
        text-transform: uppercase;
        text-decoration :  underline;
        font-size: 12px;
        display: block;
        padding-right:10px;

        @media all and  (min-width: $desk-start) {
            position: absolute; 
            bottom:0;
        }
        
        &:hover {

            color: $labc-green - 20;

        }

    }

    h3, a, .date  {           
        font-weight: normal;     
        font-family: 'HelveticaNeueLTPro-Bd', sans-serif;
     }

}