
.breakout-box {
        
    .item-list {

      margin-bottom: 8px;

    }

    li {
        
        //background-color: $labc-grey;
        float: left;
        margin: 0 0 14px;
        padding:  0 0 14px;
        
        .button {

            display: inline-block;
            margin:  0 12px;
        }

    }


    .text,
    .calltoaction-content {

        font-weight: normal;
        color : $text-color;
        font-size : 13px;
    }

    .calltoaction-content {

      padding: 15px 0 0;
      font-family: 'Roboto Slab',  sans-serif;

      h2, h3, h4, h5 {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 19px;
      }

    }

    .calltoaction-image {

        img {

            width: 100%;

            display: block;
        }

    }



}
 


// Call to  action Sidebar
//---------------------------------------------------


.call-to-actions-block.sidebar  {


    .item-list {


    }

    li {
        
        /*@media all and (min-width: $lap-start )  and (max-width: $desk-start)  {

            border-top :none;
           
            &:first-child {
                
                border-right: 9px solid $site-background-colour;
            }

        }*/

    }

}



// News
// --------------------------------------------------
 

.news-block {
        
    .title {

        color: $iedesign-primary;
         //text-transform: uppercase;
        font-size: 20px;
    }


    .item-list {
 
        border-top:none;
    }

    li {
        
        padding : 0;
        //border-top: 1px solid #efefef;
        margin: 0;
        float :none;
    }

    .news-item-title {

        margin: 0;
        padding: 0;

        a {
            color: $iedesign-white;
            font-weight: normal;
            text-decoration: none;
            padding: 0 0 10px;
 


            &:hover {

                //background-color: $labc-grey - 10;

                .text {
                    color: $iedesign-white - 20;
                }

            }
        }
    }


    .text {

        clear: both;
        display: block;
  
        padding: 4px 0 0 ;
        position: relative;
        font-family: 'Roboto Slab',  sans-serif;

        &:before {
          font-family: "SSStandard";
            position: absolute;
            right: -24px;
            top: 34%;
            font-size: 12px;
        }
    }

    .tag {
    
        display:inline-block;
        clear: both;
        padding: 2px;
        font-family: 'Roboto Slab', sans-serif;
        font-weight: normal;
        background: white;
        //text-transform: uppercase;
        clear: both;
        font-size: 12px;
        color: $labc-green;

    }


    .category{
        line-height: 90%;
        font-size: 0.7em;
        display: block;
        text-transform: none;
        color : $labc-black + 30;
    }
    

}