// Social Share & social navigation



// Social Share
//----------------------------------------------
.social-share {
    
    @media all and  (min-width: $desk-start) {
        padding: 0 18px;
    }

    .title {    
        font-size: 14px;

        color: $social-tilte-color;

        margin-bottom: 8px;
        
    }

}




 // Social Nav Icon Stlyes


 .social-icon {

    font-size: 20px;
    width: 36px;
    height: 36px;
    border: 2px solid  $social-nav-border;
    overflow: hidden;
    position: relative;
    border-radius: 30px;

    &:before {

        border-radius: 30px;
        position:  absolute;
        background: $iedesign-darkgrey;
        top: 0;
        left: 0;
        padding: 6px 10px;
        font-size: 15px;
        color :  $social-nav-color;
        width: 100%;
        height: 100%;
        font-family: "fontawesome";
    }


    &:hover {
        
        border: 2px solid  $social-nav-border-hover ;

        &:before {

            color:  $social-nav-color-hover;
 
         }

    }
}



 