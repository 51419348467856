


// Resource Library
//---------------------------------------------------------------
//---------------------------------------------------------------


// Main View Wrapper
//---------------------------------------------------------------

    .view-resource-library {
        
        .view-header  {

            
            padding:  12px 0 8px;
            float: left;
            width:100%;
            margin-bottom: 0px;

            @media all and  (min-width: $lap-start) {                
                width: 50%;
            }

            @media all and  (min-width: $desk-start) {
                width: 60%;
                margin-bottom: 34px;
                padding-top: 0;
            }

            

        }


        .views-field.views-field-commerce-price {
            float: right;
            padding: 28px 89px 0 0;
            font-style: italic;
        } 

    }
 




    // Search Result buttons
    //---------------------------------------------------------------

    .capsule-filter-search  {

        position: relative;
        float: left;
        margin: 0 6px 6px 0;
        display: block;
        padding: 4px 30px 4px 5px;

        font-size : 12px ;
        text-transform: uppercase;
        font-weight: normal;
        text-decoration: none;
        font-family:'Roboto Slab', sans-serif;
        font-weight: normal;

        background: $labc-grey - 50;
        color : white;

        cursor: pointer;

        &:hover {

            background: $labc-orange;
        }

        @extend .ss-delete;

        &:before {

            display: block;

            font-size: 12px;
            font-family: "SSStandard";

            position: absolute;
            top: 6px; 
            right: 10px;   
            z-index: 1;
 

        }
    }




    // A Resource in the library
    //---------------------------------------------------------------
    .resource { 


        float: left;
        //padding: 1px;
        margin-bottom: 20px;
        background: $iedesign-primary3;
        position: relative;
        border: 1px solid $iedesign-primary;
		width: 100%;

        .resource-title,
        .resource-text, 
        .date-wrapper {

            background:#FFF;
            padding:  0  24px ;
            margin:0;

            @media all and  (min-width: $desk-start) {

                padding-right: 9% ;
           }

        }

        .resource-title {
            padding-left: 135px;

            font-size: 18px;
            padding-top: 22px;

            a {
                font-weight: normal;
                color: $iedesign-primary3;
            }
        } 

        .resource-text {
            padding-left: 135px;

            padding-top: 24px;
            //margin-bottom:  5px;
            padding-bottom: 17px;
            min-height: 89px;
        }

        .date-wrapper {

            font-size: 14px;
         }

        .date-label {

            color :$labc-green;
        }

        &:hover {
            .resource-title,
            .resource-text, 
            .date-wrapper {
                background: $iedesign-white - 10;
            }            
        }
    }

    // Icon to display availability of the current resource to the user
    // Icon hidden if resource is for everyone
    // ---------------------------------------------------------------

    .resource-availability{

        position : absolute;
        top: 21px;
        width: 20px;
        height: 20px;
        overflow: hidden;
        right: 21px;
        @extend .ss-lock;
        text-align: center;
        font-size: 13px;
 
        &:before {

            clear: both;
            font-size: 16px;

            @media all and  (min-width: $desk-start) {
                font-size: 36px;
            }

            font-family: "SSStandard";
            display: block;
            margin:  0 auto  16px;
            color : $labc-orange;
            text-align:center;
        }


        &.Non-members {

            display: none;
            visibility: hidden;
        }



        @media all and  (min-width: $desk-start) {

            top: 70px;
            width: auto ;
            height: auto;
            right: 0;
            line-height: 130%;
            overflow: visible;
            width: 25%;
        }

    }


    // List of taxonomies the resource is in
    // ---------------------------------------------------------------

    .resource-category-wrapper {

       padding: 10px 24px;
       float:left;
       color: $iedesign-primary;
    }

    .resource-category-label {

        margin: 0;
        float:left;
    }

    .resource-category {

        margin: 0;
        font-family: 'Roboto Slab', sans-serif;
        font-size : 11.6px ;
        text-transform: none;
        font-weight: normal;
        text-decoration: none;
        color: $iedesign-white;
        //float:right;
        //line-height: 170%;
        padding-left: 111px;

        ul {

            @extend .cf;
            list-style:none;
            padding:0;
            margin:0;
        }

        li {

            float: left;
            margin: 0 6px 6px 0;
            display: block;
            padding: 4px;
            font-size : 11.6px ;
            text-transform: none;
            font-weight: normal;
            text-decoration: none;
            background: $labc-grey - 50;
            color : white;
            font-family: 'Roboto Slab', sans-serif;
            font-weight: normal;
        }
    }




    // Download button for the Resource
    // ---------------------------------------------------------------

    .resource-file {
        display: none;

        border-top: 5px solid  $labc-grey ;
        float: right; 

        @media all and  (min-width: $desk-start) {
            
            border-top: none;
            float: right;
            padding-left: 5px;
        }
        
        .button {
    
            border : 3px solid $iedesign-white; 
            padding: 4px;
            margin-top: 5px;
            text-transform: none;
            font-size: 16px;
            font-family: 'Roboto Slab', sans-serif;
            margin-right: 3px;

            &:before {
                content: ' ';
                padding:  0 3px 0 0;
            }
        }

    }

    .resource:hover .resource-file {
        //display: block;
    }

    .resource .viewmore {
        display: none;

        @media all and  (min-width: $desk-start) {
            position: absolute;
            top: 22%;
            right: 15px;

            display: block;
            @include replace-text-with-dimensions("icons/big-arrow-right.png"); 
        }
    }

    .resource-image {
        position: absolute;
        top: 20px;
        left: 20px;
        border: 2px solid $iedesign-primary;
        padding: 0;
        width: 102px;
        height: 102px;
    }


    .path-resources #page-container .main-content > .content {
        position: relative;
    }

    #lib-filter {
        clear: both;
        margin-bottom: 10px;

        position: absolute;
        display: none;
        @media all and  (min-width: $desk-start) {
            display: block;
            top: 130px;
        }
    }

.view-resource-download-link {
    .views-field-commerce-price {
        float: right;
        font-style: italic;
        margin-top: -31px;
        color: #eee;

        .field-content {
            display: inline;
        }
    }
}
