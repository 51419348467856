 

%basic-button {
    
    display: inline-block;
    border: 4px solid $button-border;
    text-align:center;
    font-weight: 400;
    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
    padding : 10px 14px;   
    text-decoration: none;
    font-weight: normal;
    color: $button-colour;
    background-color: $button-background-colour;
    line-height: normal;
    
    &:hover {

        text-decoration: none;
         background-color: $button-background-colour-hover;
    }

}   


.button {
    @extend  %basic-button ;
}

 


/// Call to action Buttons 
// ---------------------------------------------

    .calltoaction-button,
    input.calltoaction-button,
    input.gform_button,
    .resume_contact_button {

        padding-left: 20px;
        padding-right: 50px;

        width: auto;
        clear: both;

        color:#ffffff;

        display: inline-block;
        position: relative;
        //@extend .ss-navigateright;

        &.accent {
          margin: 12px 0;
          color: white;
          background-color: $labc-green;
          border: 2px solid $labc-green - 40;
        }

        text-transform: none;
        font-size: 15px;
        background: $iedesign-secondary url("../images/icons/arrow-right.png") no-repeat 95% 48%;
        //padding-right: 40px;
        
        &:hover {
            background: $button-background-colour-hover url("../images/icons/arrow-right.png") no-repeat 95% 48%;
        }

        @media screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
    }



    // Header Calltoaction button 
    // ---------------------------------------------

    .site-header {

        .calltoaction-button {

            border: none;
            position: absolute;
            top: - $mobile-sitewrapper-padding;
            right: 0;
            background-color: $header-button;
            color: white;
            padding-top: 16px;
            padding-bottom: 16px;
            z-index: 10;
            width: 100%;
            text-align: right;
            
            transition: background-color  0.24s cubic-bezier(0.550, 0.085, 0.680, 0.530);
            
 

            &:before {

                top: 17px; 
            }

            &:hover{ 

                background-color: $header-button-hover;
            }

            @media all and  (min-width: $desk-start) {

                width: 240px;
                top: 0;

            }



        }

    }

 

    .action-button { 
        padding: 10px 45px 10px 25px; 
        display: block;
        font-size: 16px;
        font-weight: 400;
        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
        position: relative;

        //for action button main CTA color
        border: 4px solid white;
        background: $iedesign-secondary;
        color: $iedesign-white;

        &:hover {
            //color: $iedesign-dark-green;
            // border-color: $iedesign-primary3;
            border-color: #fff;
        }

        &:after {
            content: "";
            display: block;
            background:  url('#{$layout_image_path}/icons/arrow-right.png') no-repeat 0 0;
            width: 30px;
            height: 17px;
            position: absolute;
            right: 8px; 
            top: 50%;
            transform: translateY(-50%); 
        }

        //for action button with texture
        &.old,
        &.grey {
            border: 4px solid white;
            background: url(#{$layout_image_path}/middleblock.jpg) repeat;
            color: $iedesign-primary3;

            &:hover {
                color: $iedesign-dark-green;
                border-color: $iedesign-primary3;
            }

            &:after {
                background:  url('#{$layout_image_path}/icons/arrow-right-dark.png') no-repeat 0 0;
                right: 8px; 
                top: 50%;
                width: 24px;
                height: 14px;
            }            
        }        
    }

     
 


    // Content Calltoaction button 
    // Added via CMS
    // ---------------------------------------------

    .content .calltoaction-button {
        
        margin: 14px 0;

        @media all and  (min-width: $lap-start) {
            
            margin:  10px;
        }

        @media all and  (min-width: $desk-start) {

            margin:  0 20px;
        }
    }

 




 

// Edit Button button 
// ---------------------------------------------

    .edit-button {
        
        font-size: 12px;
        font-family: sans-serif;
        font-weight:normal;
        padding: 4px 8px;

        display: inline-block;
        width: auto;

        position: absolute;
        top: 0;
        right: 0;

        &:hover {

            color: white;
        }
    }


 

// Flags Module button 
// ---------------------------------------------



.flag-outer {
    
    @extend .cf;
    padding-bottom: 24px;

   .flag {

        float:left;
        position :relative;
        @extend %basic-button;
        padding-right: 47px;

        color: white;
        background-color: $labc-green;


    }

    .flag-throbber {

        display: none;
    }

    .unflag-disabled {

        cursor: auto;
        background-color: $labc-grey - 40;
        &:hover {
           background-color: $labc-grey - 40;

        }
    }

    .flag-action {

        @extend .ss-check;

        &:before {

            font-size: 18px;
            font-family: "SSStandard";
            position: absolute;
            top: 10px; 
            display: block;
            right: 14px;   
            z-index: 1;
 

        }
    }
 

    

}   


#main-content .content .action-button {
    display: inline;
}
