

html {

    min-height: 0;
    height:100%;
    background: $labc-black;
    text-rendering: optimizeLegibility;
}

body {
    
    color: $text-color;   

    background: $body-background-colour;

    position: relative;

    min-height: 100%;

    font-family: 'Roboto Slab', serif!important;

    @media all and  (min-width: $desk-start) {

        background : $body-background-colour-desk;

    }
}

body.cke_editable {
    background: white;
    color: $text-color;
}

ul li {
    list-style: none;
}

a {

    color: $labc-orange;
    font-weight: 400;
}


.element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
}


// CMS styles
// Styles which user can add via wyswig
//---------------------------------------------------------------

.image {
    
    img {
        display: block;
    }
}

 @media all and  (min-width: $lap-start) {


    .image-left {

        float: left;
        margin: 2%  2% 2% 0;
    }

    .image-right {
    
        float: right;
        margin: 2%  0  2% 2% ;
    }

    .image-central {
 
        display: block;
        clear: both;
        margin: 2%  auto ;
    }

    .content {

        .image-left,
        .image-right,
        .image-central {
              max-width: 50%;
        }
    }

}


@media all and  (max-width: $lap-start) {

    .image-central ,
    .image-right ,
    .image-left {

        display: block;
        clear: both;
        margin: 6%  0 ;

    }
}




// Logo
// ---------------------------------------------------
//  <a href="/" title="LABC" rel="home" id="site-logo" class="logo-container ir one-twelfth">LABC <i class="icon logo"></i></a>

    .logo-container  {
        
        width: 175px;  
        height: 40px;  
              
        display: block;
        margin: 0;

        @media all and (min-width:  $desk-start) {

            margin-top:11px;
            width: 260px;
            height: 72px;
 
            .logo {
                //background-size:100%;
            }

        }


    
    }

    .logo {

        background-repeat: no-repeat;
    
    
        @media all and  (max-width: $desk-start) {
            background-size:100%;
            

        }

        @media all and  (min-width: $desk-start) {
            max-width:296px;
            background-size:100%;
            background-image: url('../images/logo.png');
        }

    }


 



// Tables
// ---------------------------------------------------


table {
 
    border-color :   $table-border-color;

    tbody{
        border : none;
    }

    th {

        border: none;
        background-color:  $table-header-background-color;
        border-bottom: 2px solid  $table-header-border-color;
    }

    td {

       border: none; 
    }


    tr {

        border: none;
        border-bottom: 1px solid  $table-cell-border-color;

        &:last-child {

            border: none;
        }

    }

}


// Normalise Views 
//---------------------------------------------------------------  


    // Exposed wiget block

    .views-exposed-form {

        .views-exposed-widget {

            padding:0;
            float:none;

            .form-submit {
                margin: 0;
            }
        }

    } 

// http://cssarrowplease.com/
.arrow_box {
    position: relative;
    background: $iedesign-primary3;

    &:after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(20, 94, 87, 0);
        border-bottom-color: $iedesign-primary3;
        border-width: 10px;
        margin-left: -10px;        
    }
}

blockquote {
    @extend .arrow_box;

    padding: 25px;
    //font-style: italic;
    color: $iedesign-white;
    box-shadow: 2px 2px 5px rgba(10,10,10,0.3);
    //@include background-image(
        //linear-gradient(to right, $iedesign-primary3, $iedesign-primary3 40%, $iedesign-primary2 90%, $iedesign-primary2),
        //url(../images/middleblock.jpg) repeat
    //);

    // http://stackoverflow.com/questions/2504071/is-it-possible-to-combine-a-background-image-and-css3-gradients
    background: $iedesign-primary3 - 30;
    background-image: url(../images/middleblock.png); /* fallback */
    background-image: url(../images/middleblock.png), -webkit-gradient(linear, left top, right top, from($iedesign-primary3 - 30), to($iedesign-primary2 - 30)); /* Saf4+, Chrome */
    background-image: url(../images/middleblock.png), -webkit-linear-gradient(left, $iedesign-primary3 - 30, $iedesign-primary2 - 30); /* Chrome 10+, Saf5.1+ */
    background-image: url(../images/middleblock.png),    -moz-linear-gradient(left, $iedesign-primary3 - 30, $iedesign-primary2 - 30); /* FF3.6+ */
    background-image: url(../images/middleblock.png),     -ms-linear-gradient(left, $iedesign-primary3 - 30, $iedesign-primary2 - 30); /* IE10 */
    background-image: url(../images/middleblock.png),      -o-linear-gradient(left, $iedesign-primary3 - 30, $iedesign-primary2 - 30); /* Opera 11.10+ */
    background-image: url(../images/middleblock.png),         linear-gradient(to right, $iedesign-primary3 - 30, $iedesign-primary2 - 30); /* W3C */

    h3 {
        margin-top: 10px;
        color: $iedesign-secondary; 
        margin-bottom: 0;
    }

    &:after {
        border-bottom-color: rgba(0, 84, 84, 0.86);
    }
}

.trainingpage blockquote {
    margin-top: 30px;
    padding: 15px;
    box-shadow: none;
    background: $iedesign-primary2;
    &:after {
        display: none;
    }

    @media all and  (min-width: $desk-start) {
        //padding: 0 24px 20px;    
    }
    
}


#autocomplete {
    width: auto !important;
    li {
        margin: 0 !important;
    }
}


/* RESPONSIVE */

@media (max-width: 767px){
    #access-container{
        padding: 0!important;
    }
    .access div{
        margin: 10px 0;
    }
    
    #my_profile{
        margin-bottom: 50px;
    }
    
    
    #pay-once-resources{
        margin-bottom: 30px;
        padding-right: 0px!important;
    }
    
    #pay-once-resources > div{
        margin-right: 0px!important;
    }
    
    #reg-form input[type="text"], #reg-form input[type="email"], #reg-form input[type="password"]{
        margin-bottom: 20px;
    }
    
    /*.row-eq-height > div{
        margin-bottom: 20px!important;
    }*/
    
    
}

@media (min-width: 768px){
    
    
    #access-container div > div{
        margin-top: 8px;
        margin-bottom: 8px;
    }
    #access-container div > div:nth-child(even) div.row {
        /*margin-right: 0px!important;
        margin-left: 0px!important;*/
    } 
    
    
    #saved_ads, #saved_resources{
        padding-left: 40px;
    }
    
    #saved_ads h3, #saved_resources h3{
        margin-top: 0px;
    }
    
    /* Resources */
    
    .resource-item{
        padding: 0px;
        position: relative;
    }
    
    .resource-item .viewmore {
        margin-top: 20px;
        display: block;
        text-indent: -119988px;
        overflow: hidden;
        text-align: left;
        text-transform: capitalize;
        background-image: url(../images/icons/big-arrow-right.png?1439372540);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 31px;
        height: 64px;
    }
    
    
    .resource-item .btn-law{
        width: 30%;
    }

}

@media (min-width: 991px){
        
    
    /*.match-height.pink .cell{
        height: 100%;
        width: 100%;
        background: #e54a91;
    }*/
}

/*@media (min-width: 1024px)*/
@media screen and (max-width: 1023px) {
    .logo {
        background-image: url('../images/cf-sl-logo.png')
    }

    /*Menu*/
    .navigation-container{
        
    }
    .dropdown-menu{
        background-color: transparent!important;
        border: 0px!important;
        box-shadow: none!important;
        position: relative!important;
        padding-bottom: 20px;
    }
    
    .dropdown-menu li{
        padding: 8px 5px!important;
    }
    
    .dropdown-menu li.active a{
        background-color: transparent!important;
    }
    
    .dropdown-menu li a{
        word-wrap: break-word!important;
        white-space: normal!important;
    }
    
    .nav.menu li a:focus{
        background-color: transparent!important;
    }
    
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
        background-color: transparent!important;
        border-color: transparent!important;
    }
    
    div#secondary{
        /*display: none;*/
    }
    
    .main-content-inner{
        width: 100%!important;
    }
}

.match-height.pink > div {
    background: #e54a91;

    @media screen and (max-width: 767px) {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    @media screen and (max-width: 992px) {
        margin-bottom: 20px;
    }
}


/*@media (min-width: 1024px)*/
@media screen and (min-width: 1024px) {
    /*#menu-mobile{
        display: none;
    }*/
    .header-navigation{
        width: 970px;
    }
    
    .header-content {
        padding-top: 0px!important;
        padding-bottom: 0px!important;
    }
    
    .logo-container {
        /*width: 300px;*/
        margin-top: 20px;
        width: 30%;
    }
    
    .smartlaw-slogan{
        float: left;
        margin-top: 5px;
        padding: 15px 0px 15px 0;
        width: 42%;
        border: 0px solid;
        //background: url('../images/sm-logo.png') no-repeat;
        background-position: right 200px top 5px;
        background-size: 100px;
    }


    /*.smartlaw-slogan .sl-logo__image{
        max-width:142px;
        display:block;
        margin:0 auto;
    }*/
    
    .header-content .sitename {
        /*width: 100%;*/
        border: 0px solid;
    }
    
    .header-content .slogan {
        padding-left: 0px!important;
        width: 100%!important;
        /*width: 40%;
        padding: 0;
        float: left;
        border: 1px solid;*/
    }
    
    .row-eq-height {
        display: table;
    }
    
    .row-eq-height [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
    
    .row-eq-height > div > div{
        position: static;
    }
    
    .row-eq-height > div:nth-child(1), .row-eq-height > div:nth-child(2){
        border-right: 5px solid white;
    } 
    
    .match-height > div {
        margin-bottom: 12px;
    }

    .match-height > div:nth-child(3n-2){
        border-right: 8px solid white;
    }
    
    .match-height > div:nth-child(3n-1){
        border-left: 4px solid white;
        border-right: 4px solid white;
    }
    
    .match-height > div:nth-child(3n){
        border-left: 8px solid white;
    }
    
    

    .navigation-container .header-navigation li{
        width: 176px;
    }
    
    .navigation-container .header-navigation .account-management {
       // top: -118px !important;
    }
    
    .navigation-container .header-navigation .account-management{
        width: auto!important;
    }
    
    a.header-cart{
        background-image: url(../images/icons/cart.png)!important
    }
    .navigation-container .header-navigation a.header-cart{
        text-indent: -10000px;
    }
    
    .dropdown-menu, .dropdown-toggle .caret{
        display: none!important;
    }
    
    .site-navigation li.active a {
        color: #e54a91;
    }
    
    .site-navigation li.active a:after {
        background-color: #e54a91;
        content: "";
        height: 6px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -6px;
    }
    
    .page .sidebar-second{
        width: 100%;
        padding-left: 0;
    }
    
    .home .main-content .home-intro p {
        font-size: 17px;
    }
    .home .content-bottom .infographs {
        width: 76%!important;
    }
    
    .home .testimonials-homepage{
        width: 33.33%;
        margin-left: 0;
    }
    
    
    /*.infographics:nth-child(odd) {
        padding-left: 0px;
    }
    
    .infographics:nth-child(even) {
        padding-right: 0px;
    }*/
    
    
    
    .logged-in .navigation-container .header-navigation .nav {
        
    }
    
    /*.navigation-container .site-navigation li {
        width: 18%;
    }
    
    .navigation-container .site-navigation li:nth-child(4) {
        width: 16%;
    }
    
    .navigation-container .site-navigation li:first-child, .navigation-container .site-navigation li:last-child {
        width: 13%;
    }*/
    
    .navigation-container .header-navigation li.last {
        margin-top: -90px;
    }
    
    .navigation-container .header-navigation a.header-cart {
        top: 12px;
    }
    
    .logged-in .navigation-container .header-navigation a.header-cart {
        top: -12px;
    }
    
    .logged-in .navigation-container .site-navigation #menu-member li {
        width: 25%!important;
    }
    
    /*.menu-item a:before {
        width: 32px;
        height: 32px;
        content: ' ';
        display: block;
        position: absolute;
        left: 17%;
        top: 11px;
    }
    
    .menu-item:nth-child(1) a:before{
        background: transparent url(../images/icons/homepage.png) no-repeat;
        background-size: 32px 32px;
        left: 5%;
    }
    
    .menu-item:nth-child(2) a:before, #menu-member .menu-item:nth-child(2) a:before, #menu-main .menu-item:nth-child(6) a:before{
        background: transparent url(../images/icons/about.png) no-repeat;
        background-size: 32px 32px;
        left: 3%;
    }
    
    
    .menu-item:nth-child(4) a:before, #menu-member .menu-item:nth-child(4) a:before, #menu-member .menu-item:nth-child(4) a:before,
    .menu-item:nth-child(5) a:before, #menu-member .menu-item:nth-child(5) a:before, #menu-member .menu-item:nth-child(5) a:before{
        background: transparent url(../images/icons/resources.png) no-repeat;
        background-size: 32px 32px;
        left: 8%;
    }
    
    #menu-main .menu-item:nth-child(2) a:before{
        left: 0!important;
    }
    
    #menu-main .menu-item:nth-child(3) a:before{
        left: 0!important;
    }
    
    #menu-main .menu-item:nth-child(4) a:before{
        left: 1%!important;
    }
    
    #menu-member .menu-item:nth-child(4) a:before{
        left: 10%!important;
    }
    
    .menu-item:nth-child(5) a:before{
        left: -3%!important;
    }
    
    #menu-member .menu-item:nth-child(3) a:before, .menu-item:nth-child(3) a:before{
        background: transparent url(../images/icons/opportunities.png) no-repeat;
        background-size: 32px 32px;
        left: -3%;
    }
    
    
    
    .menu-item:nth-child(1) a:hover:before, .menu-item:nth-child(1).active a:before{
        background: transparent url(../images/icons/homepage_pink.png) no-repeat;
        background-size: 32px 32px;
        
    }
    
    .menu-item:nth-child(2) a:hover:before, .menu-item:nth-child(2).active a:before,
    .menu-item:nth-child(6) a:hover:before, .menu-item:nth-child(6).active a:before
    {
        background: transparent url(../images/icons/about_pink.png) no-repeat;
        background-size: 32px 32px;
        
    }
    
    .menu-item:nth-child(4) a:hover:before, .menu-item:nth-child(4).active a:before,
    .menu-item:nth-child(5) a:hover:before, .menu-item:nth-child(5).active a:before
    {
        background: transparent url(../images/icons/resources_pink.png) no-repeat;
        background-size: 32px 32px;
    }
    
    
    .menu-item:nth-child(3) a:hover:before, .menu-item:nth-child(3).active a:before{
        background: transparent url(../images/icons/opportunities_pink.png) no-repeat;
        background-size: 32px 32px;
        
    }*/
    
    #join-mt > div{
        margin-right: 10px;
        margin-left: 10px;
    }
    
    #left-nav-menu h2{
        padding: 10px 15px;
        background: #009090;
        color: #fff;
        font-size: 19px;
        margin-bottom: 0;
    }
    
    #left-nav-menu ul{
        margin: 0;
    }
    
    #left-nav-menu ul ul{
        padding: 0 15px;
    }
    
    
    #left-nav-menu ul li a {
        padding: 15px;
        text-transform: none;
        color: #464646;
        background: #fff;
        display: block;
        font-size: 14px;
        border-bottom: none;
        margin-bottom: 0px;
        border-bottom: 1px dotted #969696;
    }
    
    #left-nav-menu ul li li a {
        padding: 15px 10px;
    }
    
    #left-nav-menu ul li a:hover{
        text-decoration: none;
    }
    
    .news li.odd{
        display: block!important;
        clear: both!important;
    }
}

@media (min-width: 1200px){
    .container, .header-navigation {
        width: 1200px!important;
    }
}

#main-content div.breadcrumb {
    background-color: white;
    padding-left: 5px;
}
