// Login form 

.user-login {

    // Wrappers for login inputs, addiotnal to the usual drupal fields
    
    .login-form {
    
        padding-bottom: 20px;
    }


    .login-element {

        float:left;
    }



    // Action wrapper and button
    .form-actions, 
    .button {

        margin-bottom: 12px;
        width: 100%;        
        float: left;


        @media all and (min-width: $lap-start) {

            width: auto;
        }

    }


    .form-actions  {

        clear:none;    
        margin-right : 20px;
    }

    // Submit Button

    .form-submit {


        width: 100%;     
        text-align:center;
    }

    // Password field

    .form-type-password {

        @media all and (min-width: $lap-start) {

            margin-left: 20px;
        }

    }

    hr {

        border: 1px solid  $labc-black;
        padding: 0.5px;   
        background: $labc-grey;
    }


    // 

    .login-form-details {

        padding-bottom: 30px;  

        ol {
            margin-left: 25px;
        }
    }


}