

// Hight Level Comment Area
#comments {

    float: left;
    border-top : 1px solid $labc-grey - 40;
    position:relative;
 
    padding: 30px 24px;
    margin: 12px -24px ;
    background-color: $iedesign-white - 3;


    h2 {
        font-size: 26px;
        font-weight: normal;
       

    }

    h3 {

        font-weight: bold;
        width: auto;
        display: inline-block;
        text-transform: uppercase;
        border-bottom: 3px  solid $labc-yellow;
    }


}

// Comment Form
.comment-form {

    padding-top : 22px;

    div {
        position: relative;
        margin-bottom: 2rem;
   
    }
}




// A comment 

.comment  {

    position:relative;
    background: $labc-grey;
    margin-bottom: 5%;
    padding: 5% 5% 40px;

    @media all and  (min-width: $lap-start) {
        
        padding: 2% 2% 40px;

    }

    h3 {
        font-size: 1.2em; 
        margin-bottom: 0;

    }

    mark{
        font-size: 0.8em;
        background:$labc-yellow;
        color: $labc-black;

    }

    footer {
        
        font-size: 0.9em;  
        
        margin-bottom: 12px;
        padding-bottom:12px;
        border-bottom: 1px solid  rgb(193, 190, 190);
        
        color: rgb(133, 130, 130);

        figure {

            color: rgb(133, 130, 130);
            margin-bottom: 0;
        }
        
        .permalink {

            font-weight: normal;
            font-size: 0.76em;
            color : #9B9B96;
            //text-transform: uppercase;
            position:absolute;
            bottom: 12px;
            left:5%;

            @media all and  (min-width: $lap-start) {
                
                left:2%;

            }
        }
    }

 
    .field-type-file {
        padding-top: 24px;
    }
 
}