/* HOME */
a.calltoaction-button:active, 
a.calltoaction-button:hover, 
a.calltoaction-button:visited{
	color: #fff;
}  

.btn-default.calltoaction-button {
	border: 4px solid #fff;
    padding-left: 20px !important;
    padding-right: 50px !important;
    width: auto;
    clear: both;
    display: inline-block;
    position: relative;
    text-transform: none;
    font-size: 15px;
    background: #e54a91 url(../images/icons/arrow-right.png) no-repeat 95% 48%!important;
}

.home{
    
    .main-content .home-intro p {
        font-size: 17px;
    }
    
    .content-bottom .infographs {
        width: 76%!important;
    }
    
    .testimonials-homepage{
        @media all and  (min-width: $desk-start) {
            width: 33.33%;
        }

        @media all and  (max-width: $desk-start - 1 ) {
            margin-top: 20px;
        }
        margin-left: 0;

        .title {
            padding: 10px;
        }
    }
}