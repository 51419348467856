.woocommerce-page {
	.woocommerce-info,
	.woocommerce-error,
	.woocommerce-message {
		
		border-top-color: $iedesign-secondary;

		&:before,
		a {
			color: $iedesign-secondary;
		}
	}

	.woocommerce-tabs.wc-tabs-wrapper { 
		display: none;  /* hide redundant description block on single product */
	}

	img.partners {
		width: 80px;
	}

	.main-content {

		#respond input#submit,
		a.button,
		button.button {
		//	display: none;
		}


		 .woocommerce-breadcrumb {
			padding-left: 15px;
		}
	}

	.woocommerce {
		input.button,
		.checkout_coupon input.button,
		.woocommerce-Button {
			border: 3px solid #fff;
		    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
		    position: relative;
		    z-index: 20;
			border-radius: 0;
		    padding: 10px 15px;
		    width: auto;
		    font-size: 16px;
		    text-transform: none;
		    font-weight: normal;
		    font-family: 'Roboto Slab';
		    color: #fff !important;
		    text-transform: uppercase;
		    background-color: $iedesign-secondary;
		    outline: 0;

		    &:hover {
		    	background-color: #dad8da;
		    }
		}

		form { 
			.form-row {
				&.woocommerce-invalid {
					label {
						color: $iedesign-secondary;
					}

					.select2-container, 
					input.input-text,
					select {
						border-color: $iedesign-secondary;
					}
				}

				&.woocommerce-validated {
					.select2-container, 
					input.input-text,
					select {
						border-color: #bebebe;
					}
				}
			}
		}

		
		.woocommerce-MyAccount-navigation {
			@media (min-width: 992px) {
			    width: 25%;
				position: relative;
			    min-height: 1px;
			    padding-right: 15px;
			    padding-left: 0;
			}
			
			ul {
				margin-left: 0;

				li {

					 a {
						color: #464646;
						display: block;
					    padding: 15px 10px;
					    margin-bottom: 0;
					    border-bottom: 1px dotted #969696;

					    &:hover,
					    &:focus,
					    &:active {
					    	text-decoration: none;
					    	background-color: #e1e1e1;
					    }
					}

					&.is-active {
						a {
							text-decoration: none;
					    	background-color: #e1e1e1;
						}
					}
				}
			}
		}
	}	

	fieldset {
		legend {
			color: $heading-color;
    		font-weight: 400;
    		margin: 10px 0 8px; 
		}
	}
}


 .woocommerce .related.products ul.products,
 .woocommerce-page .related.products ul.products{
	margin-left:-15px;
	margin-right:-15px;
	width:auto;

}  

 .woocommerce .related.products ul.products li.product, 
 .woocommerce-page .related.products  ul.products li.product {
	margin:0;
	width:50%;
	padding:15px;

	&:nth-of-type(odd){
		clear:left;
	}
}







body.woocommerce form .form-row label.checkbox, 
body.woocommerce-page form .form-row label.checkbox{
	display:block;
	position:relative;
	padding-left:20px
}

body.woocommerce form .form-row .input-checkbox{
	position:absolute;
	left:0px;
	top:10px;
}