 // User registration forms
// 

.page-user-register,
.page-member-register {

  .content {

    position: relative;
  }

}

#user-register-form {

  overflow: hidden;

  .form-actions {
    
    float: right;
    margin-right: 32px;

  }


  // Login Button
  .login {
    
    float: right;

    margin-right: 12px;
  }


}


// Author Block -- temp

.author-details-block {
    
    background: $labc-grey;

    .author {

        font-size: 0.9em;
        margin: 0 12px 0 0; padding: 12px;
        background:rgb(240,240,240);
    }

    .user-name-title {

        margin-bottom:  0;
    }

}

.avatar {

    float:left;
    margin-right : 10px;
}
 
  .user-buttons {
    margin: 12px 0
  }

 @media all and (min-width: $desk-start) {


    .user-buttons {

      margin: -60px 0 0;
      float: right;

      .button {

        float: right;
      }
    }

    .edit-your-profile {

      margin-right: 4px;
    }

    .logout.button {

      margin-bottom: 0;
    }

  }

.user-edit-form { 

    padding: 24px;
    margin: 24px 0 0;
    background: url(#{$layout_image_path}/middleblock.jpg) repeat;
}