
.slider {

 //   height: 320px;
    position:relative;

    overflow: hidden;
    @media all and  (min-width: $lap-start) {

   //     height: 340px;
    }
 
}


.slider-wrapper {

    position: relative; 
    overflow: hidden; 
    &.unslider-active {
        overflow: auto; 

    }
 
    .slide {   
  
        margin:0;
        float: left;
        height: 320px;
        position: relative;
        width: 100%;
        background: $labc-grey;

        @media all and  (min-width: $lap-start) {

            height: 340px;
        }
    }

}




.slider-block {

    @extend .cf;
    clear: both;
    margin:0;
    overflow: hidden;
}


  
.slider-content {

    z-index: 10;
    padding: 45% 2% 0;
    background: $labc-grey;
    height: 100%;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
     

    @media all and  (min-width: $lap-start) {
        
        background-size: cover;
        color :white;
        position: relative;
        padding : 60px 12% 0;

    }

    @media all and  (min-width: $desk-start) {
        background-size: 100%;
    }
    
    .slide-button {

        position: absolute;
        top: 14%;
        left:50%;
        margin-left:-92.5px;

        @media all and  (min-width: $lap-start) {

            left:auto;
            margin-left:auto;
            position: static;
        }
    }


}

    
 
.unslider-active {

  .slide { 

      min-height: 50px;
 
    }

}

.slider  {


     .slide {

        img {

            display: block;
            width: 100%;
            height: auto;

            @media all and  (min-width: $lap-start) {

                position:absolute; 
                z-index: 1;
            }
        }

    }
}



    .slider-title {

        text-transform: none;
        width: 100%;
        text-align:center;
        min-height: 0;
        font-size: 24px;
        margin-bottom:0;
        padding : 0;

        @media all and  (min-width: $lap-start) {
            color :white;

        }

        @media all and  (min-width: 700px) {
        
            color :white;   
            font-size: 47px;
        }

       

    }

    .slider-text {
  
        text-align:center;
        width: 100%;
        font-size: 18px;
        color: $labc-green; 
        letter-spacing: -0.1px;

        @media all and  (min-width: $lap-start) {

            color :white;

        }

        @media all and  (min-width: 700px) {

            font-size: 24px;
            color :white;
        }

    }



.dots {

    @extend .cf;
    margin:0;
    padding: 0;
    position: absolute;
    bottom: 0;
    list-style: none;
    color: white;
    z-index: 22;
    font-size: 14px;

    .dot {

        &.active {
            background: $labc-orange;
        }
        font-weight: bold;
        list-style: none;
        margin-right: 4px;
        
        background:darken($labc-green, 20%);
        background: rgba( darken($labc-green, 20%), 0.6);
        padding: 6px 14px;
        text-align: center;
        float: left;
        cursor: pointer;

        transition: background 0.3s;

        &:hover {
            
            background:$labc-green;
            background: rgba( $labc-green, 0.6);

        }
    }

}



.slider-navigate {

    padding:12px;
    display: block;
    color: $labc-green;
    cursor: pointer;
    position:absolute;
    
    top:14%;

    z-index: 9999;

    @media all and  (min-width: $lap-start) {
        top:44%;
    }


    &.prev {
        left: 0;
        @extend .ss-navigateleft;
    }


    &.next {
        right: 0;
        @extend .ss-navigateright;  
    }


    &:before {

        font-size: 18px;
        font-family: "SSStandard";
        display: block;

    }

}