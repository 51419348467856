body{
	font-family: 'Roboto Slab', serif!important;
	font-weight: 300;
	background: #464646 !important;
	
	&.page{
		color: #555;
		font-size: 15px;
	}
}

body.front .site-wrapper #page-container #main-content{
	width: 100%;
}

noscript {
	p {
		color: #fff;
		background-color: #009090 !important;
		padding: 15px;
	}
}

.nav.menu li a:hover{
	background: none
}

body.page .entry-content {
    margin-top: 0;
}

body.page-template-default .entry-content {
    margin-top: 0;

    /* missing content bullets */
    ul {
        li {
            list-style-type: disc;
            list-style-position: initial;
        }
    }
}

.widget ul{
	margin-left: 0;
}

.border{
	border: 1px solid #00c2c2;
}

.border2{
	border: 2px solid #00c2c2;
}

.pink-bg{
	background: #e54a91;
}

.blue-bg{
	background: #00c2c2;
}

.bluegreen-bg{
	background: #005e5e;
}

.pink-bg, .blue-bg, .bluegreen-bg{
	color: #FFF;
}

.pink-bg a, .blue-bg a{
	color: #FFF!important;
}

.col-margin-right{
	margin-right: 10px;
	padding-left: 15px;
	padding-right: 15px;
}

.pink-bg .cell{
	padding: 20px 10px 10px 10px;
}

.pink-bg .cell h2{
	color: #FFF!important;
	margin-top: 0px;
}

@media (max-width: 1024px){
	
	#secondary{
		width: 100%;
		order: 1;
	}
	
	.main-content-inner{
		order: 2;
	}

}


.resources{
	margin-bottom: 10px;
}

#resources .desc{
	font-weight: bold;
}

input[type="submit"]{
	background-color: #009090;
}

input[type="submit"]:disabled {
	background-color: #009090;
}

input[type="submit"]:hover{
	background-color: #e54a91;
	color: #FFF;
}

#skills-location, #mock-trials{
	margin-top: 20px;
}


.leaf a:active{
	background-color: none !important;
}

.nav.menu a:focus{
	background-color: transparent!important;
	
}

/* INNER */
h1.page-title {
    font-size: 26px;
    margin-top: 0;
}

nav h2.title{
	margin-top: 0;
}


.post-inner-content {
    border: 0px;
}

.post-inner-content:first-child {
    border-top: 0px;
}

.shs-term-selected{
	display: block;
}

.main-content blockquote{
	padding: 25px!important;
	font-size: 15px!important;
}

.main-content .entry-content h2 {
    color: #005e5e;
    font-weight: 600;
    font-size: 19px;
}

.main-content .entry-content h3 {
    color: #009090;
    font-weight: 500;
    font-size: 17px;
}

.fullmode_summary p{
	font-size: 17px;
}

.contact-container{
	border-top: 2px solid #c8c8c8;
    padding: 24px;
    margin: 24px 0 0;
    background: url(../images/middleblock.jpg) repeat;
	word-wrap: break-word;

	.gform_wrapper {
		margin: 0;
	}

	.gform_wrapper .gform_heading,
	.gform_title {
		display: none;
	}
}

/* contact page styling */
.contact-container input[type="text"], .contact-container input[type="email"], .contact-container textarea {
    background: #fff;
    -moz-box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    -webkit-box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    border: 1px solid #bebebe;
    -webkit-appearance: none;
    
    width: 100%;
    padding: 12px 16px;
    display: block;
    color: #555;
    font-size: 14px;
    border: none;
    border-radius: 1px;
    font-family: 'Roboto Slab';
    outline: 1px solid rgba(153,153,153,0);
}

.contact-container textarea{
	height: 120px;
}

.contact-container select{
	box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    border: none;
    width: 100%;
    height: 42px;
    background: #fff;
}

input.gform_button{
	background: #009090 url(../images/icons/arrow-right-dark.png) no-repeat 93% 15px;
    border: 3px solid #fff;
    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
    position: relative;
    z-index: 20;
    display: block;
    border-radius: 0;
    padding: 10px 45px 10px 15px;
    width: auto;
    font-size: 16px;
    text-transform: none;
    font-weight: normal;
    font-family: 'Roboto Slab';
    
    text-transform: uppercase;
}

input.gform_button:hover{
	border-color: #174c4c; 
}


.btn-law, a.btn-law, .btn.btn-law{
	background: url(../images/icons/arrow-right-dark.png) no-repeat 93% 15px,url(../images/middleblock.jpg) repeat!important;
    border: 3px solid #fff;
    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
    position: relative;
    z-index: 20;
    display: block;
    border-radius: 0;
    padding: 10px 45px 10px 15px;
    width: auto;
    font-size: 16px;
    text-transform: none;
    font-weight: normal;
    font-family: 'Roboto Slab';
    color:#333 !important;
    overflow:hidden;
    white-space: initial;
    
}

.btn-law {
	background: url(../images/icons/arrow-right-dark.png) no-repeat 93% 15px,url(../images/middleblock.jpg) repeat!important;
	padding: 10px 45px 10px 25px;
	display: block;
	font-size: 16px;
	font-weight: 400;
	box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
	position: relative;
	border: 4px solid white;
	font-family: 'Roboto Slab';
	color: #174c4c!important;
}


#edit-submit-news{
	float: right;
}

.btn-law:hover{
	text-decoration: none;
}

.btn-pink {
	background-color: #e54a91;
	color: #fff;
}



/*
	edit-profile.php
*/
#edit-profile div.image {
	width: 100%;
	height: 150px;
}
#edit-profile div.image img:nth-child(1) {
	max-height: 100%;
	height: 150px;
	margin-left: auto;
	margin-right: auto;
}

#edit-profile #photo{
	height: 210px;
}

ul.bulleted{
	margin: 15px 30px;
}
ul.bulleted li {
	list-style: disc;
	margin: 10px;
}



/* REGISTRATION */

input[type="tel"], input[type="text"], input[type="email"], input[type="password"], textarea{
	width: 100%;
	
	padding: 12px 16px!important;
	
	background: #fff;
    -moz-box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    -webkit-box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    box-shadow: inset 1px 1px 2px rgba(10,10,10,0.2),inset -1px -1px 2px rgba(10,10,10,0.1);
    border: 1px solid #bebebe;
    -webkit-appearance: none;
    cursor: text;	
}

#membership-opts > div{
	border: none;
}

#pay-on-account {
    color: #FFF;
    background-color: #009090;
    width: 200px;
    height: 42px;
    font-size: 16px;
    border: 3px solid #FFF;
    text-transform: none!important;
}

#paypal-btn {
    box-shadow: none;
    color: #FFF;
    border: 3px solid #FFF;
    height: 42px;
}

/* Resource detail page */
#recommend{
	padding: 2px 20px 10px 20px;
}

#recommend h2{
	font-weight: 300;
}

#get-resource{
	padding: 10px;
}

.resource-detail .post-inner-content{
	padding: 0!important;
}


.btn-law.btn-search{
	background: url(../images/middleblock.jpg) repeat!important;
}

.resource-detail #get-resource .btn-law{
	width: 100%!important;
	margin-bottom: 10px!important;
}

/* SIDEBAR */

#secondary .widget {
  margin-bottom: 20px;

  @media all and  (max-width: $desk-start) {
	margin-right: 15px;
  }
}

#secondary .widget a:hover, #secondary .widget a:focus{
	text-decoration: none;
}
.mc4wp-success, .mc4wp-notice{
	padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.mc4wp-success{
	color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.mc4wp-notice{
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.page .sidebar-first{
	width: 100%;
	padding: 0px;
}

.parent-sidebar-menu li{
	border-bottom: 0px!important;
}
.parent-sidebar-menu > li a h2, 
#secondary .widget > h3,
.widget-title,
.post-filter label,
.child-sidebar-menu h2
{
	color: #FFF!important;
	background: #009090;
	padding: 10px 15px;
    width: 100%;
    margin: 0;
    font-size: 19px;
    text-transform: none;
    font-weight: 400;
}

.widget ul.child-sidebar-menu,
.acatw-term-list  {
	padding: 0;
}

.widget .child-sidebar-menu li a,
.acatw-term-list li a {
	display: block;
	padding: 15px 10px;
	margin-bottom: 0;
	border-bottom: 1px dotted #969696!important;
}

.widget ul li,
.acatw-term-list li {
	margin-bottom: 0;
	padding-bottom: 0;
}

.acatw-term-list li h3,
.acatw-term-list li h3 a {
	margin: 0;
	font-size: 15px;
	color: #464646;
}

.widget ul.child-sidebar-menu li a:hover,
.acatw-term-list li a:hover {
	background: #e1e1e1;
}

.widget .child-sidebar-menu li.current_page_item a,
.acatw-term-list li.current_page_item a,
.widget .child-sidebar-menu li.current-cat a {
	background: #ebebeb;
}

.widget ul.child-sidebar-menu ul, 
.acatw-term-list ul{
	padding: 0px;
}

.widget .child-sidebar-menu li li a{
	padding-left: 25px;
}

.widget .child-sidebar-menu li.current_page_item li a, .widget .child-sidebar-menu li.current_page_item li li a{
	background: none;
}

.widget .child-sidebar-menu li li li a{
	padding-left: 35px;
}

.widget .child-sidebar-menu li li li li a{
	padding-left: 45px;
}



#secondary .widget .child-sidebar-menu a,
.child-sidebar-menu > li a{
	color: #464646;
}


#secondary{
	padding-right: 0px!important;
}

#sidebar h2{
	color: #FFF;
	
}

#left-menu h2{
	padding: 5px 15px;
	line-height: 1.71429;
	font-weight: 300;
}

.library-filter label{
	background: none;
	padding-right: 0px;
}

#filters h2{
	padding: 15px;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.8;
}

.filter-label {
	background-color: #464646;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	padding: 1% 2%;
	font-size: 87.5%;
}

.library-filter table label {
    background: #f0f0f0;
    color: #1d1d1b;
    cursor: pointer;
    padding: 5px 10px;
}

.library-filter td{
	border-bottom: 1px solid #c8c8c8;
}
.library-filter input[type="checkbox"] {
    width: 30px;
    height: 30px;
    margin-top: 3px;
}

#edit-title {
    width: 100%;
    margin: 20px auto;
}

#block-views-exp-news-page{
	padding: 1px 15px 10px 15px;
}

.webform--opportunity #webform-component-step-1--topic .select-wrapper {
    margin-top: 0px;
}

input.input-group-addon {
	background-color: #FFFFFF!important;
}

/**
	Resource page
*/


.resources .post-inner-content, 
.logged-in .post-inner-content {
	padding: 0px;
}

.resources .entry-content {
	margin-top: 0;
}

.resource-item{
	background: #005e5e;

	&:hover .resource-primary-info {
		background-color: #f5f5f5;
	}

	a.resource-title:hover,
	a.resource-title:focus,
	a.resource-title:active { 
		text-decoration: none;
	}

	a.read-more {
		color: #00c2c2;
	}

}
.resources a h2.resource-title{
	color: #005e5e;
    font-weight: 400;
    font-size: 19px;
}

.resources a h2.resource-title:hover{
	text-decoration: underline;
}

.resource-primary-info{
	background: #FFF;
	padding: 20px 5px;
}

.resources span.label{
	font-size: 90%!important;
}

.resources span.label-warning{
	background-color: #009090!important;
}

.resources span.label-info{
	background-color: #e54a91!important;
}

.resource-category-label {
	color: #FFF!important;
}

.resource-item .btn-law{
	background: url(../images/middleblock.jpg) repeat!important;
	margin-bottom: 10px!important;
	width: 100%;
}


/*TABLES*/
.table .header, .table .body.row{
	padding: 5px;
}

.table .body.row{
	border-bottom: 1px dotted #CCC;
}

.pink, .pink h2, .pink h3, .pink a{
	color: #FFF!important;


}




.woocommerce.wcopc-product-single .col2-set .col-1, .woocommerce-page .col2-set .col-1,
.woocommerce.wcopc-product-single .col2-set .col-2, .woocommerce-page .col2-set .col-2  {

	width:100%;
}


.job-manager-application-edit-form .fieldset-topic li label{
	width:100%;
}


.resume-manager-resumes  th,
.resume-manager-resumes  td{  
	padding: .5em 1em .5em 0;
}

.filter-desc{
	padding-left:20px;
	padding-right:20px;
	font-size:14px;
}

body .job-manager-form fieldset small.description{
	line-height:1.4;
}


.form-row.terms.wc-terms-and-conditions{
	position:relative;
}

body.woocommerce form .form-row.terms .input-checkbox{
	top:20px;
}


body .form-row.terms.wc-terms-and-conditions .label.checkbox{
	display:inline;
}


.preheader{
	background-color:#ffffff;
	padding-top:10px;
	display:block;
	//padding-bottom:10px;


	@include respond-to("medium"){
	padding-top:20px;
	padding-bottom:20px;
	position:relative;
	background-image:url(../images/yc-flare.png);
	background-position:top right;
	background-size:100px auto;
	background-repeat:no-repeat;
}




	p{
		font-size:12px;
		line-height:1.3;
		//float:left;
		overflow:auto;
		margin:0;
		@include respond-to("medium"){
			text-align:center;
			font-size:16px;
			line-height:40px;
			position:absolute;
			left:0;
			right:0;
		}
	}
}

.yc-logo{

	max-width:50px;
	margin-right:20px;
	@include respond-to("medium"){
		max-width:100px;
	}
	display:block;
	float:left;

	img{
		max-width:100%;
	}


}