#breadcrumbs {
    padding: 0px 0px 12px;
    margin: 0 0 15px 0;
    border-bottom: 1px dashed #174c4c;
    color: #009090;
    font-size: 12px;

	a {
		font-weight: normal;
    	color: #174c4c;
	}

	@media (min-width: 1024px) {
		padding: 0px 0px 10px;
	}
}