    
 // Pagination 
 // Durpal Generated Markup

.pager {
    background: rgba($iedesign-lightgrey, 0.7);
    padding: 10px;
    //border: 1px solid black;

    margin: 24px 0;
    color : $iedesign-grey;
    font-family: 'Roboto Slab', sans-serif;
    font-size: 16px;
    //border-bottom : 1px solid $labc-grey - 30;
    position: relative;
    clear: both;
    @include clearfix;
    text-align: center;

    li {
        //float: left;
        text-decoration: none;
        margin-right: 2px;

        //border: 1px solid $iedesign-grey;

        a {
            text-decoration: none;
        }

        &.last,
        &.first {
            //display: none;
        }

        display: inline; 
    }

    a, .current {
        font-weight: normal;
        color : $iedesign-grey;
        //text-transform:uppercase;
        //display: block;
        padding: 10px 10px;
        text-decoration: none;

        &:hover {

            text-decoration: none;
            background: $iedesign-lightgrey;

            &:before,
            &:after {
                background: $labc-grey;
            }
         }
    }

    .current {
        color: $iedesign-primary2;
        font-weight: bold;
    }

    .ajax-progress-throbber {
        position: absolute;
        right: 0;
        top: 8px;
    }

    .first a{ 

        //display:none;
    }

    .previous {
  
        //padding: 0  20px 0 0 ;

        a {
            background: transparent url('../images/icons/arrow-left-dark.png') no-repeat 7% 13px; 
            padding-left: 45px;

            &:hover {
                background-color: $iedesign-lightgrey;
            }

            &:before {


            }

        }
        
    }

    .next {

        //padding: 0 0 0 20px;  

        a {
            background: transparent url('../images/icons/arrow-right-dark.png') no-repeat 93% 13px; 
            padding-right: 45px;

            &:hover {
                background-color: $iedesign-lightgrey;
            }
            
            &:before {
                display: none;
            }
            
            &:after {


            }

        }

    }


    li.first,
    li.last {
        a {
            display: none;
        }
    }
}


 