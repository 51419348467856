 .woocommerce-cart,
 .woocommerce-account,
 .woocommerce-wishlist {
 	&.woocommerce-page {
 		.shop_table tr th,
		table.cart th
		.wishlist_table th span,
		table.my_account_orders th {
			background-color: lighten(#464646, 40%);
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			color: #fff;
			padding: 1% 2%;
			font-size: 15px;
		}

		.shop_table {
			td {
				font-size: 15px;
			}
		}

		.cart-collaterals .cart_totals tr th {
			line-height: 34px;
		}

		.button {
		    border: 3px solid #fff;
		    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
		    position: relative;
		    z-index: 20;
		    border-radius: 0;
		    padding: 10px 15px;
		    width: auto;
		    font-size: 16px;
		    text-transform: none;
		    font-weight: normal;
		    font-family: 'Roboto Slab';
		    color: #fff !important;  
		    text-transform: uppercase;
		    background-color:#009090;

		    &:hover{
		    	background-color:lighten(#009090, 10%);
		    }
		}

		a.remove {
			color: $iedesign-secondary !important;
		
			:hover {
				background-color: $iedesign-secondary;
			}
		}

		.woocommerce .wc-proceed-to-checkout {
			a.button {
				color: #fff;
				background-color: $iedesign-secondary;

				&:hover {
					background-color: #dad8da;
				}
			}
		}

		table.cart td.actions .coupon .input-text {
			width: 120px;
			height: 42px;
		}
 	}

 }