

// Icons
// Used in  conjunction ss standard / social fonts and sprite map
//---------------------------------------------------------------


    .icon {

        display: block;
        font-style: normal;
        overflow: hidden;
    }

    



// Image Replace
//---------------------------------------------------------------  


    .ir {

        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        
        .icon {

            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;

        }

    }

// Sprite Map
//---------------------------------------------------------------

    .sprite {

        background-repeat: no-repeat;
    }

// Floats
// --------------------------------------------------------------

.left {

  float: left;
}

.right {

  float: right;
}

// Clear

.clear {

  clear: both;
}
 
 

// Boxes
// ------------------------------------------------------------

.boxed {

  padding: 20px;
  border: 1px solid $labc-grey;

}

// Hide an element
.hidden {
  display:none;
}



// Main Backgroud 

@mixin  opaqueBackground {
  
  background: rgba($iedesign-lightgrey, 0.8);

  .no-rgba & {

    background: url(#{$layout_image_path}/fall-back-white.png)
  }
  
}




 

 /// Arrow generator
@mixin block-arrow ( $direction : down, $color : white, $width : 7px, $height : 14px) {
  
  width: 0; 
  height: 0; 
 
  
  // Right
  @if $direction == right {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-left: $width solid $color;
  }

  // Left
  @if $direction == left {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-right: $width solid $color;
  }

  // Up
  @if $direction == up {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $height solid $color;
  }

  // Down
  @if $direction == down {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $height solid $color;
  }


}





