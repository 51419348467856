


.map {


}


.gmap-popup {

    p { 
        margin: 0;
    }
}

.gmnoprint,
.gm-style-cc {
	display: none;
}