

.twitter-block  {
    
    margin-top: 70px;
    padding-top: 30px;
    position: relative;
    border-top : 1px solid $labc-grey - 20;

    .title {
        @extend %feature-title ;
        position: absolute;
        top: -37px;
        font-size: 20px;
     

    }

    .item-list {

        font-size: 13px;

        li {

            border-top:  1px solid $labc-grey - 20;
            padding: 12px 0 0;

            &.first {
                border : none;
            }
        }

        a {
            display: inline;
        }
        
    }





    .view-header {

        font-weight: bold;

        p {

            font-size :13px;

            margin-bottom: 0;

        }
    }

    .twitter-posted {

        color: $labc-grey - 80;
        padding-top: 6px;
        text-transform: uppercase;
        font-size: 11px;
        font-family: 'Roboto Slab', sans-serif;
        display: block;

        .placeholder {

            font-style: normal;
        }
    }

    .tweet  a{

        font-weight:normal;
    }


    .web-intents {

        padding-top: 4px;
        
        a {

            color :$labc-green;
            font-weight: normal;

            &:before {
                padding-right: 2px;
            }
        }

        span {
            padding-right :5px;
        }
        a[href*="reply"] {

            @extend .ss-reply;

            &:before {

                font-size: 12px;
                font-family: "SSStandard";
            }
        }

        a[href*="retweet"] {

            @extend .ss-retweet;

            &:before {

                font-size: 12px;
                font-family: "SSStandard";
            }
        }
        
        a[href*="favorite"] {
           @extend .ss-star;

            &:before {

                font-size: 12px;
                font-family: "SSStandard";
            }
        }
    }
}


.award-tweets {

    margin-top: 40px; 
    float:left;

}