.trainingpage {


    blockquote {
        text-indent: 0;
        float: right;
        width: auto;
        max-width: 300px;
        min-width: 230px;
        margin-left: 20px;
        margin-bottom: 20px;

        a {
            color: $iedesign-primary3;
            color: $iedesign-white;
            //font-weight: normal;
        } 

        p {
            margin: 0;
        }

        p:before,p:after { 
            content: ' ';
        }

        h3 {
            color: $iedesign-white !important;
            padding-bottom: 5px;
            border-bottom: 1px solid $iedesign-primary2 + 30;
            margin-top: 0;
            font-size: 18px;
            margin-bottom: 6px !important;
        }

        a {
            display: block;
            font-weight: 300;
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid $iedesign-primary2 + 30;

            &:hover {
                text-decoration: none;
            }

            &.internal-link {
                padding-left: 30px;
                background: transparent url(../images/internal.png) 0 4px no-repeat;
            }

            &.external-link {
                padding-left: 30px;
                background: transparent url(../images/external.png) 0 4px no-repeat;
            }
        }
    }

    h2 {
        padding-top: 20px;
        border-top: 1px solid $iedesign-grey;
        font-size: 18px;
        color: $iedesign-primary3;

        margin-top: 25px;
    }

}

.page-user-login {

    .tabs.primary {
        li:first-child {
            display: none;
        }
    }

    .login-form .form-submit {
        background-color: $iedesign-primary3;
        background-image: url('../images/icons/arrow-right.png'); 
        color: $iedesign-white;
        background-position-y: 13px;
    }

    .login-password {
        text-transform: none;
        padding: 12px;
        background: none;
        border: none;
        color: $iedesign-darkgrey;  
        font-size: 16px;

        &:hover {
            text-decoration: underline;   
        }
    }
}

.page-user .login-password {
    text-transform: none;
    padding: 12px;
    background: none;
    border: none;
    color: $iedesign-darkgrey;  
    font-size: 16px;

    &:hover {
        text-decoration: underline;   
    }
}

.page-user-password {
    .login {
        display: none !important;  
    }
}


.node-type-resource {
    article {
        position: relative;
    }

    .field-type-taxonomy-term-reference {
        padding: 0;
        //float: left;
        width: auto;
        //margin-top: 15px;
        //margin-bottom: 5px;
        margin-right: 5px;

        li {
            padding: 5px;
            background: rgba($iedesign-secondary, 1);
            border-bottom: 1px solid $iedesign-white;
            color: $iedesign-white;
            margin-bottom: 5px;
            display: inline-block;

            &:hover {
                background: $iedesign-secondary - 30;
            }
        }

        .field-items div {
            display: inline-block;  
            margin-right: 10px;
        }

    }
}

.page-user {
    .page {
        img {
            max-width: 250px; 
            margin-right: 15px;
            margin-bottom: 15px;
            border: 1px solid $iedesign-grey;
            padding: 5px;
            float: left;
            background: none;//$iedesign-secondary;
        }

        .field-type-text {
            border-top: 1px solid $iedesign-grey;
            margin-top: 20px;
            @include clearfix;
            padding: 15px;

            label {
                float: left;
                min-width: 125px;
                margin-right: 20px;
            }
        }
    }
}

.page-user-edit {
    .page {

        .form-type-radio, .form-type-checkbox{
            padding: 0;
        }

        .form-type-checkbox {

            float: none;
 
            .description {
                
                clear:both;
             }
        }    


        .form-checkboxes .form-checkbox {
            padding-left: 10px;
        }
        .form-item label {

            visibility: visible;
            display: block;
            font-weight: bold;
        }

        .field-type-text {

            border-top: none;
            margin-top: 5px;
            @include clearfix;
            padding: 0px;

            label {
                float: none;
                min-width: none;
                margin-right: 0;
            }
        } 
    }
}

.gmapslink {
    font-size: 12px;
    font-weight: 600;
    padding-left: 30px;
    background: transparent url(../images/maps_marker.png) 8px 5px no-repeat;
    color: $iedesign-primary3;
    position: absolute;
    right: 15px;
    top: 11px;
    display: block;
    height: 30px;
    padding-top: 7px;    
}


#show_user_details {
    .field-type-text,
    .field-type-taxonomy-term-reference,
    .field-type-list-boolean,
    .field-type-text-long {
        border-top: 1px dotted $iedesign-grey;
        margin-top: 20px;
        @include clearfix;
        padding: 15px;

        label {
            float: left;
            min-width: 125px;
            margin-right: 20px;
        }
    }   

    .field-type-taxonomy-term-reference .field-items {
        margin-left: 145px;
    }

    .field-type-text-long label {
        float: none;
    }
}

.view-resource-library {
    .view-empty {
        min-height: 230px;
    }
}

// My Files page view

.view-commerce-license-my-files{

    width: 100%;

    table.views-table{

        border-bottom:2px solid #efefef;

        caption{
            width: 100%;
            padding:7px 12px;
            text-align: left;
            background-color: transparent;
            font-size: 20px;
            font-weight: 500;
            color: #005e5e;

            @media all and (max-width: $lap-start) {
                font-size: 16px;
            }
        }

        tr th.views-field{
            background: #e54a91;
            border-bottom:2px solid #fff;
            color: #fff;
            padding:5px 12px;
        }

        tr:nth-child(odd){
            background: #efefef;
        }

        tr td{

            padding: 0;

            span a{
                width: 100%;
                padding: 12px;
                display: block;

                &:hover{
                    text-decoration: none;
                    color: darken(#00c2c2, 20%);
                }
            }
        }
    }

}

// Shopping cart page view

.view-commerce-cart-form{

    table.views-table{

        border-bottom:2px solid #efefef;
        margin-bottom: 0;

        tr th.views-field{
            background: #e54a91;
            border-bottom:2px solid #fff;
            color: #fff;
            padding:5px 12px;
            @media all and (max-width: $lap-start) {
               padding:6px;
               font-size: 13px;
            }
        }

        tr:nth-child(odd){
            background: #efefef;
        }

        tr td span a{
            width: 100%;
            padding: 12px;
            display: block;

            &:hover{
                text-decoration: none;
                color: darken(#00c2c2, 20%);
            }
        }

        td input.delete-line-item{
            background: #e54a91;
            border: 2px solid white;
            color: #fff;
            display: inline-block;

            &:hover{
                background: white;
                color:  #e54a91;;
            }
        }

        td.views-field-edit-quantity,
        th.views-field-edit-quantity,
        td.views-field-edit-delete,
        th.views-field-edit-delete{
            text-align: center;
        }

        td.views-field-commerce-total,
        th.views-field-commerce-total{
            text-align: right;
        }

        td.views-field{
            vertical-align: middle;
            font-size: 19px;
            @media all and  (max-width: $desk-start) {
                font-size: 13px;
                vertical-align: top;
            }
        }

    }

    .line-item-summary{
        padding: 5px 12px;
        border-bottom:2px solid #efefef;

        @media all and  (max-width: $desk-start) {
            padding-right: 0;
        }

        .line-item-total{
            font-size: 19px;
        }
    }

    #edit-actions{
        width: 100%;

        input{
            width: 25%;
            float: left;

            @media all and  (max-width: $desk-start) {
                width: 100%;
                margin-bottom: 3px;
            }

            @media all and  (max-width: $lap-start) {
                width: 100%;
                margin-bottom: 3px;
            }
        }
    }
}

// Orders page view

.entity-commerce-order {

    .content{
        padding-left: 0;
        padding-right: 0;
    }

    .view-commerce-line-item-table{

        table.views-table{

            border-bottom:2px solid #efefef;
            margin-bottom: 0;

            tr th.views-field{
                background: #e54a91;
                border-bottom:2px solid #fff;
                color: #fff;
                padding:5px 12px;
                @media all and (max-width: $lap-start) {
                   padding:6px;
                   font-size: 13px;
                   vertical-align: top;
                }
            }

            tr:nth-child(odd){
                background: #efefef;
            }

            tr td span a{
                width: 100%;
                padding: 12px;
                display: block;

                &:hover{
                    text-decoration: none;
                    color: darken(#00c2c2, 20%);
                }
            }

            td.views-field-edit-quantity,
            th.views-field-edit-quantity,{
                text-align: center;
            }

            td.views-field-commerce-total,
            th.views-field-commerce-total{
                text-align: right;
            }

            td.views-field{
                vertical-align: middle;
                font-size: 19px;
                @media all and  (max-width: $desk-start) {
                    font-size: 13px;
                    vertical-align: top;
                }
            }

        }

        .line-item-summary{
            padding: 5px 12px;
            border-bottom:2px solid #efefef;

            @media all and  (max-width: $desk-start) {
                padding-right: 0;
            }

            .line-item-total{
                font-size: 19px;
            }
        }

    }

    table.commerce-price-formatted-components{
        border-bottom:2px solid #efefef;

        @media all and  (max-width: $desk-start) {
            padding: 6px !important;
        }

        tr{
            td{
                font-size: 19px;
                font-weight: bold;
                
                @media all and  (max-width: $desk-start) {
                    font-size: 12px;
                }
            }

        }
    }

    .field-type-commerce-customer-profile-reference{
        margin: 12px;
        padding-left: 15px;
        border-left: 2px solid #00c2c2;

        @media all and  (max-width: $desk-start) {
            margin-left: 0;
        }

        label{
            font-size: 22px;

            @media all and  (max-width: $desk-start) {
                font-size: 14px;
            }
        }
    }
}