

// nav.tabs
//  ul.tabs
//        li >  a
//        li.active > a

.tabs {
    
   margin : 0 0 22px;
   border-bottom : 7px solid $iedesign-lightgrey - 32 ;

    // the UL withing Na

    .tabs {

        border:none;

    }

    ul li {
        
        padding: 0 1px;
        margin: 0;
        float: left;

        //border: 1px solid $iedesign-lightgrey;
        //padding: 5px 10px;

        .content  & {

            margin: 0;
        }
    }

    a {

        background: $iedesign-lightgrey - 12 ;
        //border-bottom:1px solid  $labc-grey - 20;
        display: block;
        padding: 8px 12px;
        font-size: 14px;
        color :  $tab-color;

        &:hover {
            
           background: $iedesign-lightgrey - 29 ;

        }
    }
    
    .active {

        a {

            color :#FFF;
            background: $labc-green;
            //border-bottom:1px solid  $labc-green - 26;
        }
    }

}



.comment-tabs  {

    border-bottom: none;

    a {

        background: white;
        color : $labc-green;

        &:hover {

            backgroumnd : white - 10;
        }
    }
}