
// Item List
//---------------------------------------------------------------


.item-list {
 
    margin: 0;

    @extend .cf;

    li {

         margin : 0 0 15px 0;
        padding: 0;
        float:left;
        list-style: none;

        @media all and (min-width:  $desk-start) {
         
            //margin : 0 0 12px 0;


            // TO DO Sort this out BW
         //   &.desk-one-third {

        //        width: 33%;
          //      margin-bottom: 15px;
           // }        
        }
    }

    a {

        display: block;
    }


    .title {

        font-weight: normal;
        padding-top : 8px;

    }

}


.content .item-list  li {
    
        list-style: none;
}



