.navigation-container nav ul.account-management {
	li a:hover {
		color: #fff;
	}

	img {
		max-width: 100%;
	}
}

.account-management {
	&__link {
		&--no-border {
			border: none;
		}
	}
}

.smartlaw-slogan .sl-logo__image{
    max-width: 142px;
    display:block;
    margin:0 auto;
}