// News List
//---------------------------------------------------------------

.news  {

    padding-top : 16px;

    @media all and (min-width:  $desk-start) {
        background: transparent;
    }

    @media all and  (min-width: $lap-start) {
        padding-left: 1%;
        width: 102%;
        margin-left: -1%;
    }


    img {

        width: 100%;
        max-height: 280px;

    }

    .item-list li {
        list-style-type: none;
    }

    .news-item {

        padding: 0;
        position: relative;
        margin-bottom: 20px;     
        border: 3px solid $iedesign-white;   
        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);

        @media all and  (min-width: $lap-start) {
            padding : 0 1%  0 0;
            padding-right: 0px;
            margin-bottom: 40px;      

            width: 48%;
            margin-right: 2%;      

            &.even {
                padding-right: 0;
                margin-right: 0%;
            }
        }

        @media all and (min-width:  $desk-start) {
            //background: rgba($iedesign-lightgrey, 0.7);
            //padding: 0;
        }

        .news-date {
            background: transparent url(../images/pink_triangle.png) no-repeat 0 5px;
            padding-left: 15px;
            color: $iedesign-darkgrey;
            font-size: 12px;
            margin-bottom: 10px;
        }

        .count {
            display: none;

            background : $labc-yellow;
            padding : 15px 16px 0 6px;
            position: absolute;

            left: 0;
            top: 0;
            font-size: 14px;
            font-weight: bold;
            &:after {
                content: ".";
            }
            
 

        } 
        
        .news-item-title {

            a {
                font-size: 17px;
                min-height: 35px;
                color: $iedesign-darkgrey;
                padding-left: 15px;
                
                &:hover {
                    color: $iedesign-darkgrey - 30;
                }
            }

            margin-bottom: 10px;

        }



        a {

            color: $labc-black;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.2px; 

            &:hover{

                text-decoration: none;
                color :$labc-orange;
            }
 
            &.edit-button {
                color: $iedesign-white;
                background: rgba($iedesign-secondary, 0.9);
                right: 0px;
            }
        }

        &.even .news-box .link {
            right: 20px;
        }


        &__content {
            background: $site-background-colour url(../images/middleblock.jpg) repeat;            
            position: relative;

            &:after {
                bottom: 100%;
                left: 10%;
                border: solid transparent;
                content: " ";
                height: 15px;
                width: 35px;
                position: absolute;
                background: transparent url(../images/middleblock_small_triangle.png) no-repeat 0 0;
            }

            padding: 15px;
            margin-top: -5px;
            color: $iedesign-primary3;
            position: relative;
            min-height: 155px;

            .tags {
                color: $iedesign-darkgrey;
                padding-left: 15px;
                font-size: 14px;

                a {
                    color: $iedesign-primary3;

                    @media screen and (max-width: 400px) {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }     

                ul {
                    margin-left: 8px;

                    li {
                        margin-bottom: 5px;
                        list-style-type: none;
                    }

                    li li {
                        margin-right: 10px;
                        list-style: disc;
                        margin-left: 0px;
                        padding-left: 0px;
                        text-indent: -8px;

                        cursor: pointer;
                        color: $iedesign-primary3;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }           
            }

            .link {
                display: none;
                @media all and (min-width:  $desk-start) {
                    display: block;
                    position: absolute;
                    right: 15px;
                    bottom: -24px;

                    a {
                        padding: 10px 35px 10px 15px;
                        border: 3px solid $iedesign-primary3;
                        background: $iedesign-white;
                        color: $iedesign-primary3;
                        font-weight: 600;
                        background: url("../images/icons/arrow-right-dark.png") no-repeat 93% 12px,
                                    url(../images/middleblock.jpg) repeat;
                        border: 3px solid $iedesign-white;
                        box-shadow: 1px 1px 3px rgba(10,10,10,0.3);


                        &:hover {
                            border-color: $iedesign-primary3;
                        }
                    }

                }
            }
        }

    }

}

