.call-to-actions {

	&.message-box {

		margin-top: 0;

		@media all and  (min-width: $lap-start) { 
			margin-top: 60px;
		}
	}



	.call-to-action {
		position: relative;
		text-align: center;
		background: $iedesign-primary3;
		background: rgba($iedesign-primary3, 0.9);
		color: $iedesign-white;
			margin: 0 auto 30px;
		float: none;

		@media all and  (min-width: $lap-start) { 
			max-width: 80%;
		}

		 &:nth-child(even) {
            background: rgba(0,94,94,0.9);
        }

        &:nth-child(odd) {
            background: rgba(0,194,194,0.9);
        }

        .title-image {
            margin-left: auto;
            margin-right: auto;
            font: 0/0 a;
            text-shadow: none;
            color: transparent;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 100%;
            height: 79px;
            width: 361px;
        }

        a.more{
            color: #005e5e;
        }

        &:nth-child(even) {
            a.more {
                color: #00c2c2;
            }
        }

		.calltoaction-button {

			position: absolute;
			bottom: -40px;
			@media all and  (min-width: 992px) { 
				bottom: -20px;
			}
			left: 50%;
			transform: translateX(-50%); 
			text-transform: none;
			border: 2px solid $iedesign-white;
			width: 60%;
		}


		.edit-button {

			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
		}

		.title {
			@include replace-text-with-dimensions('lp_banner.png');
			max-width: 100%;
			background-size: 100%;
			
			margin: 10px auto 10px;
		}

		&.even {

			background: rgba(0,94,94,0.9);
			background: rgba(0,94,94,0.9);

			.title {
				@include replace-text-with-dimensions('teachers_banner.png');	
			}
		}

		a.more {
			display: inline;
			color: $iedesign-primary;

			&:after {
				content: " \00BB";
			}
		}

		&.even a.more {
			color: $iedesign-primary3;
		}
	}


	@media all and  (min-width: $desk-start) {
		@include clearfix;

		.call-to-action {
			float: left;
			width: 49%;

			&.even {
				float: right;
			}
		}
	}
}