 //Member Conetnt blocks


    // div .member-content 
    //  div.member-content-wrapper 
    //      h2
    //       p
    //       a.action-button

 

.member-content {

    padding: 7px 7px 60px;

    margin: 0  0 12px;

    vertical-align:top;

    background-image: linear-gradient(top,  rgba(106,106,107,0.3) 0%,rgba(163,163,163,0.3) 50%,rgba(150,150,150,0.3) 100%);

    box-shadow: inset 1px 1px 1px rgba(210,210,210, 0.2),  inset -1px -1px 1px rgba(210,210,210, 0.2);

    background-color:  white;

    position: relative; 
    

    // Heading
    .heading,
    h2 { 

       font-size: 14px;
       margin:0;
       padding-bottom: 12px;
       color : $labc-green;
       line-height: 120%;

    }

    // Button 
    .action-button  {

        position: absolute;
        bottom: 6px;
        left: 7px;
        margin: 0;
        padding-left: 30px;
    }
 

}  

 .no-cssgradients   .member-content {
    background:rgb(205, 205, 205);
 }

// Wrapper

.member-content-wrapper {

    background: white;
    font-size:  14px;
    padding:    20px;
    box-shadow: 0 1px 1px rgba(210,210,210, 0.2);
    border: 1px solid rgb(247,247,247);

 }  



// Member Content  Within dashboard
    .dashboard-block,
    .dashboard-panel {

        padding-bottom: 45px;
    

        .title  {

            padding:  20px 20px 12px;
            background: white;
            font-size:  14px;
            box-shadow: 0 1px 1px rgba(210,210,210, 0.2);
            border: 1px solid rgb(247,247,247);
            border-bottom :none;
            line-height: 120%;


         }

         .dashboard-message {

            padding-top : 20px;
         }

        .member-content-wrapper {

      
            border-top : none;
            padding-top:0;
    
            @media all and  (min-width: $desk-start) {
          
                min-height: 148px;
            }
         }

    }



// Member Content  Within .registration-choice-descriptions

    // Example

    // div .member-content 
    //  div.member-content-wrapper 
    //      h2.reg-title.heading
    //  div.member-content-wrapper.reg-text
    //       p
    //       a.action-button


 

    .registration-choice-descriptions  {

        padding-bottom: 30px;

        @extend .cf;

        .member-content {

            @media all and  (min-width: $lap-start) {

              border-right: 10px solid white;
         
            }
        }


        .member-content-wrapper { 

            border-bottom: none;
            padding: 0 20px;
        }

        .reg-title  {

            font-weight: normal;
            padding-top: 20px;
 
         }

        .reg-text {

            padding-bottom: 20px;
            border-top: none;

            p {
                margin: 0;
            }
        }

        // CMS Edit button
        .edit-button {

            position: absolute;
            right: 7px;
            bottom: 7px;

        }

    }


// Member Content  Ref

    .benefits-block {

        
        .member-content {
        
            padding-bottom:7px;

        }


        ul  {

            margin: 12px 0 12px 20px;

            li {

                list-style-type: disc;
            }

        }

    }

// Member Message panel

.message-panel {
    
    padding-bottom: 7px;

    .dashboard-message {

        color: white;
    }

    .member-content-wrapper {
        
        min-height:235px;
        color: rgb(55,55,55);
        background: $labc-green;

        .view-footer{ 

            position: static;

            a {
                display: block;
                color: #FFF;
                padding: 0;

                &:hover {
                    color: #FFF;
                }
            }
        }
    }

}


// Call to action

.call-to-action-wrapper {


    .member-content { 

        &.call-to-action  {

            padding: 3px;
        }

        @media all and  (min-width: $lap-start) {

            border-right: 10px solid white;
        }


        @media all and  (min-width: $lap-start) {

            .member-content-wrapper {
                min-height: 260px;
             }
         }

        .heading {

            padding-bottom: 6px;
            text-transform: uppercase;
        }


        a {

            font-size: 12px;
            text-transform: uppercase;
            color: rgb(85,85,85);
        }
   }


}