// Styles required by admin sytesm

#content-access-page {
    
    .form-item label {

        display: block;
        visibility: visible;


    }

    .form-submit {
        float:left;
        margin: 0 10px 0 0;
    }
    
    .content_access-div {

        width: 100%;

        float:none;

        .form-type-checkboxes {

            &.form-item{
             width: 100%;
            }

            label {

                padding:12px 0;
                font-size :$h2-size;
                border-bottom:1px solid rgb(190,190,190);
            }

        }


           .form-type-checkbox  label {

                font-size:12px;
                font-weight: normal;
                padding:0;
                border-bottom: none;

             }
    }
}