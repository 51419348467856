.footer {

    transition: left 0.25s;
    position: relative;

    &__block {
        @media (min-width: 767px) {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                padding-left: 6px;
                padding-right: 6px;
            }
        }
    }

    &.active-menu {
        left: 240px;
    }
}

img.partners{
    height: 80px;
}

.footer_left {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
}

.footer-social {

    ul {

        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 5px;
            margin-bottom: 10px;

            a {
                display: block;
                color: #7d7d7d;
                font-size: 12px;
                font-weight: normal;
            }
        }
    }
}

#credits {
    padding-left: 0;
}

#copyright { 

    background: #969696; 
    width: 100%;
    padding: 5px 0;

    .row{
        background: #969696;
    }

    p,a {
       color: #000; 
    }

    p {
        font-size: 12px;
    }

    #supported_by{
        @media (min-width: 767px) {
            padding: 5px 0 0 0;
        }
    }

    #supported_by,
    #credits {
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    li {

        display: inline-block;
        margin-right: 10px;

        &.first a,
        :first-child a {
            padding-left: 0;
        }

        a {
            text-transform: none;
            padding: 10px 10px;
            font-size: 12px;
        }

        &:first-child {
            a {
                padding-left: 0;
            }
        }
    }
}