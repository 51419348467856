.registration-form.woocommerce {

	h2 {
		display: none;
	}

	form.register {
		margin-top: 0;
	}

	@media all and  (min-width: $desk-start) {
		float: right;
		width: 50%;
		margin-left: 20px;
	}
}