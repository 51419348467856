.home {	
	.view-opportunity-listing {
		.view-empty {
			padding: 15px;
		}
	}
}

.not-front {	

	.view-dashboard-legal-professional-opportunities-listing,
	.view-dashboard-teacher-opportunities-listing,
	.view-opportunity-listing {
		.view-empty {
			padding: 15px;
 			@include opaqueBackground;
 			border: 2px solid $iedesign-primary2;
			border-radius: $default-border-radius;
		}

		.view-header {
			padding: 0;

			.results {
				padding: 10px;
				font-size: 16px;
				background: $iedesign-darkgrey;
				color: $iedesign-white;
			}
		}

		.located_at {
			display: inline;

			div, span {
				display: inline !important;
				font-weight: 300 !important;
			}

			.map-link,
			.geo {
				display: none !important;
			}
		}

		position: relative;
 
		& > .title {
			background: $iedesign-darkgrey;
			color: $iedesign-white;
			padding: 5px;
			font-size: 18px;			
			//font-weight: normal;
			padding-left: 10px;
			//margin-bottom: 5px;
		}

		.row {
			background: $iedesign-white;
			margin-bottom: 10px;
			position: relative;
			padding: 10px;
			min-height: 130px;
			padding-bottom: 68px;
			border: 1px solid $iedesign-primary;

			@media all and  (min-width: $desk-start) {
				padding-bottom: 42px;			
				//padding-right: 70px;	
			}

			&:hover {
				background: $iedesign-lightgrey - 30;
			}

			.title a {
				color: $iedesign-darkgrey;
				font-weight: normal;
				font-size: 18px;
				margin-bottom: 10px;
			}

			.gmap-gmap {
				border: 2px solid $iedesign-primary;
				float: left;
				margin-right: 10px;
				margin-bottom: 10px;
			}

			.no-map {
				border: 2px solid $iedesign-primary;
				float: left;
				margin-right: 10px;
				margin-bottom: 10px;
				width: 105px;
				height: 105px;
				position: relative;
				overflow: hidden;
				background: $iedesign-lightgrey url(../images/middleblock.jpg) repeat;
				text-align: center;
				padding-top: 35px;
				@include box-sizing(border-box);
			}

			.view-details {
				display: none;

				@media all and  (min-width: $desk-start) {
					display: block;				
					position: absolute; 
					right: 20px;
					top: 15%;

					a {
						display: block;
						@include replace-text-with-dimensions("icons/big-arrow-right.png"); 
					}
				}
			}

			.key-facts {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				background: $iedesign-primary3;
				padding: 10px;
				color: $iedesign-white;

				b {
					font-weight: bold;
					color: $iedesign-primary;
				}
			}
		}

		&.view-display-id-page {
			.row {
				@media all and  (min-width: $desk-start) {
					padding-right: 70px;	
				}			
			}
		}

		.more-link {
			position: absolute;
			top: 7px;
			right: 10px;

			a {
				color: $iedesign-white;
				font-weight: normal;
				font-size: 14px;
				padding: 9px;
				background: transparent url('../images/icons/arrow-right.png') no-repeat right 10px; 
				padding-right: 40px;
			}
		}
	}

	.block-views-exp-opportunity-listing-page {
		
	}

	&.path-opportunities-teachers-create-opportunity .main-content > .content {
		position: relative;
		overflow: visible;
	}
 
	#webform-client-form-320 {
		padding-left: 15px;
		display: none;  
		background: $iedesign-lightgrey url(#{$layout_image_path}/middleblock.jpg) repeat;
		border: 1px solid #C0C0C0;
		position: relative;
		bottom: -10px;
		margin-bottom: 80px;

		input {
			//border: 1px solid $iedesign-grey;
		}

		color: $iedesign-primary3;
		label {
			color: $iedesign-primary3 !important;
		}

		.form-type-checkbox label {
			padding-top: 0;
		}

		#webform-component-intro {
			padding-top: 0;
		}

	    @media all and (min-width: $desk-start ) {
	    	//background: transparent;
			padding-right: 38%;

			.webform-component-fieldset {
				background-color: $iedesign-lightgrey;

			}

			.webform-component {
 
 				position: relative;
				overflow: visible;

				.description {
					display: none;
					background: $iedesign-lightgrey;
					font-style: italic;
					padding: 10px;

					position: absolute;
					top: 0px;
					left: 108%;
					width: 40%;

					@media all and (min-width: $desk-wide-start ) {
						left: 124%;
					}

					&:before {
						position: absolute;
						content: ' ';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 17.3px 10px 0;
						border-color: transparent $iedesign-lightgrey transparent transparent;
						left: -17px;
					}
				}

				&:hover .description,
				input:active + .description,
				input:focus + .description,
				select:active + .description,
				select:focus + .description,
				.select-wrapper.fakefocus + .description {
					display: block;
				}
			}

	    }

	    .fieldset-legend {
	    	font-size: 16px;
	    	font-weight: bold;
	    }

	    .webform-component-date .form-select {
	    	width: 145px;
	    }

	    .form-submit {
	    	//border-color: $iedesign-primary3;
	    }

	    .webform-component {
	    	@media all and (min-width: $desk-start ) {
		    	&:after {
					content: ' ';
					width: 20px;
					height: 20px;
					background: transparent url(../images/red_cross.png) no-repeat 0 0;
					position: absolute;
					top: 13px;
					right: -25px;
				}

				&.valid:after {
					background-image: url(../images/green_tick.png);
				}

				&.webform-component-markup:after,
				&#webform-component-terms-and-conditions:after {
					display: none;
				}

	    	}
	    }
	}

	//this form has not got any fieldsets
	@media all and (min-width: $desk-start ) {
		#webform-client-form-320 {
		    //background: $iedesign-lightgrey;
		    padding-right: 0;
		    margin-right: 30%;
		    padding: 15px;
		}
	}


	#block-views-opportunity-details-block {
		
		.views-field-created {
			//background: rgba($iedesign-lightgrey, 0.7);
			margin-bottom: 15px;
			padding: 0;// 18px 12px;
			color: $iedesign-primary3;
			//@include opaqueBackground;

			@media all and (min-width: $desk-start ) {
				 
 			}
		}

		.views-field-nothing {
			
			@include clearfix;
			display: block;

			.infobox {
				width: 100%;
				background: $iedesign-white;
				border: 1px solid $iedesign-grey;
				margin-bottom: 15px;
		        
		        @media all and (min-width: $desk-start ) {
		        	
		        	//border: none;
		        	width: 48%;
		        	border-color: $iedesign-lightgrey;
		        	//float: left;
		        	//margin-right: 1%;
		       // 	margin-bottom: 15px;

		        	&.column2 {
		        ///		float: right;
		        		//margin-left: 1%;
		        		margin-right: 0;
		        	}
		        }

				.infotop {
					padding: 15px;
					background: $iedesign-white;

					h3 {
						margin: -15px -15px 0;
						padding: 10px 15px;

						background: $iedesign-lightgrey;
						color: $iedesign-primary2;
						margin-bottom: 10px;
						font-size: 19px;
						font-weight: 400;
						/*
						&:before {
							content: '\25B6';
							margin-left: -17px;
							padding-right: 4px;
							color: $iedesign-darkgrey;
						}
						*/
					}

					.infobit {
						@include clearfix;

						span:first-child {
							width: 44%;
							display: block;
							float: left;
							font-weight: bold;
							//width: 100px;

							&:after {
								content: ': ';
							}
						}

						span:last-child {
							width: 56%;
							display: block;
							float: left;

						}
					}
						
				}
					
				.infobottom {
					padding: 15px;

					p {
						margin-bottom: 5px;
					}

					ul {
						margin-left: 18px;
						margin-bottom: 5px;
						list-style: disc;

						li {
							list-style: disc;
						}
					}
				}
			}

		}

		
			
		.view-footer {
			margin-top: 10px;

			a {

				padding: 5px 35px;
				padding-right: 40px;
				color: $iedesign-primary3;
				border: 4px solid white;
				width: 100%;
				display: block;
				font-size: 16px;
				font-weight: 400;
           		box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
           		margin: 0 auto;

 				&:hover {
					border-color: $iedesign-primary3;
					text-decoration: none;
				}

				position: relative;
				background: url(#{$layout_image_path}/middleblock.jpg) repeat;

				&:after {
					content: "";
					display: block;
					background:  url('#{$layout_image_path}/icons/arrow-right-dark.png') no-repeat 0 0;
					width: 24px;
					height: 14px;
					position: absolute;
					right: 8px; 
					top: 50%;
					transform: translateY(-50%);

				}

				@media all and  (min-width: $desk-start) {
					width: 48%;	
				}
			}
		}

		.topmap {
			position: relative;

			.mapwrapper {
				.gmap-control.gmap-schoolMap-gmap {
					width: auto !important;

					@media all and  (max-width: $desk-start) {
						height: 200px !important;
					}
				}
			}

			.keyfacts {		
				@include clearfix;	

				padding: 15px;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 200px;	//this is hardcoded in the map in drupal too

				@media all and  (min-width: $desk-start) {
					height: 300px;	//this is hardcoded in the map in drupal too				
				}
				background: $iedesign-primary3;
				background: rgba($iedesign-primary3, 0.9);
				color: $iedesign-white;

				@media all and  (min-width: $desk-start) {
					width: 40%;	
				}

				h3 {
					font-size: 20px;
					font-weight: normal;
					margin-bottom: 10px;
				}

				span {
					float: left;
					width: 75%;
				}

				span.label {
					color: $iedesign-primary;
					display: inline;
					font-weight: bold;

					width: 25%;
				}
			}

			.bottominfo {
				position: relative;
				padding: 15px;
				border-top: 1px solid $iedesign-grey;
				span {
					font-size: 12px;
				}
				span.label {

					//color: $iedesign-primary;
					display: inline;
					margin-right: 0px;
					color: $iedesign-primary3;
					font-weight: 700;
 
					&:after {
						content: '\25BA';
						color: $iedesign-secondary;
						font-size: 0.9em;
						padding: 0 3px 0 3px;
					}

					& + span {
						display: block;
						margin-bottom: 10px;
						position: relative;
						left: 28%;
						margin-top: -20px;
					}
				}	

				@media all and  (min-width: $desk-start) {
					span.label {
						& + span { 
							margin-right: 25px;
							display: inline;
							margin-bottom: 0;
							margin-top: 0;
							left: auto;
							position: relative;
						}
					}					
				}					
			}
		}


		.views-field-view {
			//padding: 15px;
			background: $iedesign-lightgrey;// $site-background-colour url(../images/middleblock.jpg) repeat;
			margin-bottom: 15px;
			border: 2px solid $iedesign-primary3;

			position: relative;

			@media all and  (min-width: $desk-start) {
				width: 98%;
			}

			.view-footer {
				position: absolute;
				bottom: 135px;
				left: 15px;

				a {
					display: none;
					width: 100%;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 25px;
					background-position-y: 20px;
				}

				@media all and  (min-width: $desk-start) {
					bottom: 80px;

					a {
						display: inline;
						width: auto;
					}
				}
			}		
		}	
	}



	&.node-type-opportunity .main-content > .content #opportunity-node-form {
		.vertical-tabs {
			border: none;
		}
		.vertical-tabs-list,
		.vertical-tabs-panes {
			display: none;
		}

		.location.form-wrapper {
			& > .fieldset-wrapper > .form-type-checkbox {
				display: none;
			}

			.form-item label, 
			.form-select label {
				visibility: visible;
			}

			.form-item input {
				width: 100%;
			}
		}

		.ui-accordion-content {
			height: auto !important;
		}

		.ui-accordion {
			.ui-accordion-header {
				border-radius: 0;			
				font-weight: normal;
				font-family: "Roboto Slab", sans-serif;
				padding: 5px 15px;
				background: rgba($iedesign-primary3, 0.6);

				a {
					color: $iedesign-primary3;
				}

				.ui-accordion-header-icon {
					display: none;
				}
			}
		}

		.form-item {
			label {
				display: block;
				visibility: visible;
				color: $iedesign-primary3;
				font-size: 17px;
			}

			.field-suffix {
				display: none;
				@media all and  (min-width: $desk-start) {
					//display: inline;	
				}
			}
		}

		.form-wrapper {
			margin-bottom: 10px;

			legend {
				background: $iedesign-primary3;
				padding: 5px;
				display: block;
				width: 100%;
				text-align: center;
				color: $iedesign-white;

				@media all and  (min-width: $desk-start) {
					text-align: left;
				}

				a {
					color: $iedesign-white;
				}
			}

			#edit-field-start-date,
			#edit-field-end-date {
				legend {
					background: none;
					color: $iedesign-primary3;
					text-align: left;
					padding-left: 0;
					font-weight: 400;
				}

				fieldset {
					padding: 0;
					border: none;
				}
			}
		}

		.form-type-checkbox {
			width: 100%;
			float: none;

			label {
				font-size: 14px;
			}
		}

		@media all and  (min-width: $desk-start) {
			& > .required-fields {
				fieldset {
					width: 49%;
					float: left;
					margin-right: 1%;

					&:first-child {
						width: 99%;
						float: none;
						margin-right: 0%;

						@include clearfix;

						& > .fieldset-wrapper .form-wrapper {
							width: 49%;
							float: left;
							margin-right: 1%;

							//this is a fieldset too
							&.location {
								width: 100%;
								margin-right: 0%;

								.form-wrapper {
									width: 100%;
									margin-right: 0%;
								}
							}
						}
					}
				}
			}

			.vertical-tabs.clearfix {
				clear: both;
			}

			#edit-field-opportunity-school {
				margin-bottom: 12px;
			}

			//this is all the info for ckeditor
			.form-type-textarea + .ckeditor_links + .filter-wrapper {
				display: none;
			}
		}
	}


	#views-exposed-form-promote-opportunity-block-1 {
		padding: 15px;
		border: 1px solid $iedesign-grey;
		margin-bottom: 30px;
		position: relative;
		background: $iedesign-lightgrey;

		.form-type-bef-checkbox {
			label {
				display: inline;
			}
		}

		input[type="checkbox"],
		input[type="radio"] {
			width: 30px;
			height: 30px;
		}
		input[type="radio"] + label {
			padding-top: 10px;
		}

		.form-submit {
            background: url("../images/icons/arrow-right-dark.png") no-repeat 93% 15px,
                        url(../images/middleblock.jpg) repeat;
            border: 3px solid $iedesign-white;
            box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
            color: $iedesign-primary3;


			position: absolute;
			right: 15px;
			bottom: -15px;

			&:hover {
				border-color: $iedesign-primary3;
			}
		}

		.views-exposed-widget {
			.description {
				margin-bottom: 19px;
				font-size: 11px;

				@media all and (min-width: $desk-start ) {
					padding-left: 40%;
				}
			}

			.form-item {
				margin-bottom: 10px;
			}

			&#edit-field-experience-tid-wrapper .description {
				@media all and (min-width: $desk-start ) {
					padding-left: 0%;
				}	
			}

		}

		@media all and (min-width: $desk-start ) {
			width: 65%;
			//border: none;
		}
	}

	.view-id-promote_opportunity.view-display-id-block_1 {
		//padding: 15px;

		//background: rgba($iedesign-grey, 0.7);

		& > .odd,
		& > .even {
			@include clearfix;
			border: 1px solid $iedesign-grey;
			margin-bottom: 10px;
			padding: 5px;
			background: $iedesign-lightgrey;
			position: relative;
			min-height: 52px;
			line-height: 52px;

			&:hover {
				background: $iedesign-lightgrey - 30;
			}

			.views-field-nothing li {
				list-style: none;
			}

			a {
				color: $iedesign-primary3;
				font-weight: normal;
			}

			a.dialog-box {
				position: absolute;
				top: 15px;
				right: 10px;
				padding: 5px 40px 5px 5px;
				font-weight: normal;

				background: $iedesign-primary3 url('../images/icons/arrow-right.png') no-repeat 93% 5px; 
				color: $iedesign-white;
				border: 2px solid $iedesign-white;
				line-height: 18px;
			}
		}

		.pic {
			float: left;
			width: 70px;
			line-height: 8px;
			//margin-bottom: 10px;

			img {
				max-width: 70px;
			}
		}

		.name {
			padding-left: 85px;
			line-height: 25px;
		}

		.job {
			padding-left: 85px;
			line-height: 25px;
		}
	}




	.activeteachersdash,
	.activelpdash,
	.mocktrialsdash {
		background: url(#{$layout_image_path}/middleblock.jpg) repeat;
		padding: 15px; 
		border: 1px solid $iedesign-grey + 20;
		margin-bottom: 20px; // Was 40px;
		& > .title {
			font-size: 20px;
			color: $iedesign-primary3;
			font-weight: bold;
		}

		.views-table {
			th {
				background: transparent;
				color: $iedesign-primary3;

				&:hover {
					background: transparent;
				}
			}

			tbody tr {
				background: $iedesign-lightgrey - 10;
				&.odd {
					background: $iedesign-lightgrey - 30;
				}

				&:hover {
					background: $iedesign-lightgrey - 50;
				}

				td {
					&.views-field-field-opportunity-status {
						color: $iedesign-primary;
					}
					a {
						color: $iedesign-primary3;
					}

					.open,
					.closed,
					.pending,
					.confirmed {
						color: $iedesign-primary3;
						text-transform: capitalize;
						font-weight: bold;
					}
					.accepted {
						color: $iedesign-primary2;
						text-transform: capitalize;
						font-weight: bold;
					}
					.withdrawn,
					.cancelled,
					.rejected {
						color: $iedesign-secondary;
						text-transform: capitalize;
						font-weight: bold;
					}						


					&.viewlink {
						background: $iedesign-primary3;
						a {
							color: $iedesign-white;	
							@include replace-text-with-dimensions("icons/big-arrow-right-white.png");
							width: 25px;
							height: 29px;
							display: block;
						}					
					}
				}
			}
		}


		.view-footer  {
			padding: 20px 0 0 ;
			position: relative;
			.button {
			 	position:absolute;
			 	bottom: -48px;
			 	right: 0;
		 	}
		}
	}

	.mocktrialsdash {
		width: 100%;
	}

	.activeteachers,
	.activelp {

	}

	.activeteacherslisting {
		margin: 0;

	}

	//active opportunities teacher
	.activeteacherslisting,
	.activelplisting {
		
		border-top: 2px solid rgb(200,200,200);
		background: url(#{$layout_image_path}/middleblock.jpg); 
		padding: 15px 27px;

		.pager {
			background: $iedesign-lightgrey - 20;
		}

		.view-empty p {
			margin-bottom: 0;
		}

		.view-header {
			h3 {
				border-bottom: 1px dashed $iedesign-primary3;
				color: $iedesign-primary3;
				padding-bottom: 4px;
				font-size: 20px;
			}
		}

		th {
			background: transparent;
			color: $iedesign-primary3;
			text-align: left;

			&:hover {
				background: transparent;
			}
		}

		a {
			color: $iedesign-primary3;
		}
		.volunteers {
			background: $iedesign-lightgrey - 30;
			padding-bottom: 15px;
			margin-bottom: 2px;

			&:hover, &.active {
				background: $iedesign-lightgrey + 30;
			}
		}

		table {

			margin-bottom: 0;

			td {
				vertical-align: top;
			}
		}

		.open,
		.closed,
		.pending,
		.confirmed {
			color: $iedesign-primary3;
			text-transform: capitalize;
			font-weight: bold;
		}
		.accepted {
			color: $iedesign-primary2;
			text-transform: capitalize;
			font-weight: bold;
		}
		.withdrawn,
		.cancelled,
		.rejected {
			color: $iedesign-secondary;
			text-transform: capitalize;
			font-weight: bold;
		}
	}

	ul.actionlinks {
		margin-left: 0;

		li {
			list-style: none;

			&.cancellink {
				font-size: 14px;
				text-align: right;
				padding-right: 8px;
				padding-top: 3px;
			}
		}

		a {
			width: 125px;
			padding: 5px 10px;
			color: $iedesign-white;
			border: 2px solid $iedesign-white;
			background: $iedesign-primary3 url('../images/icons/arrow-right.png') no-repeat 96% 7px; 
			display: block;
			font-weight: normal;
			font-size: 14px;
			margin-bottom: -1px;

			&:hover {
				background-color: $iedesign-primary2;
			}

			&.withdraw {
				background-color: $iedesign-secondary;
				background-image: url("../images/icons/cross.png");
				background-size: 16px 14px;
				background-position: 94% 9px;
			}

			&.cancelopp {
				background: none;
				border: none;
				padding: 0;
				color: $iedesign-primary3;
				text-decoration: underline;
				text-transform: lowercase;
				font-weight: bold;
				display: inline;

				&:hover {
					background: none;
					color: $iedesign-primary2;
				}
			}
		}
	}

	.volunteersopportunity,
	.creatoropportunity {
		display: none; 
		font-size: 12px;

		.cf {
			border-bottom: 1px dashed $iedesign-grey;
			margin-bottom: 0;
			min-height: 60px;
			//height: 60px;
			background: $iedesign-white;

			&:hover {
				background: $iedesign-lightgrey;
			}

			table td {
				padding: 0;
				text-align: left;
			}

			.actionlinks {
				margin-left: 10px;
				margin-bottom: 0;

				a {
					background-size: 24px 14px;
					background-position: 96% 9px;
				}

				.accept {
					background-color: $iedesign-primary2;
					background-image: url("../images/icons/tick.png");
					background-size: 16px 14px;
					background-position: 94% 9px;
				}

				.reject {
					background-color: $iedesign-secondary;
					background-image: url("../images/icons/cross.png");
					background-size: 16px 14px;
					background-position: 94% 9px;
				}
			}
		}
	}	

	a.managevolunteers {
		bottom: 10px;
		left: 10px;
		position: absolute;
		z-index: 2;
		background: url(#{$layout_image_path}/middleblock.jpg);
	}

	.dismissactivity {
		display: inline-block;
		//margin: 12px 0 0 ;
		color: $iedesign-secondary;
		padding: 5px;
		background: rgba($iedesign-secondary + 150, 1);
		border: 2px solid $iedesign-secondary;
		text-decoration: none;

		position: absolute;
		right: 7px;
		bottom: -30px;

		&:hover {
			background: rgba($iedesign-secondary + 50, 1);
		}
	}

	.volunteersopportunityview,
	.creatoropportunityview {
		margin-bottom: 45px;
	}

}



#block-views-exp-opportunity-listing-page {
	

	#edit-submit-opportunity-listing {
		position: absolute;
		right: 0px;
		width: 50%;

		padding: 10px;
		padding-right: 45px;
		color: #323232;

	    background: url("../images/icons/arrow-right-dark.png") no-repeat 93% 15px,
	                url(../images/middleblock.jpg) repeat;
	    border: 3px solid $iedesign-white;
	    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);

	    &:hover {
	        border-color: $iedesign-primary3;
	        background-color: $iedesign-lightgrey;
	    }		
	}

	/* // change the ajax loader
	.progress-disabled {
	  float: none;
	  display:inline;
	}
	.ajax-progress {
	  float: none;
	  display:block;
	   position: absolute;
	  top:40%;
	  margin-left: auto;
	  margin-right: auto;
	}
	.ajax-progress .throbber {
	  background: transparent url('../images/ajax-loader.gif') no-repeat;
	  float: none;
	  height: 128px;
	  //margin: 0 auto;
	  width: 128px;
	  display:inline-block;


	}
	tr .ajax-progress .throbber {
	  margin: 0 2px;
	}
	.ajax-progress-bar {
	  width: 16em;
	} */
}
/* show mouse loader icon on ajax, class set in js */
body.wait *, body.wait
{
	    cursor: progress !important;
}


.node-type-opportunity {
	
	.page-title {

		margin-bottom: 0;
	}
	.main-content  .content{
		padding-bottom: 0;
	}
}