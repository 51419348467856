.home {
	overflow: hidden;

	.viewalllink {
		margin-bottom: 15px;

		a {
			text-align: center;
			display: block;
			color: $iedesign-white;
			font-weight: 300;
			font-size: 18px;	
			@include hover-link;

			background: white url("../images/icons/arrow-right-dark.png") no-repeat 93% 10px;
            border: 3px solid $iedesign-primary2;
            box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
            color: $iedesign-primary2;
            padding: 5px 35px 5px 15px;			

            width: 90%;
            margin: 0 auto;
		}

		p {
			margin: 0;
		}

		@media all and  (min-width: $lap-start) {
			display: none;
		}
	}

	.testimonials-homepage {
		//background: #bbb;

		.title {
			background: $iedesign-darkgrey url('../images/icons/testimonials.png') no-repeat 7px 7px;		
			background-size: 25px 25px;	
			color: $iedesign-white;
			padding: 5px;
			font-size: 18px;			
			//font-weight: normal;
			padding-left: 40px;
			//margin-bottom: 5px;
		}

		.view-testimonials {			

			ul {
				margin-left: 0;

				li {
					padding: 25px 15px;
					float: none;
					color: $iedesign-white;
			 		position: relative; 

			 		&:after {
			 			content: "";
			 			position: absolute;
			 			display: block;
			 			left: -7px;
			  			@include block-arrow(left, $iedesign-primary);
			  			top: 20%;
			  		}

			 
					background: $iedesign-primary;

					.views-field-body {
						p {
							&:before {
								content: open-quote; 
							}
							&:after {
								content: close-quote; 
							}
						}
					}

					.views-field-field-person-details {
						font-size: 11px;
						margin-top: 10px;
						text-align: center;
					}

					&.odd {
						
					}

					&.even {
						
						&:after {
							border-right-color: $iedesign-primary2;
						}

						background: $iedesign-primary2;
					}
				}
			}
		}
	}


 
	
		.main-content  .home-intro {

			margin-top: 30px;
			padding: 32px;
			padding-top: 0;
			background: rgba($iedesign-white, 1);

			@media all and  (max-width: $desk-start) {
				padding-top: 20px;
			}
	
			@media all and  (min-width: $desk-start) {
				padding-top: 32px;		
				border: 2px solid white;
				/*margin-top: 80px;*/

			}

			p {
				text-align: center;
				max-width: $page-width - 100;
				margin: 0 auto;
				font-size: 17px;
				line-height: 1.5em;

				@media all and  (min-width: $desk-start) {
					font-size: 21px;
				}
			}
		}

		@media all and  (min-width: $desk-start) {
	 	
		 	.content-bottom {
		 		background: $iedesign-white;
				padding-top: 0px;
			}

		}

	.opportunities-homepage {
		position: relative;
		display: none;
		@media all and  (min-width: $lap-start) {
			display: block;
		}

		.school_info {
			display: none;
			@media all and  (min-width: $desk-start) {
				display: block;
				font-size: 14px;

				.map-link {
					display: none;
				}

				div,
				.additional {
					display: inline;
					font-weight: 300;
				}
			}
		}

		& > .title {
			background: $iedesign-darkgrey;
			color: $iedesign-white;
			padding: 5px;
			font-size: 18px;			
			//font-weight: normal;
			padding-left: 10px;
			//margin-bottom: 5px;
		}
		
		.key-facts {
			font-size: 14px;
			padding-top: 8px;

			position: absolute;
			bottom: 11px;
			left: 125px;
		}

		.view-opportunity-listing {

		}

		.view-opportunity-listing .cf {

			background: $iedesign-white;
			margin-bottom: 10px;
			position: relative;
			padding: 10px;
			min-height: 130px;
			border: 1px solid $iedesign-primary;

			&:hover {
				background: $iedesign-lightgrey;
			}

			.title a {
				color: $iedesign-darkgrey;
				font-weight: normal;
				font-size: 18px;
				margin-bottom: 10px;
				height: 30px;
				overflow: hidden;
				display: block;

				@media all and  (min-width: $desk-start) {
					height: auto;
				}
			}

			.gmap-gmap {
				border: 2px solid $iedesign-primary;
				float: left;
				margin-right: 10px;
			}

			.no-map {
				border: 2px solid $iedesign-primary;
				float: left;
				margin-right: 10px;
				margin-bottom: 10px;
				width: 105px;
				height: 105px;
				position: relative;
				overflow: hidden;
				background: $iedesign-lightgrey url(../images/middleblock.jpg) repeat;
				text-align: center;
				padding-top: 35px;
				@include box-sizing(border-box);
			}			

			.view-details {
				display: none;

				@media all and  (min-width: $desk-start) {
					display: block;
					position: absolute; 
					right: 20px;
					top: 25%;

					a {
						display: block;
						@include replace-text-with-dimensions("icons/big-arrow-right.png"); 
					}

				}

			}

			.key-facts b {
				font-weight: bold;
			}
		}

		.more-link {
			
			position: absolute;
			top: 7px;
			right: 10px;

			a {
				color: $iedesign-white;
				font-weight: normal;
				font-size: 14px;
				padding: 9px;
				background: transparent url('../images/icons/arrow-right.png') no-repeat right 10px; 
				padding-right: 40px;
			}
		}
	}
	

	@media all and  (min-width: $desk-start) {
		#page-container {
			padding-bottom: 0 !important;
		}

		.content.home-intro {
			@include clearfix;
		}

		.content-bottom {
			@include clearfix;

			margin-left: -1000px;
			margin-right: -1000px;
			padding-left: 1000px;
			padding-right: 1000px;

			margin-top: 0;
			padding-bottom: 10px;
		}

		.opportunities-homepage {
			float: left;
			width: 79%;

			.view-opportunity-listing .cf {
				display: block;
			}
		}

		.testimonials-homepage {
			float: right;
			width: 19%;

			margin-left: 10px;
		}

	}

	.footer-content {
		margin-bottom: 15px;
	}
}
