.info-box {
	h2,
	img {
		margin-bottom: 20px;
	}

	h2 {
		text-align: left;

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	p {
		word-break: break-word;
	}
}