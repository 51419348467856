// Create Opportunity Webform
// Specific styles


#ui-datepicker-div {
	z-index:999;
}
.webform--opportunity {
	#edit-submitted-step-2-terms-and-conditions-1 {
		padding-top: 10px;
	}

	legend {
		display: none;
	}
 
 	max-width: 558px;
 	margin: 0;
 	padding:0;

    .webform-component {
		@media all and (min-width: 578px ) {
	    	&:after {
				content: ' ';
				width: 20px;
				height: 20px;
				background: transparent url(../images/red_cross.png) no-repeat 0 0;
				position: absolute;
				top: 22px;
				right: -32px;
			}

			&.valid:after {
				background-image: url(../images/green_tick.png);
			}

			&.webform-component-markup,
			&#webform-component-terms-and-conditions,
			&#webform-component-step-2--terms-and-conditions,
			&#webform-component-step-1--number-of-sessions,
			&#webform-component-step-1--opportunity-start-date,
			&.heading {
				&:after {
					display: none;	
				}			
			}

			padding: 10px;
		}
    }

    #webform-component-step-2--terms-and-conditions {
    	& > label {
	    	display: none;
	    }

    	label {
    		padding-top: 9px;
    	}
    }


	.webform-component-fieldset  {

		width: 100%;
		background: $iedesign-lightgrey url(#{$layout_image_path}/middleblock.jpg) repeat;
		margin: 24px 0;
		padding: 24px 20px;

		@media all and  (min-width: 583px) { 

			padding-right: 60px;
		}
	}

	// Title for a fieldset replacing Legend
	
	.step-title {
 		
 		padding-top: 12px; 		

		@media all and (min-width: 593px ) {
			padding-left: 10px;
			width: 50%;
			float: left;

			&.step-title--step-2 {
				width: 34%;
			}
			

		}

		.title {

			text-transform: uppercase;
			font-size: 20px;
			font-weight: 700;
			color: $iedesign-primary3;

		}

		.title__sub {

			font-size: 0.8em;
			font-weight: 400;

			text-transform: none;
		}
	}

	#webform-component-step-2 .step-title.logged_in {
		width: 100%;
	}

	// Heading

	.heading {

		width: 100%;
		float: left;
		margin: 0;
		padding-bottom: 0;
		font-weight: 700;
		color: $iedesign-primary3;

	}



	.form-item  { 

		float: left;
		width: 100%;

		label {
			padding-top: 6px;
		}

		.required:after {

            content : "";
            display: none;
            
        }


	}

	div.description {

		display: none;
	}

	#webform-component-step-1--session-length,
	#webform-component-step-1--number-of-volunteers  {


			
		label {
			text-transform: capitalize;
			font-size: 14px;
		}


		@media all and (min-width: 583px ) {

			width: 50%;
			float:right;
			clear:right;
			padding-right:0;

			label {

				float: right;
				width: auto;
				text-transform: none;
			}
			.select-wrapper {

				float: left;
				width: auto;
			}
		}
	}

	#webform-component-step-1--session-length {

		width: 50%;
		float:right;
		clear:right;	
	}

 
	#webform-component-step-1--opportunity-start-date,
	#webform-component-step-1--opportunity-end-date {

		
		width: 100%;

		padding-right: 50px;


		label {
			
			width: auto;
			font-weight: 400;
			font-size: 14px;
			
		}

		.datepicker {
			padding-right: 6px;
			padding-left: 6px;
			width: 100%;
			margin: 0;
			max-width: 100%;

		}
		.ui-datepicker-trigger {

			position: absolute;
			top: 50%;
			right: 0;
			width: 30px;
			height: 30px;
			padding: 0;
			border: none;
			background: none;

		}

		@media all and  (min-width: 583px) {
			clear: right;
			width: 50%;

			margin-bottom: 14px;

			.datepicker {

				max-width: 96px;
			}
			.ui-datepicker-trigger {
	
				top: 15px;			
			}

		}


	}

	#webform-component-step-1--opportunity-end-date {
		
		@media all and  (min-width: 583px) {

			padding-left: 5%;

			label {
				padding-left: 20px; 
			}
		}
	}

	#webform-component-step-1--number-of-volunteers  {

		label {

			font-size: 14px;

			@media all and  (min-width: 583px) {
				padding-top: 3px;
			}
		}

		@media all and  (min-width: 583px) {
			width: 50%;
		}

	}


	#webform-component-step-1--topic {

		width: 100%;
		padding-right:0;

		label {
			font-size: 14px;
			font-weight: 400;
		}

		.description {
			top: 10px;
		}

		@media all and  (min-width: 583px) {
			.select-wrapper {
				float: right;
				margin-top: -40px;	
				width: 67%;
			}
			
		}

		
	}


	#webform-component-step-1--number-of-sessions {
			
		width: 50%;
		padding-right:0;

		label {
 
			width: auto;
			font-weight: 400;
			font-size: 14px;
		}

		.select-wrapper {

			float:left;
			max-width:184px;
			width: 100%;
		}

		@media all and  (min-width: 583px) {
			
			label {

				float: left;
			}
		}

	}

	#webform-component-step-1--session-length {

		padding-left: 3%;
	}


	#webform-component-step-2--user-selection {

		
		@media all and  (min-width: 583px) {
			
			float:left;
			width: 50%;
		}

		label {

			display: none;
		}


	}


 	.webform-component-markup {
		
		
		p {
			font-size: 12px;
			padding: 0px 0 0;
			margin: 0;
		}
	}


	.webform-container-inline {
	
		.form-text {

			width: auto;
			float:left;
			max-width: 290px;
		}
	}


	 @media all and (min-width: $desk-start ) {
	    
	    background: transparent;

		.webform-component {

			&.heading {
				margin-top: -6px;
				margin-bottom: -14px; 
			}
				
			margin: 0;
			position: relative;
			overflow: visible;

			.description {

				display: none;
				background: $iedesign-lightgrey; 
				padding: 10px;
				position: absolute;
				top: 15px;
				left: auto;
				right: -55%;
				width: 180px;

				&:before {

					position: absolute;
					content: ' ';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 7px 12px 7px 0;
					border-color: transparent $iedesign-lightgrey transparent transparent;
					left: -12px;
				}
			}

			&:hover .description,
			input:active ~ .description,
			input:focus ~ .description,
			select:active ~ .description,
			select:focus ~ .description,
			.select-wrapper.fakefocus ~ .description {
				display: block;
			}

		}

			 
			#webform-component-step-1--session-length {

			}

			#webform-component-step-1--opportunity-start-date,
			#webform-component-step-1--number-of-sessions {
				
				.description {

					right: -213%;
				}
			}
			
			#webform-component-step-1--number-of-volunteers,
			#webform-component-step-1--opportunity-end-date,
			#webform-component-step-1--session-length {
				
				.description {

					right: -113%;
				}

			}
			
			#webform-component-step-2--user-selection {

				.description {

					right: -145%;
				}

			}


		 
	}
}


#webform-client-form-320 {
	background: $iedesign-lightgrey url(#{$layout_image_path}/middleblock.jpg) repeat;

	#webform-component-intro {
 		
 		padding-top: 12px;

		@media all and (min-width: 593px ) {

			width: 50%;
			float: left;

		}

		.title {

			text-transform: uppercase;
			font-size: 20px;
			font-weight: 700;
			color: $iedesign-primary3;

		}

		.title__sub {

			font-size: 0.8em;
			font-weight: 400;
		}
	}

	#edit-actions {
		position: absolute;
		left: 0;
		bottom: -64px;
	}
}
	