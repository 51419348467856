



.search {

     // Submit Button Container
    width: auto;
    position:relative;

    .form-item {
        
        max-width: 100%;
    }

    .views-submit-button,
    .form-actions {

        @extend .ss-search;
        width: 43px;
        border-left: 1px solid rgb(200,200,200);
        height: 39px;

        background:none;
        margin: 0;
        padding:0;
        position:absolute;
       
        right: 12px;
        top: 16px;


        &:before {
            
            color : $labc-green;
            right: 12px;
            top : 9px;
            position:absolute;
            font-family: "SSStandard";
            display: block;
            z-index: 1;
            font-size: 17px;
        }



    }

    // Submit Button Element

    .form-submit {

        margin: 0;
        width:100%;
        height: 100%;
        position:relative;
        padding:0;  
        z-index: 3;
        background:none;

        @extend .ir;


        &:hover {

            background: rgba($labc-grey, 0.2);
        }

    }

    .form-type-textfield {
        
        margin: 0;

        input {

            width: 100%;

            padding: 11px 45px  11px 20px;
        
            @media all and  (min-width: $desk-start) {

                width:  500px;
            }

        }

    }



}






 
 
 


// Search Listing
// ------------------------------------------------------


    
.search-results {

     
    // Higlight for the searched word
    .searched { 

        color : $labc-orange;
    }

}


    // Summary of results

    .search-summary {

        padding: 8px 12px;

        background: $labc-grey;
            
        margin:12px 0  25px;

        p {
            margin:0;
        }

    }


    // Individaul Result 
    .search-result { 
        
        @extend .cf;
        padding:  0 0 25px;
        margin: 0 0 25px;
        border-bottom:1px solid $labc-grey - 30 ;

    }
     

    .search-url {

        a {
            font-size: 0.9em;
            font-weight: normal;
            color:  $labc-grey  - 50;
            display: block;
            margin-top: 4px;
        }
    }

    .search-result-text {

        margin-bottom: 0;
    }


    @media all and  (min-width: $lap-start) {

        .search-result-wrapper{

            float: left;
            width:  75%;
            padding:  20px 10% 20px 0;
            border-right:1px solid $labc-grey - 30 ;

        }


        .search-result-button-wrapper {

            float: right;
            margin-top: 4%;
            padding-right: 4%;
            width: 24%;
        }

        .search-result .button  {

            float:right;
            clear:none;
            
        }

    }

    .search-result-title {
        
        a {

            font-weight: normal;          
            color : $labc-black ;
            text-decoration: none;

            &:hover  {
                            
                color : $labc-black + 30;
                text-decoration: underline;
            }


        }

    }

  // Search Listing, search block

.page-site-search .content-search-block {

    padding-top : 20px;

    .views-exposed-widget {
        float: left;
        margin:  0 12px 0 0;
    }
}




// Local Auth Search - TEMP
// ------------------------------------------------------

// Positioned to site-header

.local-auth-search {
    
    padding: 12px;
    
    @media all and  (min-width: $desk-start) {

        float: right;
        position: absolute;
        top: 60px;
        right: 0;
        padding: 0;

    }

    .form-type-textfield {
        
        input {

            width:  100%;
            padding: 16px 40px 16px 20px;

            @media all and  (min-width: $desk-start) {

                width: 240px;
                padding: 11px 40px  11px 20px;
        
            }

        }
    }

    .form-actions {
        
        right: 16px;
        top: 16px;
        border: none;
        width: 39px;

        &:before {
 
            top: 12px;
            font-size: 15px;
        }


        @media all and  (min-width: $desk-start) {

            right: 0;
            top: 0;
        }

    }

    .form-submit {
        right: 0;
        top:  0;
    }

  

} 


.lte-ie9  #local-auth-search  label {
    
    display: none;
 }