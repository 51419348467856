.pea_cook_wrapper {
	
	border-radius: 0 !important;

	&.pea_cook_bottomright,
	&.pea_cook_bottomcenter {
		bottom: 0 !important;
    	left: 0 !important;
    	margin: 0 !important;
    	right: 0 !important;
	}

	button.pea_cook_btn {
		display: inline-block;
	    border: 2px solid $button-border;
	    text-align:center;
	    font-weight: 400;
	    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
	    padding : 5px 7px;   
	    text-decoration: none;
	    font-weight: normal;
	    color: $button-colour;
	    background-color: $button-background-colour;
	    line-height: normal;
	}
}