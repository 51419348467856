.woocommerce-account {

	.woocommerce-Button {
		&:hover {
			background-color: pink;
			color: white;
		}
	}
	
	.col2-set {
		.col-1 {
			@include respond-to('large') {
				margin-right: 15px;
			}
		}

		.col-2 {
			@include respond-to('large') {
				margin-left: 15px;
			}
		}
	 
	 	.col-1,
		.col-2 {

			width: 100%;
	
			@include respond-to('large') {
				width: calc(50% - 15px);
			}

			form {

				margin-top: 10px;
				float: left;
				width: 100%;
  
				&.login,
				&.register {
				    background: url(../images/middleblock.jpg) repeat;

				    input.button {
				    	border: 3px solid #fff;
					    box-shadow: 1px 1px 3px rgba(10,10,10,0.3);
					    position: relative;
					    z-index: 20;
						border-radius: 0;
					    padding: 10px 15px;
					    width: auto;
					    font-size: 16px;
					    text-transform: none;
					    font-weight: normal;
					    font-family: 'Roboto Slab';
					    color: #fff !important;
					    text-transform: uppercase;
					    background-color: $iedesign-secondary;
					    outline: 0;

					    &:hover {
					    	background-color: #dad8da;
					    }
				    }
				}
			}
		}
	}
}