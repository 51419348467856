@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin replace-text-with-dimensions($img, $x: 50%, $y: 50%, $inline: false) {
  @include replace-text(if($inline, inline-image($img), $img), $x, $y);
  width: image-width($img);
  height: image-height($img);
}

@mixin replace-text($img, $x: 50%, $y: 50%) {
  @include hide-text;
  background: {
    @if is-url($img) {
      image: $img;
    }
    @else {
      image: image-url($img);
    }
    repeat: no-repeat;
    position: $x $y;
  };
}

@mixin hide-text($direction: $hide-text-direction) {
  @if $direction == left {
    $approximate-em-value: 12px / 1em;
    $wider-than-any-screen: -9999em;
    text-indent: $wider-than-any-screen * $approximate-em-value;
    overflow: hidden;
    text-align: left;
  }
  @else {
    // slightly wider than the box prevents issues with inline-block elements
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
  }
  @include for-legacy-browsers((ie: "7"), $critical-usage-threshold) {
    //Text transform capitalize fixes text-replacement issue when used in a <button> element on ie7
    text-transform: capitalize;
  }
}

@mixin for-legacy-browsers($browsers, $threshold: $critical-usage-threshold) {
  $rendered: false;
  @each $browser, $range in $browsers {
    @if not $rendered {
      @include for-legacy-browser($browser, $range..., $threshold: $threshold, $ranges: $browsers) {
        $rendered: true;
        @content;
      }
    }
  }
}

@mixin with-browser-ranges($capability, $prefix: $current-prefix) {
  $new-ranges: null;
  @if type-of($capability) == map {
    $new-ranges: $capability;
  }
  @else {
    $new-ranges: browser-ranges($capability, $prefix);
  }
  @if has-browser-subset($current-browser-versions, $new-ranges) {
    $old-ranges: $current-browser-versions;
    $current-browser-versions: intersect-browser-ranges($old-ranges, $new-ranges) !global;
    @content;
    $current-browser-versions: $old-ranges !global;
  }
  @else if $debug-browser-support {
    /* Excluding content because #{inspect($new-ranges)} is not included within
       #{inspect($current-browser-versions)} */
  }
}

@mixin for-legacy-browser($browser, $min-version, $max-version: $min-version, $threshold: $critical-usage-threshold, $ranges: ($browser: $min-version $max-version)) {
  @if not browser-out-of-scope($browser, $max-version) and support-legacy-browser($browser, $min-version, $max-version, $threshold) {
    @if $debug-browser-support {
      /* Content for #{display-browser-range($browser, $min-version, $max-version)}.
      Min version: #{map-get($browser-minimum-versions, $browser) or unspecified}.
      User threshold to keep: #{$threshold}%. If #{display-browser-range($browser, $min-version, $max-version)} are omitted: #{omitted-usage($browser, $min-version, $max-version)}%. */
    }
    @include with-browser-ranges(intersect-browser-ranges($current-browser-versions, $ranges)) {
      @content;
    }
  }
  @else if $debug-browser-support and browser-out-of-scope($browser, $max-version) {
    /* Content for #{display-browser-range($browser, $min-version, $max-version)} omitted.
       Not allowed in the current scope: #{browser-out-of-scope($browser, $max-version)} */
  }
  @else if $debug-browser-support and not support-legacy-browser($browser, $min-version, $max-version, $threshold) {
    @if omitted-usage($browser, $min-version, $max-version) > $threshold {
      /* Content for #{display-browser-range($browser, $min-version, $max-version)} omitted.
         User threshold to keep: #{$threshold}%. If #{display-browser-range($browser, $min-version, $max-version)} and below are omitted: #{omitted-usage($browser, $min-version, $max-version)}%. */
    }
    @else {
      /* Content for #{display-browser-range($browser, $min-version, $max-version)} omitted.
         Minimum support is #{map-get($browser-minimum-versions, $browser)}. */
    }
  }
}

@function browser-out-of-scope($browser, $version: null) {
  @if not index($supported-browsers, $browser) {
    @if $debug-browser-support {
      @return "#{$browser} is not listed as a supported browser.";
    }
    @else {
      @return true;
    }
  }
  @else if not ($current-prefix == null or $current-prefix == browser-prefix($browser)) {
    @if $debug-browser-support {
      @return "#{$browser} #{$version} is incompatible with #{$current-prefix}.";
    }
    @else {
      @return true;
    }
  }
  $current-range: map-get($current-browser-versions, $browser);
  $current-min: if($current-range, nth($current-range, 1), null);
  $current-max: if($current-range, nth($current-range, 2), null);
  @if not ($version and $current-max) {
    // We don't have any versions to compare
    @return false;
  }
  @else {
    // If the version is less than the current min, it is not supported
    $too-old: compare-browser-versions($browser, $version, $current-min)  0;
    @if $too-old or $too-new {
      @if $debug-browser-support {
        @return "The current scope only works with #{display-browser-range($browser, $current-min, $current-max)}.";
      }
      @else {
        @return true;
      }
    }
    @else {
      @return false;
    }
  }
}

@mixin hover-link {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

@mixin box-sizing($box-model: $default-box-sizing) {
  $box-model: unquote($box-model);
  @include prefixed-properties(css3-boxsizing, $box-sizing-support-threshold, (box-sizing: $box-model));
}

@mixin prefixed-properties($capability, $threshold, $properties, $capability-options: $default-capability-options) {
  @include with-each-prefix($capability, $threshold, $capability-options) {
    @each $prop, $value in $properties {
      @include prefix-prop($prop, $value);
    }
  }
}

@mixin with-each-prefix($capability, $threshold, $capability-options: $default-capability-options) {
  @each $prefix, $should-use-prefix in prefixes-for-capability($capability, $threshold, $capability-options) {
    @if $should-use-prefix {
      @if $debug-browser-support and type-of($should-use-prefix) == list {
        /* Capability #{$capability} is prefixed with #{$prefix} because #{$should-use-prefix} is required. */
      }
      @else if $debug-browser-support and type-of($should-use-prefix) == number {
        /* Capability #{$capability} is prefixed with #{$prefix} because #{$should-use-prefix}% of users need it which is more than the threshold of #{$threshold}%. */
      }
      @include with-prefix($prefix) {
        @include with-browser-ranges($capability) {
          @content;
        }
      }
    }
    @else if $debug-browser-support {
      /* Capability #{$capability} is not prefixed with #{$prefix} because #{prefix-usage($prefix, $capability, $capability-options)}% of users are affected which is less than the threshold of #{$threshold}. */
    }
  }
  @include with-prefix(null) {
    @include with-browser-ranges($capability) {
      @content;
    }
  }
}

@mixin with-prefix($prefix) {
  @if $current-prefix or $prefix-context {
    @if $current-prefix == $prefix or $prefix-context == $prefix {
      @if $debug-browser-support {
        @if $prefix {
          /* content for #{$prefix} because #{$current-prefix or $prefix-context} is already in scope. */
        }
        @else {
          /* unprefixed content. #{$current-prefix or $prefix-context} is already in scope. */
        }
      }
      $old-prefix-context: $prefix-context;
      $old-prefix: $current-prefix;
      $prefix-context: $prefix-context or $current-prefix !global;
      $current-prefix: $prefix !global;
      @content;
      $prefix-context: $old-prefix-context !global;
      $current-prefix: $old-prefix !global;
    }
    @else if $prefix == null {
      $old-prefix-context: $prefix-context;
      $prefix-context: $prefix-context or $current-prefix !global;
      $current-prefix: null !global;
      @if $debug-browser-support {
        /* Content for official syntax. Prefix context is still #{$prefix-context}. */
      }
      @content;
      $current-prefix: $prefix-context !global;
      $prefix-context: $old-prefix-context !global;
    }
    @else if $debug-browser-support {
      /* Omitting content for #{$prefix} because #{$current-prefix} is already in scope. */
    }
  }
  @else {
    @if $debug-browser-support and $prefix {
      /* Creating new #{$prefix} context. */
    }
    $prefix-context: $prefix !global;
    $current-prefix: $prefix !global;
    @content;
    $current-prefix: null !global;
    $prefix-context: null !global;
  }
}

@mixin prefix-prop($property, $value, $prefix: $current-prefix) {
  #{prefix-identifier($property, $prefix)}: $value;
}

@mixin tablet-landscape() {
  @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (orientation: landscape) 
      and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin tablet-portrait() {
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
      @content;
  }
}

